import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { map, tap } from 'rxjs';

import { User } from 'src/index';
import { LoadingService } from 'src/index/services.index';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService<User>,
    private router: Router,
    private loadingService: LoadingService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.loadingService.show();
    return this.authenticationService.checklogin().pipe(
      tap(() => this.loadingService.hide()),
      map(currentUser => {
        if (!currentUser) {
          return true;
        }
        this.router.navigate(['/'], { queryParams: route.queryParams || {} });
        return false;
      }),
    );
  }

}
