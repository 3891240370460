@if ($course()) {
<header class="pt-6 pb-5 w-full flex justify-center bg-primary">
    <img class="max-h-[3.75rem] mx-auto" [src]="mainLogo" [alt]="name" role="presentation">
</header>
<div class="px-base xl:px-base-desk pt-[3.125rem] lg:pt-[4.375rem]">
    <div class="max-w-[55.375rem] mx-auto">
        <div class="flex flex-col gap-9">
            <div class="flex flex-col-reverse sm:flex-row gap-x-6 gap-y-12">
                <img class="max-h-[15.625rem] w-full sm:size-[12.5rem] object-cover"
                    [src]="$course()!.image" [alt]="$course()!.title">
                <div class="flex flex-col items-start gap-y-3">
                    <h1 class="text-[2.5rem] leading-[3.125rem] font-bold">{{$course()!.title || '' | translate}}</h1>
                    <h2 class="text-[1.875rem] leading-[2.375rem]">{{$course()!.subtitle || '' | translate}}
                    </h2>
                    <a href="javascript:void(0);" (click)="registerAndGoCourse()"
                        class="inline-flex justify-center items-center px-8 py-2.5 text-white rounded-md bg-primary font-bold text-base">{{'Sign up' | translate}}</a>
                </div>
            </div>
            <div class="flex flex-col items-start gap-y-3">
                <p class="text-xl whitespace-pre-line" [innerHTML]="$course()?.description"></p>
            </div>
            @if ($courseSyntheticData().length > 0) {
                <dl class="flex flex-col text-primary">
                    @for (data of $courseSyntheticData(); track $index) {
                        <div class="py-6 w-full flex items-center gap-6 border-b border-input-color first:pt-0 last:border-b-0">
                            <dt class="text-xl font-bold mr-auto">{{data.label | translate}}</dt>
                            <dd class="text-xl text-end">{{data.value | translate}}</dd>
                        </div>
                    }
                </dl>
            }

            @if ($courseLongData().length > 0) {
                @for (data of $courseLongData(); track $index) {
                    <div class="mt-[1.25rem] lg:mt-[3.125rem]flex flex-col gap-4">
                        <h1 class="text-2xl leading-[2.375rem] text-primary font-bold">{{ data.label | translate }}</h1>
                        <div class="text-xl">
                            <p class="whitespace-pre-line" [innerHTML]="data.value"></p>
                        </div>
                    </div>
                }
            }
        </div>
    </div>
</div>
<div class="mt-[4.5rem] px-base xl:px-base-desk bg-primary py-11">
    <div class="max-w-[54.375rem] mx-auto">
        <div class="flex justify-between items-center gap-8 flex-wrap">
            <h1 class="text-[2.5rem] leading-[3.125rem] font-bold text-white">{{'Are you interested in our course?' | translate}}</h1>
            <a href="javascript:void(0);" (click)="registerAndGoCourse()"
                class="inline-flex justify-center items-center px-8 py-2.5 bg-white rounded-md text-primary font-bold text-base">{{'Sign up' | translate}}</a>
        </div>
    </div>
</div>

@if ($modules().length > 0) {
    <div class="px-base xl:px-base-desk pt-[3.125rem] lg:pt-[4.375rem]">
        <div class="max-w-[54.375rem] mx-auto">
            <div class="flex flex-col gap-14">
                <h1 class="text-[1.875rem] leading-[2.375rem] text-primary font-bold">{{'Modules' | translate}}</h1>
                <div class="flex flex-col sm:hidden">
                    <swiper class="w-full" [config]="swiperConfig">
                        @for (module of $modules(); track $index) {
                            <ng-template swiperSlide>
                                <ng-template *ngTemplateOutlet="moduleTmpl; context: { module }"></ng-template>
                            </ng-template>
                        }
                    </swiper>
                    <div class="swiper-pagination !static mt-6" id="swiperSuggestedCoursesPagination"></div>
                    <div class="mt-4 flex justify-between items-center text-primary">
                        <button type="button" class="swiper-btn-prev inline-flex items-center gap-3">
                            <span class="contents-viewer-icon-arrow-left h-6 inline-flex items-center"
                                aria-hidden="true"></span>
                            <span class="text-base font-bold">{{'Previous' |  translate}}</span>
                        </button>
                        <button type="button" class="swiper-btn-next inline-flex items-center gap-3">
                            <span class="text-base font-bold">{{'Next' | translate}}</span>
                            <span class="contents-viewer-icon-arrow-right h-6 inline-flex items-center"
                                aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="hidden sm:grid grid-cols-2 gap-12">
                    @for (module of $modules(); track $index) {
                        <ng-template *ngTemplateOutlet="moduleTmpl; context: { module }"></ng-template>
                    }
                </div>

                <ng-template #moduleTmpl let-module="module">
                    <div class="group flex flex-col">
                        <div class="w-full max-h-[11.25rem] flex justify-center items-center overflow-hidden">
                            <img class="size-full object-cover group-hover:scale-110"
                                [src]="module.cover" [alt]="module.title" />
                        </div>
                        <div class="px-5 py-6 flex flex-col gap-y-4 border border-input-color">
                            <h2
                                class="text-xl font-bold text-primary line-clamp-2 group-hover:underline group-focus-visible:underline">
                                {{module.title}}</h2>
                            @if (module.description) {
                                <p class="text-sm line-clamp-3 whitespace-pre-line" [innerHTML]="module.description"></p>
                            }
                            <dl class="grid grid-cols-3 text-sm text-primary">
                                @for (data of module.metadata; track $index) {
                                    <div
                                        class="flex flex-col gap-1.5 first:justify-self-start justify-self-center last:justify-self-end">
                                        <dt class="font-bold">{{data.label | translate}}</dt>
                                        <dd>{{data.value | translate}}</dd>
                                    </div>
                                }
                            </dl>
                        </div>
                    </div>
                </ng-template>
                
            </div>
        </div>
    </div>
    @if ($modules().length > 2) {
        <div class="mt-[4.5rem] px-base xl:px-base-desk bg-primary py-11">
            <div class="max-w-[54.375rem] mx-auto">
                <div class="flex justify-between items-center gap-8 flex-wrap">
                    <h1 class="text-[2.5rem] leading-[3.125rem] font-bold text-white">{{'Are you interested in our course?' | translate}}</h1>
                    <a href="javascript:void(0);" (click)="registerAndGoCourse()"
                        class="inline-flex justify-center items-center px-8 py-2.5 bg-white rounded-md text-primary font-bold text-base">{{'Sign up' | translate}}</a>
                </div>
            </div>
        </div>
    }
}

<footer class="px-base xl:px-base-desk py-11">
    <div class="max-w-[54.375rem] mx-auto">
        <span class="text-sm text-primary" [innerHTML]="footerText | translate">
            <!-- TODO: Add <a href=""> | Privacy Policy</a>-->
        </span>
    </div>
</footer>
}