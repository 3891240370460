import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";

import { SwiperModule } from "swiper/angular";

import { LocalizationModule } from "src/core/index/shared.module.index";

import {
    AdvancedSearchComponent, ChatTogglerComponent, ClickOutsideDirective, ContentDetailComponent,
    ContentPreviewComponent, CourseCertificateComponent, CourseDetailComponent, CoursePreviewComponent, GuideDetailComponent,
    GuidePreviewComponent, HandleImgErrorDirective, HandleStringObjectPipe, IconComponent,
    LanguageSelectorComponent, LoadingComponent, ModalComponent, ModalMessageComponent, PaginationComponent,
    RouterLinkDirective, SidebarComponent, SuggestedCoursesComponent, UserMenuComponent, FooterComponent,
    PopupComponent,
} from 'src/index/components.index';

@NgModule({
    declarations: [
        AdvancedSearchComponent,
        ChatTogglerComponent,
        ClickOutsideDirective,
        ContentDetailComponent,
        ContentPreviewComponent,
        CourseCertificateComponent,
        CourseDetailComponent,
        CoursePreviewComponent,
        FooterComponent,
        GuideDetailComponent,
        GuidePreviewComponent,
        HandleImgErrorDirective,
        HandleStringObjectPipe,
        IconComponent,
        LanguageSelectorComponent,
        LoadingComponent,
        ModalComponent,
        ModalMessageComponent,
        PaginationComponent,
        PopupComponent,
        RouterLinkDirective,
        SidebarComponent,
        SuggestedCoursesComponent,
        UserMenuComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        RouterModule,
        SwiperModule,
    ],
    providers: [
    ],
    exports: [
        AdvancedSearchComponent,
        ChatTogglerComponent,
        ClickOutsideDirective,
        ContentDetailComponent,
        ContentPreviewComponent,
        CourseCertificateComponent,
        CourseDetailComponent,
        CoursePreviewComponent,
        FooterComponent,
        GuideDetailComponent,
        GuidePreviewComponent,
        HandleImgErrorDirective,
        HandleStringObjectPipe,
        IconComponent,
        LanguageSelectorComponent,
        LoadingComponent,
        LocalizationModule,
        ModalComponent,
        ModalMessageComponent,
        PaginationComponent,
        RouterLinkDirective,
        PopupComponent,
        SidebarComponent,
        SuggestedCoursesComponent,
        UserMenuComponent,
    ],
})
export class SharedModule {
}
