@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" [ngClass]="{'xl:pl-sidebar': sideBarShown$ | async}"
    class="flex-1 transition-all duration-300 pb-6">
    <section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
        <div class="flex flex-col-reverse lg:flex-row">
            <div class="w-full flex">
                <div>
                    <p class="text-xl text-primary font-bold">{{'Hi!' | translate:{value:(currentUser$ | async)?.firstName} }}</p>
                    <h1 class="text-4xl font-bold">{{(config.mainTitle || 'What do you want to learn today?') | translate}}</h1>
                </div>
            </div>
            @if (!config.hideSearch) {
                <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                    <form class="flex-1 lg:flex-none pr-4" (ngSubmit)="search(searchInput.value)">
                        <label for="search" class="sr-only">{{'Search in' | translate}} {{appName}}</label>
                        <div class="relative flex items-center">
                            <input id="search" name="search" type="search" class="lg:w-[18.75rem] h-10 !pr-9 truncate"
                                #searchInput [placeholder]="('Search in' | translate) + ' ' + appName" [ngModel]="search$ | async" />
                            <div class="absolute right-1">
                                <button type="submit" class="w-8 h-8 hover:bg-secondary rounded-md text-xl flex justify-center items-center">
                                    <span class="sr-only">{{'Search' | translate}}</span>
                                    <meta-icon icon="search"></meta-icon>
                                </button>
                            </div>
                        </div>
                    </form>
                    @if (!hideChatToggler) {
                        <div class="ml-auto">
                            <meta-chat-toggler />
                        </div>
                    }
                </div>
            }
        </div>
    </section>

    <section class="px-base xl:px-base-desk mb-3">
        <div class="flex flex-col xl:flex-row -mx-4 mb-8" [ngClass]="{
            'xl:flex-row': !config.hideStudyPath && !config.hideInEvidenceCourses && !config.hideInProgressCourses
        }">
            @if (!config.hideInEvidenceCourses) {
                <div class="w-full px-4 mb-8" [ngClass]="{
                    'hidden': noInEvidenceCourses$ | async,
                    'xl:w-8/12 xl:mb-0': !config.hideStudyPath && !config.hideInEvidenceCourses && !config.hideInProgressCourses
                }">
                    <div class="relative swiper-c01 js-swiper-c01 swiper-inEvidence">
                        @if ($inEvidenceLoading()) {
                            <meta-loading [standAlone]="true" />
                        }
                        <swiper [config]="swiperInEvidenceConfig">
                            @for (course of inEvidenceCourses$ | async; track $index) {
                                <ng-template swiperSlide>
                                    <article class="h-full flex bg-primary rounded-md overflow-hidden focus-visible-white">
                                        <div class="hidden md:block w-5/12">
                                            <img class="w-full h-[26rem] object-cover" [src]="course.image" alt="" />
                                        </div>
                                        <div class="w-full md:w-7/12 px-6 py-10">
                                            <div class="inline-flex items-center text-white px-1 swiper-no-swiping">
                                                @if (course.duration) {
                                                    <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
                                                    <span>{{course.duration}}</span>
                                                }
                                            </div>
                                            <h3 class="text-3xl text-white line-clamp-2 p-1 swiper-no-swiping">
                                                <a class="hover:underline" href="#">{{course.title | translate}}</a>
                                            </h3>
                                            @if (course.subtitle) {
                                                <div class="text-2xl text-white swiper-no-swiping">
                                                    {{course.subtitle | translate}}
                                                </div>
                                            }
                                            <div class="text-white mt-6 px-1 swiper-no-swiping">
                                                <p class="whitespace-pre-line" [ngClass]="{
                                                    'line-clamp-4': course.skills?.length || course.subtitle,
                                                    'line-clamp-5': !course.skills?.length
                                                }">{{course.description | translate}}</p>
                                            </div>
                                            @if (course.skills?.length) {
                                                <div class="text-white mt-8 px-1 swiper-no-swiping">
                                                    <p class="font-bold">{{'Skills you will get' | translate}}</p>
                                                    <p>
                                                        @for (skill of course.skills; track $index) {
                                                            {{ skill | translate }}{{$last ? '': ', '}}
                                                        }
                                                    </p>
                                                </div>
                                            }
                                            <div class="mt-10 px-1 swiper-no-swiping">
                                                <a (click)="showCourseDetails(course)"
                                                    class="btn btn-secondary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
                                                    {{'See the details' | translate}}
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </ng-template>
                            }
                        </swiper>
                        <div class="swiper-pagination" id="swiperInEvidencePagination"></div>
                    </div>
                </div>
            }

            @if (!config.hideInProgressCourses) {
                <div class="w-full px-4 mb-8" [ngClass]="{
                    'xl:w-4/12 xl:mb-0': !config.hideStudyPath && !config.hideInEvidenceCourses && !config.hideInProgressCourses
                }">
                    <h2 class="text-3xl font-bold mb-4">{{'Courses in progress' | translate}}</h2>
                    <div class="relative swiper-c01 js-swiper-c01">
                        @if ($inProgressLoading()) {
                            <meta-loading [standAlone]="true" />
                        }
                        @if (noInProgressCourses$ | async) {
                            {{'You do not have any courses in progress' | translate}}
                        }
                        <swiper [config]="swiperInProgressConfig">
                            @for (inProgressCoursesSlide of inProgressCourses$ | async; track $index) {
                                <ng-template swiperSlide>
                                    <div class="lg:grid lg:grid-cols-3 gap-x-3" [ngClass]="{
                                        'xl:block': !config.hideStudyPath && !config.hideInEvidenceCourses && !config.hideInProgressCourses
                                    }">
                                        @for (course of inProgressCoursesSlide.courses; track $index) {
                                            <meta-course-preview [course]="course"
                                                [lowerHeight]="inProgressConfig.lowerHeight ?? false"
                                                [showProgress]="inProgressConfig.showProgress ?? false"
                                                [showStudents]="inProgressConfig.showStudents ?? false"
                                                [showSkills]="inProgressConfig.showSkills ?? false"
                                                [showImage]="inProgressConfig.showImage ?? false"
                                                [showDescription]="inProgressConfig.showDescription ?? false"
                                                [showContentTypeIcon]="inProgressConfig.showContentTypeIcon ?? false"
                                                [showStarIcon]="inProgressConfig.showStarIcon ?? false"
                                                (doClick)="goToCourse(course)"
                                                class="cursor-pointer mb-2 last:mb-0 lg:mb-0 xl:mb-2 xl:last:mb-0 !h-auto" />
                                        }
                                    </div>
                                </ng-template>
                            }
                        </swiper>
                        <div class="swiper-pagination" id="swiperInProgressPagination"></div>
                    </div>
                </div>
            }
        </div>

        @if (!config.hideStudyPath) {
            <div class="flex flex-col xl:flex-row -mx-4">
                <div class="w-full px-4 mb-8 xl:mb-0">
                    <h2 class="text-3xl font-bold mb-4">{{'Your study path' | translate}}</h2>
                    <div class="relative swiper-c02 js-swiper-c02 swiper-studyPath">
                        @if (studyPathLoading$ | async) {
                            <meta-loading [standAlone]="true" />
                        }
                        @if (noStudyPathCourses$ | async) {
                            {{'You have not started any courses yet' | translate}}
                        }
                        <swiper [config]="swiperStudyPathConfig">
                            @for (course of studyPathCourses$ | async; track $index) {
                                <ng-template swiperSlide>
                                    <meta-course-preview [course]="course" 
                                        [lowerHeight]="studyPathConfig.lowerHeight ?? false"
                                        [showProgress]="studyPathConfig.showProgress ?? false"
                                        [showStudents]="studyPathConfig.showStudents ?? false"
                                        [showSkills]="studyPathConfig.showSkills ?? false"
                                        [showImage]="studyPathConfig.showImage ?? false"
                                        [showDescription]="studyPathConfig.showDescription ?? false"
                                        [showContentTypeIcon]="studyPathConfig.showContentTypeIcon ?? false"
                                        [showStarIcon]="studyPathConfig.showStarIcon ?? false"
                                        (doClick)="showCourseDetails(course)" class="cursor-pointer"/>
                                </ng-template>
                            }
                        </swiper>
                        <div class="swiper-pagination" id="swiperStudyPathPagination"></div>
                    </div>
                </div>
            </div>
        }
    </section>

    @if (!hideFooter) {
        <meta-footer additionalClasses="px-base xl:px-base-desk"/>
    }
</main>

@defer (when $courseToPreview()) {
    @if ($courseToPreview()) {
        <meta-modal class="courseDetails"
            (doClose)="$courseToPreview.set(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
            <meta-course-detail modal-content [course]="$courseToPreview()!" [needActivation]="!$courseToPreview()!.status" 
                (openCourse)="startCourse($courseToPreview()!, $event)" (updateData)="updateCourseToPreview($event)" />
        </meta-modal>
    }
}