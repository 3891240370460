import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { faqs } from 'src/assets/faqs';

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    getFaqs() {
        return of(faqs as FaqItem[]);
    }
}
export interface FaqItem {
    title: string;
    body: string;
    media?: {
        type: 'image' | 'video';
        url: string;
        alt: string;
    };
}
