<main id="mainContent" class="flex-1">
    <section class="bg-primary min-h-screen px-base xl:px-base-desk">
        <div class="min-h-screen flex flex-col py-10">

            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="max-h-[3.75rem] mx-auto" [src]="logo || 'core/assets/images/logo.png'" [alt]="appName" />
            </div>
            <div class="w-full max-w-md mx-auto pb-12 mt-12 max-h-770:mt-0">
                <h1 class="text-[1.75rem] text-white text-center mb-6">{{'Reset password' | translate:{ app: this.appName } }}</h1>
                <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                    <form class="space-y-7" [formGroup]="form" (ngSubmit)="resetPassword()" autocomplete="off" (change)="$errorMsg.set('')">
                        <div>
                            <label for="newPassword" class="block text-[0.9375rem] font-semibold"
                                [ngClass]="{ '!text-red-600': $submitted() && f['newPassword'].errors }">{{'New password' | translate}}</label>
                            <div class="mt-1">
                                <input id="newPassword" name="newPassword" type="password" class="h-10 !border-red-600" required formControlName="newPassword"
                                    [ngClass]="{ '!border-red-600': $submitted() && f['newPassword'].errors }" />
                            </div>
                            @if ($submitted() && f['newPassword'].errors) {
                                <div class="text-sm text-red-700 mt-1">
                                    @if (f['newPassword'].errors['required']) {
                                        <p>{{'Password is mandatory' | translate}}</p>
                                    }
                                    @if (f['newPassword'].errors['minlength']) {
                                        <p> {{'The Password must have at least n characters' | translate:{value: minPwdLength } }}</p>
                                    }
                                    @if (f['newPassword'].errors['maxlength']) {
                                        <p> {{ 'The Password can have a maximum of n characters' | translate:{value: maxPwdLength } }}</p>
                                    }
                                </div>
                            }
                        </div>

                        <div>
                            <label for="confirmPassword" class="block text-[0.9375rem] font-semibold"
                                [ngClass]="{ '!text-red-600': $submitted() && f['confirmPassword'].errors }">{{'Confirm password' | translate}}</label>
                            <div class="mt-1">
                                <input id="confirmPassword" name="confirmPassword" type="password" class="h-10 !border-red-600" required formControlName="confirmPassword"
                                    [ngClass]="{ '!border-red-600': $submitted() && f['confirmPassword'].errors }" />
                            </div>
                            @if ($submitted() && f['confirmPassword'].errors) {
                                <div class="text-sm text-red-700 mt-1">
                                    @if (f['confirmPassword'].errors['required']) {
                                        <p class="font-medium">{{'The field is required' | translate:{value:('Confirm password' | translate)} }}</p>
                                    }
                                    @if (f['confirmPassword'].errors['matching']) {
                                        <p class="font-medium">{{'The two values do not match' | translate}}</p>
                                    }
                                </div>
                            }
                        </div>

                        <div>
                            <span class="text-red-600 text-sm">
                                {{$errorMsg() | translate}}
                                &nbsp;
                            </span>
                        </div>

                        <div class="!mt-9">
                            <button type="submit" [disabled]="$submitted() && !form.valid" [attr.aria-disabled]="$submitted() && !form.valid" 
                                class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2"
                                [ngClass]="{
                                    'btn-reset disabled': $submitted() && !form.valid,
                                    'btn-primary': !$submitted() || form.valid
                                }">{{'Reset password' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto">
                <p class="text-white">{{'Do you already have an account?' | translate }}</p>
                <a (click)="goToLogin()"
                    class="text-secondary hover:text-secondary-hover underline hover:no-underline">
                    {{'Log in' | translate}}
                </a>
            </div>
        </div>
    </section>
</main>