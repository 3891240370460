import { Component, computed, EventEmitter, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, map, shareReplay, startWith, switchMap } from 'rxjs';

import { Course, environment, SelfAssessmentSet, SendlerMethod } from 'src/index';
import {
    AnalyticsService, AuthenticationService, CoursesService, isMobile, isValidDate,
    LoadingService, PopupService, RoutingService, SelfAssessmentService, UserService,
} from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-self-assessment',
    templateUrl: './self-assessment.component.html',
    styleUrls: ['./self-assessment.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class SelfAssessmentComponent extends HasDashboardSidebar {
    selfAssessmentService = inject(SelfAssessmentService);
    authService = inject(AuthenticationService);
    routingService = inject(RoutingService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    analyticsService = inject(AnalyticsService);
    userService = inject(UserService);
    translateService = inject(TranslateService);
    popupService = inject(PopupService);
    loadingService = inject(LoadingService);
    coursesService = inject(CoursesService);

    config = { ...environment.selfAssessmentModuleConfig };
    hideFooter = this.config.hideFooter || environment.hideFooter;
    appName = environment.name;

    introTexts = this.config.introTexts || ['Self assessment intro 1', 'Self assessment intro 2', 'Self assessment intro 3', 'Self assessment intro 4', 'Self assessment intro 5'];
    baseCourseLink = '<a class="text-primary hover:underline" href="/search?fromCourse={{uuid}}" target="_blank">{{title}}</a>';

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('self-assessment');

    $welcome = computed(() => this.route.snapshot.data['welcome'] || false);
    $menuItems = computed(() => {
        if (this.$welcome()) {
            return [];
        }
        return this.configurationService.getDashboardMenuItems();
    });

    reloadDashboard$ = new EventEmitter();
    sets$ = this.reloadDashboard$.pipe(
        startWith(undefined),
        switchMap(() => this.selfAssessmentService.getSets()),
        map(sets => sets.map(set => ({
            ...set,
            routerLink: `${this.$welcome() ? '/welcome' : ''}/self-assessment/set/${set.uuid}`,
        }))),
        shareReplay(1),
    );
    $sets = toSignal(this.sets$);
    $suggestedCourses = computed(() => {
        if (!this.$welcome()) {
            const sets = this.$sets() || [];
            return sets.map(set => set.executionProfile)
                .filter(profile => !!profile)
                .reduce((courses, profile) => {
                    const coursesData = [...profile?.pills ?? [], ...profile?.courses ?? []];
                    coursesData.forEach(course => {
                        if (!courses.find(c => c.uuid === course.uuid)) {
                            courses.push(course);
                        }
                    });
                    return courses;
                }, [] as Course[]);
        }
        return [];
    });
    $courseToPreview = signal<Course | undefined>(undefined);
    $studyPathCourses = toSignal(this.coursesService.getStudyPathCourses().pipe(
        map(courses => courses || []),
        shareReplay(1),
    ));

    $canTerminate = computed(() => this.$sets()?.some(item => item.completed));
    $firstAccess = computed(() => {
        const startDate = this.authService.$currentUser()?.data?.selfAssessmentStart ?? '';
        return !isValidDate(new Date(startDate)) && this.$sets()?.every(item => !item.completed)
    });

    $currentUser = computed(() => {
        const currentUser = this.authService.$currentUser();
        return `${currentUser?.firstName} ${currentUser?.lastName}`;
    });

    goHome() {
        this.routingService.goToSearch();
    }

    goToResults() {
        const currentUser = this.authService.$currentUser();
        if (currentUser && this.$sets()?.some(item => item.completed)) {
            const startDate = currentUser.data?.selfAssessmentStart ? currentUser.data.selfAssessmentStart : undefined;
            if (startDate) {
                this.analyticsService.trackEvent(SendlerMethod.SelfAssessmentComplete, currentUser, startDate);
            }
        }
        this.routingService.goToSelfAssessment();
    }

    trackSelfAssessmentStart() {
        if (this.$firstAccess()) {
            const currentUser = this.authService.$currentUser();
            if (currentUser) {
                currentUser.data = {
                    ...currentUser.data || {},
                    selfAssessmentStart: this.analyticsService.convertDate(new Date()),
                }
                firstValueFrom(this.userService.saveData(currentUser.data));
                this.analyticsService.trackEvent(SendlerMethod.SelfAssessmentStart, currentUser);
            }
        }
    }

    showResults(set: SelfAssessmentSetWithRouterLink) {
        if (set.executionProfile) {
            const level = set.executionProfile?.level ?? '';
            const text = this.translateService.instant(set.executionProfile.text) + this.selfAssessmentService.getProfileTip(set.executionProfile);
            this.popupService.alert(text, {
                size: 'medium',
                position: 'center-center',
                closable: true,
                labels: {
                    confirm: 'Close',
                },
                alertConfig: {
                    header: {
                        title: set?.title ?? '',
                        subtitle: `${this.translateService.instant('Maturity level')}: ${this.translateService.instant(level)}`,
                    },
                },
            });
        } else {
            this.router.navigateByUrl(set.routerLink);
        }
    }

    showCourseDetails(course: Course) {
        this.loadingService.show();
        firstValueFrom(this.coursesService.getCourseDetails(course))
            .then(completeCourse => {
                this.$courseToPreview.set({ ...course, ...completeCourse });
                if (!completeCourse) {
                    this.popupService.error(this.configurationService.getCourseDetailErrorMsg);
                }
            })
            .catch((err) => this.popupService.error(this.configurationService.getCourseDetailErrorMsg))
            .finally(() => this.loadingService.hide());
    }

    updateCourseToPreview(course: Course) {
        this.$courseToPreview.set({ ...course });
    }

    startCourse(course: Course, urlToOpen?: string | void) {
        if (course.associatedToUser) {
            this.goToCourse(course, urlToOpen);
            this.$courseToPreview.set(undefined);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.$courseToPreview.set(undefined);
                    this.goToCourse(course, urlToOpen);
                })
                .catch((err) => this.popupService.error(err.message || this.configurationService.startCourseErrorMsg))
                .finally(() => this.loadingService.hide());
        }
    }

    goToCourse(course: Course, urlToOpen?: string | void) {
        if (urlToOpen) {
            return this.router.navigateByUrl(urlToOpen);
        }
        return this.coursesService.openCourse(course);
    }
}

interface SelfAssessmentSetWithRouterLink extends SelfAssessmentSet {
    routerLink: string;
    courses?: Course[];
    pills?: Course[];
}