<div id="viewer-accessibility" class="relative flex-1 flex flex-col px-base overflow-y-auto">
    <div class="sticky top-0 bg-white w-full h-12 px-1 pt-base flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="copyright"></meta-icon>
            <p class="inline-block">{{'Credits' | translate}}</p>
        </div>
        <button class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    <div class="px-1 flex flex-col overflow-y-auto mt-4 pt-4 border-t border-t-gray-300 scrollbar-custom">
        <meta-footer [hideCustomFooter]="hideFooter"/>
    </div>
</div>