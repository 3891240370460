import { Component, computed, signal } from '@angular/core';
import { CourseDetailComponent } from 'src/core/index/components.index';


@Component({
    selector: 'meta-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.css']
})
export class CustomCourseDetailComponent extends CourseDetailComponent {
    $showMoreMetadata = signal(false);
    $showMoreBtnNeeded = computed(() => {
        return this.$course().abstract || this.$course().mainLanguage || this.$course().keywords?.length || this.$course().skillLevel ||
            this.$course().license || this.$course().versionDate || this.$course().resourceUrl || this.$course().targetGroups?.length ||
            this.$course().learningResourceType || this.$course().prerequisites || this.$course().learningOutcome || this.$course().accessCost;
    });
}
