import { ModuleWithProviders, Type } from '@angular/core';
import { Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Core
export * from 'src/environments/server';
export * from 'src/core/index';

export * from 'src/index/components.index';
import { AboutComponent, CoreCourseDetailComponent, FaqsComponent, FaqsListComponent, ViewerCourseDetailComponent } from 'src/index/components.index';

import { AppModuleConfig, Course as CoreCourse, CoursePackage as CoreCoursePackage, Module as CoreModule } from 'src/core/index';
import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { AppRoutingModule } from 'src/custom/app-routing.module';

// Core - Optional modules
import { BotSearchModule } from 'src/core/index';
import { ChatModule } from 'src/core/index'; // TEMP disabilitato
// import { CoursesModule } from 'src/core/index'; // TEMP disabilitato
import { DashboardModule } from 'src/core/index';
import { GlobalSearchModule } from 'src/core/index';
// import { GuidesModule } from 'src/core/index'; // TEMP disabilitato
import { HelpModule } from 'src/core/index';
import { LoginModule } from 'src/core/index';
import { MyCoursesModule } from 'src/core/index';
import { MyGuidesModule } from 'src/core/index';
import { PublicModule } from 'src/core/index';
import { RegistrationModule } from 'src/core/index';
// import { SelfAssessmentModule } from 'src/core/index';
import { UserProfileModule } from 'src/core/index';
import { ViewerModule } from 'src/core/index';
import { PresentationViewerModule } from 'src/core/index';
import { ArModule } from 'src/core/index';

import { PreviewGuard } from 'src/index/services.index';

// Configurazione d'ambiente
export * from 'src/environments/environment';
export * from 'src/environments/platform';

// Moduli opzionali che possono essere attivati o disattivati. Per disattivare, basta commentare la riga di riferimento e la relativa riga nella definizione delle rotte
const optionalModules: (any[] | Type<any> | ModuleWithProviders<{}>)[] = [
    BotSearchModule,
    // CoursesModule, // TEMP disabilitato
    ChatModule,
    DashboardModule,
    GlobalSearchModule,
    // GuidesModule, // TEMP disabilitato
    HelpModule,
    LoginModule,
    MyCoursesModule,
    MyGuidesModule,
    PublicModule,
    RegistrationModule,
    // SelfAssessmentModule,
    UserProfileModule,
    ViewerModule,
    PresentationViewerModule,
    ArModule,
];
const optionalRoutes: Routes = [
    ...BotSearchModule.prototype.getRoutes(),
    ...ChatModule.prototype.getRoutes(),
    // ...CoursesModule.prototype.getRoutes(), // TEMP disabilitato
    ...DashboardModule.prototype.getRoutes(),
    ...GlobalSearchModule.prototype.getRoutes(),
    // ...GuidesModule.prototype.getRoutes(), // TEMP disabilitato
    ...HelpModule.prototype.getRoutes(),
    ...LoginModule.prototype.getRoutes(),
    ...MyCoursesModule.prototype.getRoutes(),
    ...MyGuidesModule.prototype.getRoutes(),
    ...PublicModule.prototype.getRoutes(),
    ...RegistrationModule.prototype.getRoutes(),
    // ...SelfAssessmentModule.prototype.getRoutes(),
    ...UserProfileModule.prototype.getRoutes(),
    ...ViewerModule.prototype.getRoutes(),
    ...PresentationViewerModule.prototype.getRoutes(),
    ...ArModule.prototype.getRoutes(),
];

// Aggiungere componenti ad AppModule
export const appModuleConfig: AppModuleConfig = {
    declarations: [
        CoreCourseDetailComponent, // Need to avoid compilation error. Would be probably removed when base app will start to use standalone components
        ViewerCourseDetailComponent,
        FaqsListComponent,
        FaqsComponent,
        AboutComponent,
    ],
    imports: [
        ...optionalModules,
        FormsModule,
        SharedModule,
        LocalizationModule,
    ],
    providers: [
        PreviewGuard
    ],
    routes: [
        // Controllo su effettivo bisogno di rotte per la localizzazione interno a LocalizationModule.prototype.getRoutes
        ...LocalizationModule.prototype.getRoutes([ // "Vincono" le rotte definite per prime. Per fare override di una rotta, basta definirla nel proprio routing module con lo stesso path
            ...AppRoutingModule.prototype.getRoutes(),
            ...optionalRoutes,
        ]),
        { path: '**', redirectTo: '', pathMatch: 'full' }
    ],
};

export interface Module extends CoreModule {
    abstract?: string;
    authors?: string[];
    keywords?: string[];
    mainLanguage?: string;
    license?: string;
    versionDate?: string;
    resourceUrl?: string;
    urlType?: string;
    targetGroups?: string[];
    skillLevel?: string;
    learningResourceType?: string;
    accessCost?: string;
    learningOutcome?: string;
    prerequisites?: string;
}

export interface Course extends CoreCourse {
    abstract?: string;
    authors?: string[];
    keywords?: string[];
    mainLanguage?: string;
    license?: string;
    versionDate?: string;
    resourceUrl?: string;
    urlType?: string;
    targetGroups?: string[];
    skillLevel?: string;
    learningResourceType?: string;
    accessCost?: string;
    learningOutcome?: string;
    prerequisites?: string;
    modules?: Module[];
}

export interface CoursePackage extends CoreCoursePackage {
    abstract?: string;
    authors?: string[];
    keywords?: string[];
    mainLanguage?: string;
    license?: string;
    versionDate?: string;
    resourceUrl?: string;
    urlType?: string;
    targetGroups?: string[];
    skillLevel?: string;
    learningResourceType?: string;
    accessCost?: string;
    learningOutcome?: string;
    prerequisites?: string;
}