import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DynamicModule } from 'ng-dynamic-component';
import { FormsModule } from "@angular/forms";

import { AuthGuard } from "src/index/services.index";
import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import {
  ViewerComponent, ViewerToolbarComponent,
  ViewerToolbarPanelComponent, ViewerIndexComponent,
  ViewerAccessibilityComponent, ViewerAnnotationsListComponent,
  ViewerBookmarksComponent, ViewerWorkbookComponent, ViewerSearchComponent,
  ViewerRelatedGuidesComponent, ViewerCalendarComponent,
  ViewerCreditsComponent,
} from 'src/index/components.index';

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
  {
    path: 'course/:courseId',
    canActivate: [AuthGuard],
    component: ViewerComponent,
    data: { contentType: 'course' },
  },
  {
    path: 'course/:courseId/:moduleId',
    canActivate: [AuthGuard],
    component: ViewerComponent,
    data: { contentType: 'course' },
  },
  {
    path: 'course/:courseId/:moduleId/:contentId',
    canActivate: [AuthGuard],
    component: ViewerComponent,
    data: { contentType: 'course' },
  },
  {
    path: 'viewer/:publicationId',
    canActivate: [AuthGuard],
    component: ViewerComponent,
    data: { contentType: 'liquid' },
  },
  {
    path: 'viewer/:publicationId/:contentId',
    canActivate: [AuthGuard],
    component: ViewerComponent,
    data: { contentType: 'liquid' },
  }
];

@NgModule({
  declarations: [
    ViewerComponent,
    ViewerToolbarComponent,
    ViewerToolbarPanelComponent,
    ViewerIndexComponent,
    ViewerAccessibilityComponent,
    ViewerAnnotationsListComponent,
    ViewerWorkbookComponent,
    ViewerBookmarksComponent,
    ViewerSearchComponent,
    ViewerRelatedGuidesComponent,
    ViewerCalendarComponent,
    ViewerCreditsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    DynamicModule,
    FormsModule,
    LocalizationModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    AuthGuard,
  ],
  exports: [
    ViewerComponent,
    ViewerToolbarComponent,
    ViewerToolbarPanelComponent,
    ViewerIndexComponent,
    ViewerAccessibilityComponent,
    ViewerAnnotationsListComponent,
    ViewerWorkbookComponent,
    ViewerBookmarksComponent,
    ViewerSearchComponent,
    ViewerRelatedGuidesComponent,
    ViewerCalendarComponent,
    ViewerCreditsComponent,
  ],
})
export class ViewerModule {
  getRoutes() {
    return routes;
  }
}
