import { inject, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsEvent, Course, environment, ISendler, LiquidViewerMedia, Module, User } from 'src/index';

declare const _paq: any;
@Injectable({
  providedIn: 'root'
})
export class MatomoService implements ISendler {
  deviceService = inject(DeviceDetectorService);
  config = { ...environment.analytics };

  convertDate(date: Date) {
    return date.toISOString().substring(0, 16).replace('T', ', ');
  }

  trackRegistration(user: Partial<User>) {
    if (_paq) {
      _paq.push(['setUserId', user.uuid]);
    }
    this.sendEvent(AnalyticsEvent.Registration, {
      user_id: user.uuid,
      registration_date: this.convertDate(new Date()),
      device_type: this.getDeviceType(),
    });
  }

  trackLogin(user: Partial<User>) {
    if (_paq) {
      _paq.push(['setUserId', user.uuid]);
    }
    this.sendEvent(AnalyticsEvent.Login, {
      user_id: user.uuid,
      login_date: this.convertDate(new Date()),
      device_type: this.getDeviceType(),
    });
  }

  trackSelfAssessmentStart(user: Partial<User>) {
    this.sendEvent(AnalyticsEvent.SelfAssessmentStart, {
      user_id: user.uuid,
      self_assessment_start_date: this.convertDate(new Date()),
      device_type: this.getDeviceType(),
    });
  }

  trackSelfAssessmentComplete(user: Partial<User>, startDate: string) {
    this.sendEvent(AnalyticsEvent.SelfAssessmentComplete, {
      user_id: user.uuid,
      self_assessment_start_date: this.convertDate(new Date(startDate)),
      self_assessment_complete_date: this.convertDate(new Date()),
      device_type: this.getDeviceType(),
    });
  }

  trackCourseAccess(user: Partial<User>, course: Partial<Course>) {
    this.sendEvent(AnalyticsEvent.CourseAccess, {
      user_id: user.uuid,
      course_id: course.uuid,
      course_name: course.title,
      access_time: new Date().getTime(),
      device_type: this.getDeviceType(),
    });
  }

  trackModuleAccess(user: Partial<User>, course: Partial<Course>, module: Partial<Module>) {
    this.sendEvent(AnalyticsEvent.ModuleAccess, {
      user_id: user.uuid,
      course_id: course.uuid,
      course_name: course.title,
      module_id: module.id,
      module_name: module.title,
      access_time: new Date().getTime(),
      device_type: this.getDeviceType(),
    });
  }

  trackVideoPlay(user: Partial<User>, course: Partial<Course>, video: LiquidViewerMedia, titleFromCurrentIndex: string) {
    this.sendEvent(AnalyticsEvent.VideoPlay, {
      user_id: user.uuid,
      course_id: course.uuid,
      course_name: course.title,
      video_filename: video.title || titleFromCurrentIndex || '',
      access_time: new Date().getTime(),
      device_type: this.getDeviceType(),
    });
  }

  sendEvent(name: string, eventData: any) {
    if (_paq) {
      _paq.push(['trackEvent', this.config.eventsMainCategory, name, name, JSON.stringify(eventData)]);
    } else {
      console.warn('Analytics send event missing', this.config.eventsMainCategory, name, eventData);
    }
  }

  getDeviceType() {
    return this.deviceService.deviceType;
  }
}
