import { Component, computed, EventEmitter, inject, input, Input, Output, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, of, switchMap, tap } from 'rxjs';
import { SwiperOptions } from 'swiper';

import { Course, SkillLevelId, SuggestionsLayout } from 'src/index';
import { CoursesService } from 'src/index/services.index';

@Component({
    selector: 'meta-suggested-courses',
    templateUrl: './suggested-courses.component.html',
    styleUrls: ['./suggested-courses.component.scss'],
})
export class SuggestedCoursesComponent {
    coursesService = inject(CoursesService);
    $skills = input<{ [key: string]: SkillLevelId } | undefined | null>(undefined, { alias: 'skills' });
    $content = input<string | undefined | null>(undefined, { alias: 'content' });
    $externalCourses = input<Course[]>([], { alias: 'courses' });

    @Input() title: string = 'Suggested study paths';
    @Input() layout: SuggestionsLayout = 'default';

    @Output() previewCourse = new EventEmitter<Course>();

    defaultSlidesPerView: SlidesPerView = { small: 2, medium: 3, large: 3 }
    $slidesPerView = input<Partial<SlidesPerView>>({}, { alias: 'slidesPerView' });

    $currentStudyPath = input<Course[]>([], { alias: 'currentStudyPath' });
    $coursesLoading = signal(true);
    suggestedCourses$ = combineLatest([
        toObservable(this.$content),
        toObservable(this.$skills),
    ]).pipe(
        switchMap(([content, skills]) => {
            if (content) {
                return this.coursesService.getSuggestedCoursesByContent(content);
            }
            if (skills) {
                return this.coursesService.getSuggestedCoursesBySkills(skills);
            }
            return of([]);
        }),
        tap(() => this.$coursesLoading.set(false)),
    );

    $suggestedCourses = toSignal(this.suggestedCourses$);
    $courses = computed(() => [...this.$suggestedCourses() || [], ...this.$externalCourses()].map(course => {
        const courseInStudyPath = this.$currentStudyPath().find(c => c.uuid === course.uuid);
        return {
            ...course,
            associatedToUser: !!courseInStudyPath,
            progress: courseInStudyPath?.progress,
        };
    }))
    $hasSuggestedCourses = computed(() => this.$courses().length > 0);

    $swiperConfig = computed<SwiperOptions>(() => ({
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {
            el: '#swiperSuggestedCoursesPagination',
            clickable: true
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            640: {
                slidesPerView: this.$slidesPerView().small || this.defaultSlidesPerView.small,
            },
            768: {
                slidesPerView: this.$slidesPerView().medium || this.defaultSlidesPerView.medium,
            },
            1024: {
                slidesPerView: this.$slidesPerView().large || this.defaultSlidesPerView.large,
            },
        },
    }));
}

interface SlidesPerView {
    small: number;
    medium: number;
    large: number;
}