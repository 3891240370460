@if ($hasSidebar()) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="$sideBarShown.set($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6"
    [ngClass]="{'xl:pl-sidebar': $hasSidebar() && $sideBarShown()}">
    @if (!$hasSidebar()) {
        <header class="pt-6 pb-5 w-full flex justify-center bg-primary mb-10">
            <img class="max-h-[3.75rem] mx-auto" [src]="mainLogo" [alt]="name" role="presentation">
        </header>
    }
    <section class="pt-6 pb-3 bg-body px-base xl:px-base-desk mb-6">
        <div [ngClass]="{
            'max-w-[55.375rem] mx-auto': !$hasSidebar()
            }">
            <h1 class="text-4xl font-bold mb-4">{{gdprTitle | translate}}</h1>
            <!-- TODO: Inserire testo GPRD -->
            @for (section of $gdprSections(); track $index) {
                @if (section.title) {
                    <h2 class="font-semibold text-xl mt-2">{{section.title}}</h2>
                }
                <div class="mb-2" [innerHTML]="section.text"></div>
            }
        </div>
    </section>

    <meta-footer [hideCustomFooter]="hideFooter" />
</main>
