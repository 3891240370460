import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';

import { User } from 'src/index';
import { AuthenticationService, RoutingService } from 'src/index/services.index';

@Injectable()
export class SelfAssessmentManagerGuard implements CanActivate {
    
    authenticationService = inject(AuthenticationService<User>);
    routingService = inject(RoutingService);

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authenticationService.checklogin().pipe(
            map(currentUser => {
                const userData = currentUser?.data;
                if (userData && userData.managerSelfAssessment) {
                    return true;
                }
                this.routingService.goToHome();
                return false;
            }),
        );
    }
}
