@if ($hasSidebar()) {
<meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems" [activeItem]="activeItem$ | async"
    [hideUserMenu]="config.hideUserMenu || false" (statusChanged)="$sideBarShown.set($event)"
    (menuItemClicked)="handleMenuItemClicked($event.id)" (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="min-h-screen bg-body flex flex-col flex-1 transition-all duration-300 pb-6"
    [ngClass]="{'xl:pl-sidebar': $hasSidebar() && $sideBarShown()}">
    @if (!$hasSidebar()) {
    <header class="pt-6 pb-5 w-full flex justify-center bg-primary mb-10">
        <img class="max-h-[3.75rem] mx-auto" [src]="mainLogo" [alt]="name" role="presentation" />
    </header>
    }
    <section class="pt-6 pb-3 bg-body px-base xl:px-base-desk mb-6 flex-1">
        <div class="flex flex-col-reverse lg:flex-row">
            <div class="w-full flex">
                <div>
                    <h1 class="text-4xl font-bold">{{'H2IOSC Training Environment' | translate}}</h1>
                </div>
            </div>
            @if ($loggedIn() && !hideChatToggler) {
            <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                <div class="ml-auto">
                    <meta-chat-toggler />
                </div>
            </div>
            }
        </div>
        <div class="pt-3">
            <p>
                <strong>H2IOSC Training Environment</strong> è una moderna <strong>piattaforma di gestione della
                    formazione</strong> progettata per offrire un ambiente di apprendimento virtuale altamente
                interattivo e personalizzabile. Sviluppata dall'azienda <strong>ETT</strong> in collaborazione con
                l’<strong>Istituto di Linguistica Computazionale "Antonio Zampolli"</strong> di Pisa, nell'ambito del
                progetto PNRR <strong>Humanities and cultural Heritage Italian Open Science Cloud</strong>.
            </p>
            <br />
            <p>
                La piattaforma consente agli utenti di <strong>Iscriversi ai corsi</strong> accedendo a un ampio
                catalogo di corsi, strutturati per soddisfare le esigenze di studenti, professionisti e organizzazioni;
                <strong>gestire la propria pagina personale</strong> con una dashboard intuitiva che permette di
                monitorare il progresso, consultare materiali didattici e accedere alle certificazioni ottenute;
                <strong>interagire con la community</strong> grazie a strumenti collaborativi come forum, chat e gruppi
                di lavoro virtuali, gli utenti possono scambiare idee, risolvere problemi e creare reti di
                apprendimento.
            </p>
            <br />
            <p>
                H2IOSC Training Environment è stato pensato per adattarsi a diverse esigenze, garantendo un’esperienza
                fluida e inclusiva attraverso un’interfaccia semplice da usare, sia per i principianti sia per utenti
                avanzati e compatibile con dispositivi desktop e mobile, per un apprendimento senza limiti di spazio o
                tempo. La piattaforma inoltre <strong>pone l’accento sull’interattività</strong> supportando quiz,
                simulazioni, esercitazioni pratiche e strumenti di gamification che rendono l’apprendimento più
                coinvolgente.
            </p>
        </div>
    </section>

    <meta-footer [hideCustomFooter]="hideFooter" />
</main>