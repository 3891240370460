import { Component, computed, ElementRef, inject, input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { jsPDF } from "jspdf";

import { CourseCertificateData } from 'src/index';
import { LoadingService, PopupService, toCanvas } from 'src/index/services.index';

@Component({
    selector: 'meta-course-certificate',
    templateUrl: './course-certificate.component.html',
    styleUrls: ['./course-certificate.component.css'],
})
export class CourseCertificateComponent {
    elRef = inject(ElementRef);
    @ViewChild('certificate') certificate!: ElementRef;
    @ViewChildren('img') imgs: QueryList<HTMLImageElement> | undefined

    loadingService = inject(LoadingService);
    popupService = inject(PopupService);

    $data = input<Partial<CourseCertificateData>>({}, { alias: 'data' });

    $title = computed(() => this.$data().title || '');
    $date = computed(() => this.$data().date);
    $subtitle = computed(() => this.$data().subtitle || '');
    $description = computed(() => this.$data().description || '');
    $logos = computed(() => {
        const logos = this.$data().logos ?? [];
        return logos.slice(0, 3);
    });
    $user = computed(() => this.$data().user || '');

    async downloadPdf(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            this.loadingService.show();

            await this.checkImagesLoaded();

            const certificateEl = this.certificate.nativeElement as HTMLElement;
            const canvasCert = await toCanvas(certificateEl);
            try {
                const imgData = canvasCert.toDataURL('image/png');
                const doc = new jsPDF('l', 'mm', [canvasCert.width, canvasCert.height]);
                const marginX = 60;
                const marginY = 40;
                doc.addImage(imgData, 'png', marginX, marginY, canvasCert.width - (marginX * 2), canvasCert.height - (marginY * 2));
                const docTitle = this.$data().pdfTitle;
                doc.save(docTitle);
            } catch (e) {
                this.popupService.error(`Si è verificato un errore nell'esportazione del pdf.`);
            }
            this.loadingService.hide();
            resolve();
        })
    }

    checkImagesLoaded() {
        const imgsPromises = (this.imgs ?? [])?.map(img => new Promise<void>((res) => {
            if (img.complete) {
                res();
            } else {
                img.onload = () => res();
            }
        }));

        return Promise.all(imgsPromises);
    }
}
