import { Component, computed, EventEmitter, inject, input, Input, InputSignal, Output, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { Course, CourseSubscriptionStatus } from 'src/index';
import { CoursesService, LoadingService, PopupService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent {
    popupService = inject(PopupService);
    coursesService = inject(CoursesService);
    loadingService = inject(LoadingService);
    translateService = inject(TranslateService);

    $course: InputSignal<Course> = input.required({ alias: 'course' });
    $courseMetadataInDescription = computed<CourseMetadata[]>(() => []); // Eventuali metadata da mostrare nella descrizione del corso
    $courseDescription = computed(() => this.$course().description || '');
    $openBtnLabel = computed<string>(() => {
        const progress = (this.$course()?.progress || 0) * 1;
        const associatedToUser = this.$course()?.associatedToUser;
        if (associatedToUser) {
            if (progress >= 100) {
                return 'Review the course';
            }
            switch (this.$course().status) {
                case CourseSubscriptionStatus.AwaitingApproval:
                    return 'Wait for approval'
                case CourseSubscriptionStatus.Finished:
                    return 'Review the course';
                case CourseSubscriptionStatus.Progress:
                    return 'Continue the course';
                case CourseSubscriptionStatus.Subscribed:
                default:
                    return 'Start the course';
            }
        }
        if (this.$course()?.needApproval) {
            return 'Sign up for the course';
        }
        return 'Start the course';
    });
    @Input() needActivation = true;
    @Output() openCourse = new EventEmitter<string | void>();
    @Output() updateData = new EventEmitter<Course>();

    $showAwaitingApprovalMessage = computed(() => this.$course().status === CourseSubscriptionStatus.AwaitingApproval);

    $showActivationModal = signal(false);
    urlAfterActivation = '';
    $activationErrorMsg = signal('');
    courseActivationCode = '';

    $modulesSectionTitle = computed(() => 'Modules');

    startCourse(urlToOpen?: string) {
        if (this.$course().banned) {
            this.popupService.alertError('You are not allowed to see this content', { alertConfig: { header: { title: 'Attention' } } });
        } else {
            if (!this.needActivation || !this.$course().toBuy) {
                const associatedToUser = this.$course()?.associatedToUser;
                if (this.$course().status !== CourseSubscriptionStatus.AwaitingApproval) {
                    if (associatedToUser || !this.$course().needApproval) {
                        this.openCourse.emit(urlToOpen);
                    } else if (this.$course().needApproval) {
                        this.subscribeCourse();
                    }
                }
            } else {
                this.urlAfterActivation = urlToOpen || '';
                this.$showActivationModal.set(true);
            }
        }
    }

    subscribeCourse() {
        this.loadingService.show();
        firstValueFrom(this.coursesService.startCourse(this.$course(), CourseSubscriptionStatus.AwaitingApproval))
            .then(() => {
                this.popupService.success('Registration request sent successfully');
                this.updateData.emit({
                    ...this.$course(),
                    associatedToUser: true,
                    status: CourseSubscriptionStatus.AwaitingApproval,
                });
            })
            .catch(err => this.$activationErrorMsg.set(err?.error?.message || `An error occurred while subscribing the course`))
            .finally(() => this.loadingService.hide());
    }

    activateCourse() {
        this.loadingService.show();
        firstValueFrom(this.coursesService.activateCourse(this.$course(), this.courseActivationCode))
            .then(() => {
                if (this.$course().needApproval) {
                    this.popupService.success('Registration request sent successfully');
                    this.$course().status = CourseSubscriptionStatus.AwaitingApproval;
                } else {
                    this.popupService.success('Course successfully activated');
                    this.$showActivationModal.set(false);
                    this.openCourse.emit(this.urlAfterActivation);
                }
            })
            .catch(err => this.$activationErrorMsg.set(err?.error?.message || `An error occurred while activating the course`))
            .finally(() => this.loadingService.hide());
    }
}

export interface CourseMetadata {
    id: string;
    label: string;
    labelInline?: boolean;
    value: string;
}