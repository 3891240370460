<footer class="px-base xl:px-base-desk flex flex-col gap-4 relative text-center pb-4 text-xs {{additionalClasses}}">
    @if (!hideCustomFooter) {
        <p [innerHTML]="text"></p>
    }
    @if (!hideMetaCredits) {
        <p class="flex flex-col gap-2 items-center">
            {{'Layout Design & Web Development' | translate}}<br/>
            <a href="https://www.gruppometa.it/" title="Vai al sito web di GruppoMeta">
                <img src="core/assets/images/logoMeta.svg" class="max-h-[1.5rem]" alt="Logo GruppoMeta"/>
            </a>
        </p>
    }
</footer>