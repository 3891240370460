import { Component, computed, ElementRef, HostBinding, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom, map } from 'rxjs';

import { environment, PopupConfig } from 'src/index';
import { AuthenticationService, ChatService, GDPRService, LoadingService, UserService } from 'src/index/services.index';
import { inOutPaneAnimation } from './components/shared/animations';

@Component({
    selector: 'meta-contents-viewer-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '150ms', direction: 'enterRight' }),
    ],
})
export class AppComponent {
    authService = inject(AuthenticationService);
    userService = inject(UserService);
    gdprService = inject(GDPRService);
    @HostBinding('attr.app-version') libVersionAttr = `${environment.version}.${environment.build}.${environment.patch}` +
        (environment.instanceVersion ? `_${environment.instanceVersion}` : '');
    title = environment.name;

    $showPrivacyDisclaimer = computed(() => {
        if (environment.enablePrivacyDisclaimer) {
            const currentUser = this.authService.$currentUser();
            if (currentUser) {
                return !(currentUser.data?.privacy);
            }
        }
        return false;
    });

    privacyDisclaimerConfig: PopupConfig = {
        type: 'alert',
        size: 'large',
        labels: {
            confirm: 'Accept',
        },
        callbacks: {
            confirm: () => {
                this.loadingService.show();
                firstValueFrom(this.userService.saveData({
                    ...this.authService.$currentUser()?.data ?? {},
                    privacy: true
                }))
                    .finally(() => {
                        this.authService.$currentUser.update(user => user ? ({
                            ...user,
                            data: {
                                ...user?.data || {},
                                privacy: true
                            }
                        }) : user);
                        this.loadingService.hide();
                    })
            },
        },
        alertConfig: {
            header: {
                title: this.gdprService.getTitle(),
            }
        },
        message: 'Lorem ipsum'
    }
    $gdprSections = computed(() => this.gdprService.getGDPRText())

    constructor(
        public chatService: ChatService,
        private loadingService: LoadingService,
        private titleService: Title,
        private el: ElementRef,
        translate: TranslateService,
        router: Router,
    ) {
        translate.setDefaultLang(environment.defaultLanguage);

        if (this.title && this.title.length > 0) {
            this.titleService.setTitle(this.title);
        }
        router.events.pipe(
            filter(event => (event instanceof NavigationStart)),
            map(e => e as NavigationStart),
        ).subscribe(event => {
            if (event.navigationTrigger === 'popstate') {
                this.loadingService.hide();
            }
        });
        const rootEl: HTMLElement = this.el.nativeElement;
        rootEl.removeAttribute('data-root-api');
    }
}
