import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import { TranslateService } from '@ngx-translate/core';

import { environment, SelfAssessmentExecution, SelfAssessmentProfile, SelfAssessmentSet, SelfAssessmentSetBO } from 'src/index';
import { CoursesService } from "src/index/services.index";

@Injectable({
  providedIn: 'root'
})
export class SelfAssessmentService {
  httpClient = inject(HttpClient);
  coursesService = inject(CoursesService);
  translateService = inject(TranslateService);
  baseCourseLink = '<a class="text-primary hover:underline" href="/search?fromCourse={{uuid}}" target="_blank">{{title}}</a>';

  convertSet(set: SelfAssessmentSetBO): SelfAssessmentSet {
    return {
      ...set,
      profiles: (set.profiles ?? []).map(profile => ({
        ...profile,
        courses: profile.courses?.map(c => this.coursesService.convertPackageCourse(c)) ?? [],
        pills: profile.pills?.map(c => this.coursesService.convertPackageCourse(c)) ?? [],
      })),
      executionProfile: set.executionProfile ? {
        ...set.executionProfile,
        courses: set.executionProfile.courses?.map(c => this.coursesService.convertPackageCourse(c)) ?? [],
        pills: set.executionProfile.pills?.map(c => this.coursesService.convertPackageCourse(c)) ?? [],
      } : undefined,
    } as SelfAssessmentSet;
  }

  getSets(): Observable<SelfAssessmentSet[]> {
    if (environment.getSelfAssessmentSets) {
      return this.httpClient.get<SelfAssessmentSetBO[]>(environment.getSelfAssessmentSets).pipe(
        catchError(() => of([] as SelfAssessmentSetBO[])),
        map(sets => sets.map(set => this.convertSet(set))),
      );
    }
    return of([]);
  }

  getSet(setUuid: string): Observable<SelfAssessmentSet | undefined> {
    if (environment.getSelfAssessmentSet) {
      return this.httpClient.get<SelfAssessmentSetBO>(environment.getSelfAssessmentSet.replace(':uuid', setUuid)).pipe(
        catchError(() => of(undefined)),
        map(set => set ? this.convertSet(set) : set),
      );
    }
    return of(undefined);
  }

  deleteSet(setUuid: string): Observable<SelfAssessmentSet | undefined> {
    if (environment.getSelfAssessmentSet) {
      return this.httpClient.delete<SelfAssessmentSet>(environment.getSelfAssessmentSet.replace(':uuid', setUuid)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  saveExecution(setUuid: string, execution: string, uuidProfile: string | null): Observable<SelfAssessmentExecution | undefined> {
    if (environment.saveExecution) {
      return this.httpClient.post<SelfAssessmentExecution>(
        environment.saveExecution.replace(':uuid', setUuid),
        {
          execution,
          uuidProfile,
        }
      ).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  deleteExecution(executionUuid: string) {
    if (environment.deleteExecution) {
      return this.httpClient.delete(environment.deleteExecution.replace(':uuid', executionUuid)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  getProfileTip(userProfile: SelfAssessmentProfile) {
    let text = '';
    if (userProfile.tip) {
      const courses = this.getTipCourses(userProfile);
      const pills = this.getTipPills(userProfile);
      if (courses.length > 0 || pills.length > 0) {
        const coursesTitles = courses.length > 0 ? `${courses.length === 1 ? 'il corso' : 'i corsi'} ${courses.join(', ')}` : '';
        const pillsTitles = pills.length > 0 ? `${pills.length === 1 ? 'la sezione' : 'le sezioni'} ${pills.join(', ')}` : '';
        text += '<br/>' + this.translateService.instant(userProfile.tip)
          .replace('###COURSES###', coursesTitles)
          .replace('###PILLS###', pillsTitles);
      }
    }
    return text;
  }

  getTipCourses(userProfile: SelfAssessmentProfile) {
    const courses = userProfile?.courses ?? [];
    return courses.map(course => this.baseCourseLink.replace('{{uuid}}', course.uuid).replace('{{title}}', course.title));
  }

  getTipPills(userProfile: SelfAssessmentProfile) {
    const pills = userProfile?.pills ?? [];
    return pills.map(pill => this.baseCourseLink.replace('{{uuid}}', pill.uuid).replace('{{title}}', pill.title));
  }
}

