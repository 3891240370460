import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { Routes } from "@angular/router";

import { SwiperModule } from 'swiper/angular';

import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { CoursePublicDetailsComponent, PrivacyPolicyComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'public/course/:courseId',
        component: CoursePublicDetailsComponent,
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
    },
];

@NgModule({
    declarations: [
        CoursePublicDetailsComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        LocalizationModule,
        SharedModule,
        SwiperModule,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        CoursePublicDetailsComponent,
        PrivacyPolicyComponent,
    ],
})
export class PublicModule {
    getRoutes() {
        return routes;
    }
}
