import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { User, environment } from 'src/index';
import { AuthenticationService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  http = inject(HttpClient);
  authService = inject(AuthenticationService<User>);

  registration(newUserData: Object): Observable<{ access_token: string, user: User } | undefined> {
    if (environment.registration) {
      return this.http.post<{ access_token: string, user: User }>(environment.registration, newUserData).pipe(
        catchError(error => throwError(() => error.error.message)),
      );
    }

    return of(undefined);
  }

  changePassword(currentPassword: string, newPassword: string) {
    if (environment.changePassword) {
      return this.http.post<User>(environment.changePassword, { currentPassword, newPassword }).pipe(
        catchError(error => throwError(() => error.error.message)),
      );
    }
    return of(undefined);
  }

  saveData(data: { [key: string]: any }) {
    if (environment.saveUserData) {
      return this.http.put<User>(environment.saveUserData, { data }).pipe(
        catchError(error => throwError(() => error.error.message)),
        tap(user => this.authService.setUser(user)),
      );
    }
    return of();
  }

  updatePersonalData(user: Partial<User>) {
    if (environment.saveUserData) {
      return this.http.put<User>(environment.saveUserData, user).pipe(
        catchError(error => throwError(() => error.error.message)),
        tap(user => this.authService.setUser(user)),
      );
    }
    return of();
  }
}
