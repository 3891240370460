<main id="mainContent" class="flex-1">
    <section class="bg-primary min-h-screen px-base xl:px-base-desk">
        <div class="min-h-screen flex flex-col py-10">
            @if ($hasSteps()) {
                <div class="fixed top-0 inset-x-0 h-3 bg-gray-300">
                    <div class="h-3 bg-secondary transition-all duration-200" [style.width.%]="$currentStepWidth()">
                    </div>
                </div>
            }
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="max-h-[3.75rem] mx-auto" [src]="logo || 'core/assets/images/logo.png'" [alt]="appName" />
            </div>
            @if ($currentStepHasForm()) {
                <div class="w-full mx-auto mt-6 mb-6 text-center max-w-md" [ngClass]="{
                    'pt-10': !$isFromCourse() || !$isFirstStep()
                }">
                    @if (!$isFromCourse()) {
                        <h1 class="text-[1.75rem] text-white mb-3">
                            @if ($isFirstStep()) {
                                @if ($isFromCourse()) {
                                    {{'Enter your details and register to course' | translate }}
                                } @else {
                                    {{ 'Enter your details and register' | translate }}
                                }
                            } @else {
                                {{ 'Complete the profile' | translate }}
                            }
                        </h1>
                    }
                    @if ($isFirstStep() && $isFromCourse() && $course()) {
                        <div class="flex flex-col sm:flex-row gap-x-6 gap-y-4 text-start bg-white shadow rounded-md px-6 md:px-10 py-6">
                            <img class="max-h-[6.625rem] w-full sm:size-[6.5rem] object-cover"
                                [src]="$course()!.image" [alt]="$course()!.title" />
                            <div class="flex flex-col items-start gap-y-2 justify-start">
                                <h1 class="text-[1.4rem] leading-[2.025rem]  font-bold">{{$course()!.title || '' | translate}}</h1>
                                @if ($course()!.subtitle) {
                                    <h2 class=" line-clamp-3">{{$course()!.subtitle || '' | translate}}
                                    </h2>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div class="w-full max-w-md mx-auto pb-12 max-h-770:mt-0">
                    <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                        @if ($isFromCourse()) {
                            <h1 class="mb-8 font-bold text-[1.2rem]">
                                @if ($isFirstStep()) {
                                    @if ($isFromCourse()) {
                                        {{'Enter your details and register to course' | translate }}
                                    } @else {
                                        {{ 'Enter your details and register' | translate }}
                                    }
                                } @else {
                                    {{ 'Complete the profile' | translate }}
                                }
                            </h1>
                        }
                        @if (form().controls) {
                            <form class="space-y-7" [formGroup]="form()" (ngSubmit)="register()" autocomplete="off"
                                (change)="$errorMsg.set('')">
                                @for (field of $currentStepFields(); track field.id) {
                                    <div>
                                        <label [attr.for]="field.id" class="block text-[0.9375rem] font-semibold"
                                            [ngClass]="{ '!text-red-600': submitted && field.control.errors }">
                                                {{field.label | translate}}
                                                @if (field.required) {
                                                    *
                                                }
                                        </label>
                                        <div class="mt-1">
                                            @switch (field.inputType) {
                                                @case ('base64') {
                                                    <meta-base64-uploader [maxSize]="field.maxSizeBytes" [base64]="form().value[field.id]"
                                                        [acceptFormat]="field.acceptFormat || '*'"
                                                        (change)="patchFormValue(field.id, $event)" (deleted)="patchFormValue(field.id, undefined)"/>
                                                }
                                                @case ('select') {
                                                    <select [id]="field.id" [attr.name]="field.id" class="h-10" [value]="field.default"
                                                        [formControlName]="field.id" [ngClass]="{ '!border-red-600': submitted && field.control.errors }">
                                                        @if (field.$options) {
                                                            @for (optionItem of field.$options(); track $index) {
                                                                <option [value]="optionItem | handleStringObject:'value'">
                                                                    {{ optionItem | handleStringObject:'label' | translate }}
                                                                </option>
                                                            }
                                                        }
                                                    </select>
                                                }
                                                @case ('textarea') {
                                                    <textarea [id]="field.id" [attr.name]="field.id" [formControlName]="field.id"
                                                        [ngClass]="{ '!border-red-600': submitted && field.control.errors }"></textarea>
                                                }
                                                @case ('radio') {
                                                    @if (field.$options) {
                                                        <div class="flex gap-4" [ngClass]="{
                                                            'flex-col': field.optionsInline
                                                        }">
                                                            @for (optionItem of field.$options(); track $index) {
                                                                <label class="inline-flex gap-x-2 items-center">
                                                                    <input [id]="field.id" [attr.name]="field.id" type="radio"
                                                                        [value]="optionItem | handleStringObject:'value'" [formControlName]="field.id"
                                                                        [ngClass]="{ '!border-red-600': submitted && field.control.errors }" />
                                                                    <p>{{optionItem | handleStringObject:'label' | translate}}</p>
                                                                </label>
                                                            }
                                                        </div>
                                                    }
                                                }
                                                @case ('checkbox') {
                                                    @if (field.$options) {
                                                        <div class="flex gap-4" [ngClass]="{
                                                            'flex-row': field.optionsInline,
                                                            'flex-col': !field.optionsInline
                                                        }">
                                                            @for (optionItem of field.$options(); track $index) {
                                                                <label class="inline-flex gap-x-2 items-center" [formArrayName]="field.id">
                                                                    <input [id]="field.id+'_'+$index" type="checkbox"
                                                                        [value]="optionItem | handleStringObject:'value'" [formControlName]="$index"
                                                                        [ngClass]="{ '!border-red-600': submitted && field.control.errors }"/>
                                                                    <p>{{optionItem | handleStringObject:'label' | translate}}</p>
                                                                </label>
                                                            }
                                                        </div>
                                                    }
                                                }
                                                @default {
                                                    <input [id]="field.id" [attr.name]="field.id" [type]="field.inputType" 
                                                        [attr.autocomplete]="field.id" class="h-10" [formControlName]="field.id"
                                                        [ngClass]="{ '!border-red-600': submitted && field.control.errors }" />
                                                }
                                            }
                                        </div>
                                        @if (field.id === 'email' && submitted && $controls()['email'].errors) {
                                            <div>
                                                <span class="text-red-600 text-sm">{{'Invalid value entered' | translate}}</span>
                                            </div>
                                        }
                                    </div>
                                }
        
                                <!-- <div>
                                    <label for="password2" class="block text-[0.9375rem] font-semibold"
                                        [ngClass]="{ '!text-red-600': submitted && $controls()['password2'].errors }">Ripetere
                                        password</label>
                                    <div class="mt-1">
                                        <input id="password2" name="password2" type="password" autocomplete="password2"
                                            class="h-10" required formControlName="password2"
                                            [ngClass]="{ '!border-red-600': submitted && $controls()['password2'].errors }" />
                                    </div>
                                    <div *ngIf="submitted && $controls()['password2'].errors">
                                        <span class="text-red-600 text-sm">Le 2 password non corrispondono</span>
                                    </div>
                                </div> -->
        
                                @if ($errorMsg()) {
                                    <div>
                                        <span class="text-red-600 text-sm">{{$errorMsg() | translate}}</span>
                                    </div>
                                }
                                @if ($showPrivacyDisclaimer()) {
                                    <div class="flex gap-x-3 text-[0.75rem]">
                                        <span [innerHTML]="'Privacy disclaimer' | translate:{ policyLink:$privacyLink() }"></span>
                                    </div>
                                }
    
                                @if ($currentStep()) {
                                    <div class="!mt-7 flex gap-x-3">
                                        @if ($isFirstStep()) {
                                            <button type="submit"
                                                class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">{{'Sign in' | translate}}</button>
                                        } @else {
                                            <button type="button" (click)="nextStep()"
                                                class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">
                                                {{'Save and continue' | translate}}
                                            </button>
                                        }
                                    </div>
                                }
                            </form>
                        }
                    </div>
                </div>
            }

            @if ($currentStepHasSurvey()) {
                <div class="w-full max-w-5xl mx-auto pt-10 pb-12 mt-12 max-h-770:mt-0">
                    @if ($currentSurveyData()) {
                        <h1 class="text-[1.75rem] text-white text-center mb-6">{{$currentSurveyData().label | translate}}</h1>
                        <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                            <div class="overflow-x-auto">
                                <table class="min-w-full">
                                    <caption class="sr-only">{{$currentSurveyData().label | translate}}</caption>
                                    <tbody>
                                        <tr class="text-[1.0625rem] text-primary">
                                            <td class="p-2.5"></td>
                                            @for (level of $currentSurveySkillLevels(); track $index) {
                                                <th scope="col" [id]="level.id" class="whitespace-nowrap p-2.5">{{level.label | translate}}</th>
                                            }
                                        </tr>
                                        @for (skill of $currentSurveySkills(); track $index) {
                                            <tr class="border-b border-[#E2E2E2]">
                                                <th scope="row" [id]="'skill-'+skill.id"
                                                    class="text-[1.625rem] font-bold text-primary text-left whitespace-nowrap p-2.5">
                                                    {{skill.label | translate}}
                                                </th>
                                                @for (level of $currentSurveySkillLevels(); track $index) {
                                                    <td class="text-center p-2.5">
                                                        <input [id]="skill.id + level.id" [name]="skill.id" type="radio"
                                                            class="!w-4 h-4" [(ngModel)]="currentSurveyUserSkills[skill.id]"
                                                            [attr.aria-labelledby]="'skill-'+skill.id+' '+level.id"
                                                            [value]="level.id" />
                                                    </td>
                                                }
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div class="max-w-[23rem] mx-auto mt-10">
                                    <button type="button" (click)="nextStep()"
                                        class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">
                                        {{'Save and continue' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

            @if ($isFirstStep()) {
                <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto">
                    <p class="text-white">{{'Do you already have an account?' | translate}}</p>
                    <a (click)="goToLogin()"
                        class="text-secondary hover:text-secondary-hover underline hover:no-underline cursor-pointer">{{'Log in' | translate}}</a>
                </div>
            } @else {
                @if ($currentStepSkippable()) {
                    <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto">
                        <a (click)="nextStep(true);"
                            class="text-secondary hover:text-secondary-hover underline hover:no-underline cursor-pointer">{{'Skip' | translate}}</a>
                    </div>
                }
            }
        </div>
    </section>
</main>