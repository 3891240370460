export const faqs = [
    {
        title: `Come faccio a seguire un corso?`,
        body: `Per iscriversi a un corso in libera consultazione, basta selezionarlo nella pagina “search in H2IOSC”, dove è disponibile un’interfaccia di ricerca semplice o avanzata. Una volta selezionato il corso, clicca sul titolo e poi su “inizia corso”.`,
    },
    {
        title: `Come posso iscrivermi a un corso a numero chiuso?`,
        body: `Prima dell’iscrizione, il docente di riferimento ti invierà un codice alfanumerico. Dopo aver selezionato il corso nel menu “cerca in H2IOSC”, dove puoi effettuare una ricerca in testo libero, clicca su “inizia corso” e copia il codice alfanumerico nel box che apparirà sullo schermo.`,
    },
    {
        title: `Dove posso vedere i corsi che ho iniziato?`,
        body: `Tutti i corsi che hai iniziato si trovano nella sezione “i miei corsi” nella barra del menu a sinistra. Dopo aver selezionato il corso di tuo interesse, puoi accedervi selezionando “continua”.`,
    },
    {
        title: `Come posso completare le attività del corso?`,
        body: `Tutte le attività del corso si possono completare dall’interfaccia di lettura, nel menu della barra laterale, attraverso il bottone con la spunta “segna come completata”.`,
    },
    {
        title: `Come posso scaricare i materiali didattici?`,
        body: `Tutti i materiali in formato immagine e PDF possono essere scaricati. Passando il cursore su un’immagine, in alto a destra comparirà il simbolo di una nuvoletta con una freccia che punta verso il basso: cliccando sull’icona, l’immagine verrà scaricata. I PDF sono visualizzati all’interno di una finestra con una barra di navigazione in alto. In alto a destra, l’icona con la freccia che punta verso il basso permette il download del file.`,
    },
    {
        title: `Dove posso vedere la percentuale di completamento del corso?`,
        body: `Nella sezione “I miei corsi” il menu propone una thumbnail del corso con una barra e la percentuale di completamento delle attività, comprendendo sia quelle obbligatorie che quelle facoltative.`,
    },
    {
        title: `Come accedo agli attestati di partecipazione?`,
        body: `Una volta spuntato il completamento di tutte le attività e i materiali obbligatori, apparirà una schermata con di notifica con scritto “congratulazioni, hai completato il corso”. A quel punto, un pop-up in alto a destra darà la notifica della disponibilità del certificato. Accanto alla spunta di completamento, nella barra del menu, comparirà un nuovo bottone con una coccarda: cliccando sull’icona, il certificato sarà disponibile per il download.`,
    },
    {
        title: `Posso aggiungere le note al testo che sto studiando?`,
        body: `Sì, l’editor di testo permette di sottolineare in diversi colori e anche di aggiungere delle note personali. Tutte le note e i contenuti sottolineati saranno disponibili nella sezione “quaderno”, accessibile dalla barra del menu con l’icona di un quaderno aperto.`,
    },
    {
        title: `Posso sottolineare il testo?`,
        body: `Sì, è possibile sottolineare il testo in diversi colori. I brani sottolineati sono raccolti nella sezione “quaderno”, accessibile dalla barra del menu con l’icona di un quaderno aperto.`,
    },
];