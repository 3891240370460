import { Location } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, inject, OnDestroy, OnInit, signal, Type, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, firstValueFrom, map, Observable, of, shareReplay, Subscription, switchMap, tap } from 'rxjs';

import {
  AccessibilitySetting, Annotation, Course, environment, IndexItem, IndexOutputMessage, liquidViewerEventsPrefix, LiquidViewerMedia,
  LiquidViewerMsgEvent, LiquidViewerMsgEventType, publicationType, SendlerMethod, User, ViewerAnnotation, ViewerToolbarTool
} from 'src/index';
import {
  AnalyticsService, AuthenticationService, ChatService, ConfigurationService, CoursesService, ExerciseService,
  LoadingService, PopupService, removePrefix, RoutingService, ViewerAnnotationListService,
  ViewerContentService, ViewerIndexService, ViewerPostMessageService, ViewerSearchService, ViewerService
} from 'src/index/services.index';

@Component({
  selector: 'meta-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('liquidViewerShellIframe') liquidViewerShellIframe!: ElementRef;

  analyticsService = inject(AnalyticsService);
  authenticationService = inject(AuthenticationService<User>);
  chatService = inject(ChatService);
  configurationService = inject(ConfigurationService);
  coursesService = inject(CoursesService);
  exerciseService = inject(ExerciseService);
  loadingService = inject(LoadingService);
  location = inject(Location);
  popupService = inject(PopupService);
  route = inject(ActivatedRoute);
  routingService = inject(RoutingService);
  sanitizer = inject(DomSanitizer);
  titleService = inject(Title);
  translateService = inject(TranslateService);
  viewerAnnotationListService = inject(ViewerAnnotationListService);
  viewerContentService = inject(ViewerContentService);
  viewerIndexService = inject(ViewerIndexService);
  viewerPostMessageService = inject(ViewerPostMessageService);
  viewerSearchService = inject(ViewerSearchService);
  viewerService = inject(ViewerService);

  // TODO: Handle all correctly with observable
  subscriptions: Subscription[] = [];

  // Content variables
  contentType: 'course' | 'liquid' = this.route.snapshot.data['contentType'] || 'liquid';
  mainId: string | undefined = this.contentType === 'course' ? this.route.snapshot.params['courseId'] : this.route.snapshot.params['publicationId'];
  $chatCourse = signal<Course | undefined>(undefined);

  // Configurations
  config = { ...environment.viewerModule, defaultLanguage: environment.defaultLanguage };
  toolbarConfig = this.config.toolbar?.config ?? { position: 'left' };
  hideFooter = this.config.hideFooter || environment.hideFooter;
  $isSurvey = signal(false);
  $canConfirm = signal(false);
  executions: Array<any> = [];

  $toolbarToolsByContentType = signal(this.configurationService.getViewerToolbarTools().filter(tool => !tool.limitToContentType || tool.limitToContentType === this.contentType));
  $toolbarTools = computed(() => {
    const courseProgress = this.viewerIndexService.$currentProgress();
    let tools = this.$toolbarToolsByContentType().map(t => ({ ...t }));
    const isSurvey = this.$isSurvey();
    const canConfirm = this.$canConfirm();
    if (this.config.trackProgressType === 'manual') {
      const trackProgress = tools.find(t => t.id === 'trackProgress');
      const courseCompleted = courseProgress.total >= 100 || courseProgress.mandatory >= 100;
      const currentCertificateData = this.viewerIndexService.$currentCertificateData();
      tools = tools.filter(t => {
        if (t.id === 'certificate') {
          return courseCompleted && currentCertificateData?.title;
        }
        return true;
      })
      if (trackProgress) {
        trackProgress.permanentActive = this.viewerIndexService.$currentContentCompleted();
        trackProgress.label = trackProgress.permanentActive ? 'Completed' : 'Mark as completed';
        if (isSurvey) {
          trackProgress.disabled = !canConfirm || trackProgress.permanentActive;
        } else {
          trackProgress.disabled = trackProgress.permanentActive;
        }
      }
    }
    return tools;
  });
  toolbarLinks = this.configurationService.getViewerToolbarSecondaryTools().filter(tool => !tool.limitToContentType || tool.limitToContentType === this.contentType);;

  hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config?.hideChatToggler;

  // Viewer variables
  viewerUrl = this.config.liquidViewer?.indexPath ? this.sanitizer.bypassSecurityTrustResourceUrl(this.config.liquidViewer?.indexPath) : null;
  viewerConfiguration = {
    ...this.config.liquidViewer?.config ?? {},
    defaultLang: this.translateService.defaultLang || this.config.defaultLanguage,
    currentLang: this.translateService.currentLang,
  };
  //focusOnPage$: BehaviorSubject<boolean> = this.viewerService.focusOnPage$;
  fullscreenDynamicComponent$: BehaviorSubject<Type<any> | undefined> = this.viewerService.fullscreenDynamicComponent$;

  // Events from annotations list
  clickedAnnotation$: Observable<Annotation | undefined> = this.viewerAnnotationListService.openedAnnotation$.pipe(
    filter(ann => ann !== undefined),
    map(ann => ann as Annotation),
    tap(ann => {
      switch (ann.type) {
        case "clipping":
          this.popupService.alert(`<div class="flex justify-center"><img src="${ann?.data}"></div>`, {
            size: 'medium',
            alertConfig: {
              header: { title: 'Clipping' },
            },
            labels: {
              confirm: 'Close'
            },
            callbacks: {
              confirm: () => {
                let elToFocus: HTMLElement | undefined;
                if (ann.id) {
                  elToFocus = document.getElementById(ann.id) || undefined;
                }
                elToFocus = elToFocus || document.getElementById('workbook') || undefined;
                elToFocus?.focus();
              }
            }
          });
          break;
        default:
          switch (this.viewerIndexService.$publicationType()) {
            case 'liquid':
              if (this.mainId !== ann.mainId || this.viewerIndexService.$currentContentId() !== ann.contentId) {
                this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(ann.contentId) as IndexItem);
                this.routingService.goToPublicationContent(ann.mainId, ann.contentId);
                firstValueFrom(this.viewerService.getContent(ann.mainId, ann.contentId))
                  .then((content) => {
                    this.viewerPostMessageService.postMessage('content', content);
                    this.viewerPostMessageService.postMessage('scrollToAnnotation', ann.id);
                  })
                  .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg))
              }
              break;
            case 'course':
              if (ann.secondaryMainId) {
                if (this.mainId !== ann.mainId || this.viewerIndexService.getSecondaryMainId() !== ann.secondaryMainId || this.viewerIndexService.$currentContentId() !== ann.contentId) {
                  this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(ann.contentId) as IndexItem);
                  this.$isSurvey.set(this.viewerIndexService.$currentIndexItem()?.isSurvey ?? false);
                  this.routingService.goToCourse(ann.mainId, ann.secondaryMainId, ann.contentId);
                  this._setCourse(ann.mainId, ann.secondaryMainId, ann.contentId)
                    .then(({ course, content }) => {
                      if (course && content) {
                        const convertedCourse = {
                          id: course.uuid,
                          title: course.title,
                          styles: "",
                          index: [],
                          language: "it-IT",
                          printable: false,
                          lastUpdate: "",
                          startId: ann.secondaryMainId + "/" + ann.contentId,
                        };
                        if (this.mainId !== ann.mainId) {
                          this.viewerIndexService.$currentCertificateData.set(course.certificateData ?? {});
                          this.viewerIndexService.$currentEvents.set(course.events || []);
                          this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('course', course));
                        }
                        this.viewerPostMessageService.postMessage('publication', convertedCourse); //TODO: rivedere converter
                        this.viewerPostMessageService.postMessage('content', content);
                      }
                    })
                    .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg))
                }
              }
              break;
            default:
              break;
          }
          break;
      }
    }),
    tap(annotation => {
      try {
        const annElement: HTMLElement | undefined = this.liquidViewerShellIframe?.nativeElement.contentWindow.document.querySelector(`[data-annotation-id="${annotation.id}"]`);
        if (annElement) {
          annElement.scrollIntoView();
        }
      } catch (e) { }
    })
  );

  eventListener = this.handleEventMessages.bind(this); // Necessario fare così per poter rimuovere correttamente il listener

  constructor() {
    this.viewerPostMessageService.setMessageListener(this.eventListener);
  }

  // ----- Lifecycle hooks functions -----
  ngOnInit(): void {
    this.subscriptions.push(
      this.viewerIndexService.indexItemClicked.subscribe(item => this._handleIndexOutputMessage(item)),
    );
  }

  ngOnDestroy(): void {
    this.viewerPostMessageService.removeMessageListener(this.eventListener);
    this.viewerIndexService.reset();
    this.viewerSearchService.lastSearch = undefined;
    this.viewerAnnotationListService.openedAnnotation$.next(undefined);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.viewerPostMessageService.setLiquidViewerIframe(this.liquidViewerShellIframe);
    this.subscriptions.push(
      this.clickedAnnotation$.subscribe(),
      this.viewerSearchService.pageWithResultSearch$.subscribe(
        item => {
          if (item) {
            const mainId = this.viewerIndexService.$mainId();
            this.viewerIndexService.setCurrentIndexItem(item);
            this.$isSurvey.set(this.viewerIndexService.$currentIndexItem()?.isSurvey ?? false);
            const pubType = this.viewerIndexService.$publicationType();
            if (pubType && mainId) {
              this._updateContent(pubType, mainId, undefined, item);
            }
          }
        }
      )
    );
  }
  // ----- END Lifecycle hooks functions -----

  handleToolbarToolClick(currTool: ViewerToolbarTool) {
    // Put here logic to handle tool that has no callback defined in configuration nor panelComponent set

    if (currTool.id !== "cut" && this.$toolbarTools().find(tool => tool.id === "cut") && this.$toolbarTools().find(tool => tool.id === "cut")!.active === true) {
      this.viewerService.toggleCutMode();
    }
  }

  handleEventMessages(event: LiquidViewerMsgEvent): void {
    const eventType = event.data?.type?.replace(liquidViewerEventsPrefix, '') as removePrefix<LiquidViewerMsgEventType, typeof liquidViewerEventsPrefix>;
    let mainId;
    switch (eventType) {
      case 'init':
        this.viewerPostMessageService.postMessage('setConfiguration', this.viewerConfiguration);
        this._initContent();
        this._sendUserIdToShell();
        break;
      case 'getAccessibilityStyle':
        this.viewerService.setAccessibilitySetting(event.data.message as AccessibilitySetting);
        break;
      case 'onContentRendered':
        mainId = this.viewerIndexService.$mainId();
        if (mainId) {
          firstValueFrom(
            this.viewerAnnotationListService.getAnnotations(mainId))
            .finally(() => this.loadingService.hide());
        }
        break;
      case 'onChangeContent':
        this.loadingService.hide();
        break;
      case 'onOpenUrl':
        const link = event.data.message as { url: string; local?: boolean; target?: string; type?: string; }
        this.openUrl(link.url, link.local, link.target, link.type);
        break;
      case 'onPlayMedia':
        const media = event.data.message as { type: string; src: Array<{ src: string; provider?: string; }>; title?: string; }
        this.handlePlayMedia(media);
        break;
      case 'cutResult':
        this.handleClippingResultPostMessage(event.data.message as string);
        break;
      case 'annotationCreated':
        this.loadingService.show();
        const annotation = event.data.message as ViewerAnnotation;
        if (this.viewerIndexService.getSecondaryMainId()) {
          annotation.moduleId = this.viewerIndexService.getSecondaryMainId();
        }
        if (annotation.contentId) {
          annotation.contentId = annotation.contentId.toString();
        }
        firstValueFrom(
          this.viewerAnnotationListService.addAnnotation(annotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationUpdated':
        this.loadingService.show();
        firstValueFrom(
          this.viewerAnnotationListService.updateAnnotation(event.data.message as ViewerAnnotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationDeleted':
        this.loadingService.show();
        firstValueFrom(
          this.viewerAnnotationListService.deleteAnnotation(event.data.message as string)
        ).finally(() => this.loadingService.hide());
        break;
      case 'onConfirmExercise':
        mainId = this.viewerIndexService.$mainId();
        let secondaryMainId = this.viewerIndexService.getSecondaryMainId();
        let contentId = this.viewerIndexService.$currentIndexItem()?.id;
        const { id, userAnswer, report, exerciseData, canConfirm } = event.data.message as any;
        if (!userAnswer && (!report || Object.keys(report).length <= 0)) {
          return;
        }

        let exerciseExecutionData: any = {
          exerciseId: id,
          executionData: userAnswer || report,
          exerciseData,
        }

        if (mainId && !secondaryMainId && contentId) {
          exerciseExecutionData = {
            ...exerciseExecutionData,
            coursePublicationId: mainId,
            contentId: contentId
          }

        } else if (mainId && secondaryMainId && contentId) {
          exerciseExecutionData = {
            ...exerciseExecutionData,
            coursePublicationId: mainId,
            moduleId: secondaryMainId,
            contentId: contentId
          }
        }

        if (
          this.viewerIndexService.$currentIndexItem()?.id == contentId
          && this.viewerIndexService.$currentIndexItem()?.isSurvey
        ) {
          exerciseExecutionData.exerciseData.isSurvey = true;
        }

        if (!this.$isSurvey()) {
          this.exerciseService.saveExerciseExecution(exerciseExecutionData);
        } else {
          this.executions[id] = exerciseExecutionData;
          this.$canConfirm.set(typeof canConfirm != "undefined" && canConfirm);
        }
        break;
    }
  }

  confirmExecution() {
    const exes = Object.keys(this.executions) || [];
    const promises = exes.map((key: any) => this.exerciseService.saveExerciseExecution(this.executions[key]));
    Promise.all(promises).then(() => {
      let courseId = this.route.snapshot.params['courseId'];
      let moduleId = this.route.snapshot.params['moduleId'] || undefined;
      let courseContentId = this.route.snapshot.params['contentId'] || undefined;
      this.viewerIndexService.$currentContentCompleted.set(true);
      if (courseId && moduleId && courseContentId) {
        this.coursesService.trackProgress(courseId, moduleId, courseContentId);
      }
    });

  }

  // ----- Navigation functions -----
  hasNext() {
    if (this.viewerIndexService.$currentIndexItem()?.isSurvey &&
      !this.viewerIndexService.$currentIndexItem()?.optional &&
      !this.viewerIndexService.$currentContentCompleted()) {
      return false
    }
    return this.viewerIndexService.hasNext();
  }

  hasPrevious() {
    return this.viewerIndexService.hasPrevious();
  }

  goPrevious() {
    this.loadingService.show();
    const prevPage = this.viewerIndexService.getPrevious();
    if (prevPage?.secondaryMainId && prevPage?.secondaryMainId !== this.viewerIndexService.getSecondaryMainId()) {
      const prevChapter = this.viewerIndexService.getIndexItemById(prevPage.secondaryMainId)
      if (prevChapter?.title) {
        this.popupService.info(`Sei al modulo: ${prevChapter.title}`);
      }
    }
    this._handleIndexOutputMessage(prevPage);
  }

  goNext() {
    if (this.hasNext()) {
      this.loadingService.show();
      const nextPage = this.viewerIndexService.getNext();
      if (nextPage?.secondaryMainId && nextPage?.secondaryMainId !== this.viewerIndexService.getSecondaryMainId()) {
        const nextChapter = this.viewerIndexService.getIndexItemById(nextPage.secondaryMainId)
        if (nextChapter?.title) {
          this.popupService.info(`Sei al modulo: ${nextChapter.title}`);
        }
      }
      this._handleIndexOutputMessage(nextPage);
    } else {
      const progress = this.viewerIndexService.$currentProgress();
      if (progress.total >= 100) {
        this.routingService.goToCompletedCourse(this.mainId!);
      }
    }
  }

  // ------ User functions ------
  private _sendUserIdToShell() {
    firstValueFrom(this.authenticationService.currentUser$.pipe(filter(user => !!user)))
      .then((user) => {
        this.viewerPostMessageService.postMessage('userId', user?.uuid || user?.id)
      })
      .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg))
  }

  // ------ Content functions -------
  private _handleIndexOutputMessage(message: IndexOutputMessage | null) {
    if (message) {
      switch (message.type) {
        case 'liquid':
          this.routingService.goToPublicationContent(message.mainId, message.indexItem.id);
          this.viewerIndexService.setCurrentIndexItem(message.indexItem);
          this._updateContent('liquid', message.mainId, undefined, message.indexItem, message.paragraph ? message.paragraph.id : null);
          break;
        case 'course':
          if (message.secondaryMainId) {
            this.routingService.goToCourse(message.mainId, message.secondaryMainId, message.indexItem.id); //TODO: da testare, probabilmente da rimuovere (considerare a11y)
            this.viewerIndexService.setCurrentIndexItem(message.indexItem);
            this.$isSurvey.set(this.viewerIndexService.$currentIndexItem()?.isSurvey ?? false);
            if (this.config.trackProgressType === 'auto') {
              this.coursesService.trackProgress(message.mainId, message.secondaryMainId, message.indexItem.id);
            }
            this._updateContent('course', message.mainId, message.secondaryMainId, message.indexItem);
          }
          break;
      }
    }
  }

  private _initContent() {
    this.loadingService.show();

    switch (this.contentType) {
      case 'liquid':
        let publicationId = this.route.snapshot.params['publicationId'];
        let pubContentId = this.route.snapshot.params['contentId'] || undefined;

        if (publicationId) {
          this._setPublicationAndContent(publicationId, pubContentId)
            .then(({ publication, content }) => {
              if (publication && content) {
                this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('liquid', publication));
                this.viewerPostMessageService.postMessage('publication', publication);
                this.viewerPostMessageService.postMessage('content', content);
              }
            })
            .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg));
        }
        break;
      case 'course':
        let courseId = this.route.snapshot.params['courseId'];
        let moduleId = this.route.snapshot.params['moduleId'] || undefined;
        let courseContentId = this.route.snapshot.params['contentId'] || undefined;
        if (courseId && moduleId && courseContentId && this.config.trackProgressType === 'auto') {
          this.coursesService.trackProgress(courseId, moduleId, courseContentId);
        }
        if (courseId) {
          this._setCourse(courseId, moduleId, courseContentId)
            .then(({ course, content }) => {
              if (course) {
                const convertedCourse = {
                  id: course.uuid,
                  title: course.title,
                  styles: "",
                  index: [],
                  language: "it-IT",
                  printable: false,
                  lastUpdate: "",
                  startId: moduleId + "/" + courseContentId,
                };
                this.$chatCourse.set(course);
                this.viewerIndexService.$currentCertificateData.set(course.certificateData ?? {});
                this.viewerIndexService.$currentEvents.set(course.events || []);
                this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('course', course));
                if (courseContentId) {
                  this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(courseContentId) as IndexItem);
                  this.$isSurvey.set(this.viewerIndexService.$currentIndexItem()?.isSurvey ?? false);
                }
                this.viewerPostMessageService.postMessage('publication', convertedCourse); //TODO: rivedere converter
                if (content) {
                  this.viewerPostMessageService.postMessage('content', content);
                } else { // TODO: Rivedere comportamento
                  this.loadingService.hide();
                  this.popupService.warning(this.configurationService.emptyContentCourseMsg);
                }
              }
            })
            .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg));
        }
        break;
      default:
        console.warn("Unsupported Content.")
        this.loadingService.hide();
        break;
    }
  }

  private _setPublicationAndContent(mainId: string, contentId?: string) {
    const pageTitleParts: string[] = [];
    return firstValueFrom(this.viewerService.getPublication(mainId).pipe(
      switchMap(publication => {
        pageTitleParts.push(publication?.title);
        if (!contentId) {
          contentId = publication?.startId ?? contentId;
          this.location.replaceState(`${this.location.path()}/${contentId}`);
        }
        if (contentId) {
          return this.viewerService.getContent(mainId, contentId).pipe(
            map(content => ({ publication, content })),
            tap(({ content }) => {
              pageTitleParts.push(content?.title);
            }),
          );
        }
        return of({ publication, content: undefined });
      }),
      tap(() => this.titleService.setTitle(pageTitleParts.filter(p => !!p).join('/') ?? environment.name)),
    ));
  }

  private _setCourse(mainId: string, secondaryMainId?: string, contentId?: string) {
    return firstValueFrom(this.coursesService.getCourse(mainId).pipe(
      tap(course => {
        this.viewerIndexService.$currentProgress.set({
          total: course?.progress ?? 0,
          mandatory: course?.mandatoryProgress ?? 0,
        });
        const user = this.authenticationService.$currentUser();
        if (user && course) {
          const pageTitleParts = [];
          if (this.viewerIndexService.$mainId() !== mainId) { // Is first course access
            this.analyticsService.trackEvent(SendlerMethod.CourseAccess, user, course);
          }
          pageTitleParts.push(course.title);
          const currentModule = course?.modules?.find(m => m.id === secondaryMainId);
          if (this.viewerIndexService.$currentIndexItem()?.secondaryMainId !== secondaryMainId) { // Is first module access
            if (currentModule) {
              this.analyticsService.trackEvent(SendlerMethod.ModuleAccess, user, course, currentModule);
            }
          }
          pageTitleParts.push(currentModule?.title);
          pageTitleParts.push(this.viewerIndexService.$currentIndexItem()?.title);
          this.titleService.setTitle(pageTitleParts.filter(p => !!p).join('/') ?? environment.name);
        }
      }),
      switchMap(course => {
        if (course) {
          if ((!secondaryMainId || !contentId) && course?.modules && course.modules[0] && course.modules[0].startId) {
            secondaryMainId = course.modules[0].id;
            contentId = course.modules[0].startId;
            this.routingService.goToCourse(mainId, secondaryMainId, contentId);
          }
          if (secondaryMainId && contentId && course?.modules) {
            let contentType: string;
            course?.modules.forEach((module, _) => {
              if (module.id === secondaryMainId) {
                module.index.forEach((indexItem: any) => {
                  if (indexItem.uuid == contentId) {
                    contentType = indexItem.type;
                    return;
                  }
                });
              }
            });
            return this.coursesService.getContentModuleCourse(mainId, secondaryMainId, contentId).pipe(
              tap(c => c ? this.viewerIndexService.$currentContentCompleted.set(c.completed) : undefined),
              map(content => this.viewerContentService.convert(
                content, contentType, this.authenticationService.getToken(), environment.baseUrlApi
              )),
              switchMap(content => {
                if (secondaryMainId && contentId && ['exercise', 'survey'].indexOf(content.contentType) !== -1) {
                  return this.exerciseService.getExerciseExecution(mainId, secondaryMainId, contentId).pipe(
                    tap((res) => {
                      Object.keys(content.contents || {}).forEach(cId => {
                        if (content.contents[cId].type === 'exercise') {
                          const exeIndex = res.findIndex(e => e.exerciseId === cId);
                          if (exeIndex > -1) {
                            try {
                              content.contents[cId].report = {
                                ...res[exeIndex].executionData,
                                exerciseData: res[exeIndex].exerciseData,
                              };
                            } catch (e) { }
                          }
                        }
                      })
                    }),
                    map(() => content),
                  );
                }
                return of(content);
              }),
              map(content => ({ course, content })),
            );
          }
        }
        return of({ course, content: undefined });
      }),
      shareReplay(1),
    ))
  }

  private _updateContent(type: publicationType, mainId: string, secondaryMainId: string | undefined, item: IndexItem | undefined, contentId?: string | null) {
    this.loadingService.show();
    switch (type) {
      case 'liquid':
        if (item) {
          firstValueFrom(this.viewerService.getContent(mainId, item.id))
            .then((content) => {
              this.viewerPostMessageService.postMessage('content', content);
              if (contentId) {
                this.viewerPostMessageService.postMessage('scrollToParagraph', contentId);
              }
            })
            .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg));
        }
        break;
      case 'course':
        if (item) {
          this._setCourse(mainId, secondaryMainId, item.id)
            .then(({ course, content }) => {
              this.viewerPostMessageService.postMessage('content', content);
            })
            .catch((err) => this.popupService.error(this.configurationService.loadPublicationErrorMsg));
        }
        break;
      default:
        console.warn("Unsupported Content.")
        this.loadingService.hide();
        break;
    }
  }


  openUrl(url: string, local?: boolean | undefined, target?: string | undefined, type?: string | undefined) {
    switch (type) {
      default:
        window.open(url, '_blank')?.focus(); //TODO: per ora vengono aperti su un altra pagina
        break;
    }
  }

  /* ----------- END Content functions -------------- */

  // ------- Cut result listener ----------
  handleClippingResultPostMessage(result: string) {
    this.viewerService.toggleCutMode();

    this.$toolbarTools().find(tool => tool.id === 'cut')!.active = false;

    this.popupService.confirm(`<div class="flex justify-center"><img src="${result}"></div>`, {
      alertConfig: {
        header: { title: 'New clipping' },
      },
      labels: {
        confirm: 'Save'
      },
      size: 'medium',
      callbacks: {
        cancel: () => {
          document.getElementById('cut')?.focus();
        },
        confirm: () => {
          const mainId = this.viewerIndexService.$mainId();
          const secondaryMainId: string | undefined = this.viewerIndexService.getSecondaryMainId();
          const contentId: string | undefined = this.viewerIndexService.$currentContentId();

          if (mainId && contentId) {
            this.loadingService.show();
            const clipAnnotation: ViewerAnnotation = {
              type: "clipping",
              volumeId: mainId,
              moduleId: secondaryMainId,
              contentId: contentId,
              title: "Clipping",
              data: { clippingUrl: result },
            }

            firstValueFrom(this.viewerAnnotationListService.addAnnotation(clipAnnotation))
              .finally(() => {
                document.getElementById('cut')?.focus();
                this.loadingService.hide();
              });
          }
        }
      }
    });
  }

  toggleChat() {
    this.chatService.$currentCourse.set(this.$chatCourse());
    this.chatService.$isGlobal.set(false);
    this.chatService.toggleChat$.emit();
  }

  handlePlayMedia(media: LiquidViewerMedia) {
    if (media.type === 'video' && this.viewerIndexService.$publicationType() === 'course') {
      const currentIndexItem = this.viewerIndexService.$currentIndexItem();
      let titleFromCurrentIndex = '';
      if (currentIndexItem) {
        const viewerIds = [this.viewerIndexService.$publicationType(), this.viewerIndexService.$mainId(), this.viewerIndexService.getSecondaryMainId(), currentIndexItem.id].filter(id => !!id);
        titleFromCurrentIndex = `${currentIndexItem.title} - ${viewerIds.join('/')}`;
      }
      const course: Partial<Course> = {
        title: this.viewerIndexService.$currentIndex()?.title,
        uuid: this.viewerIndexService.$currentIndex()?.id,
      }
      const user = this.authenticationService.$currentUser();
      if (user) {
        this.analyticsService.trackEvent(SendlerMethod.VideoPlay, user, course, media, titleFromCurrentIndex);
      }
    }
  }
}
