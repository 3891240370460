import { Component, computed, inject, signal } from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, shareReplay } from 'rxjs';
import { environment } from 'src/index';
import { AuthenticationService, GDPRService, isMobile } from 'src/index/services.index';
import { HasDashboardSidebar } from '../../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent extends HasDashboardSidebar {
    authenticationService = inject(AuthenticationService);
    gdprService = inject(GDPRService);

    config = { ...environment.publicModule, hideSidebar: environment.dashboardModule?.hideSidebar, hideUserMenu: environment.dashboardModule?.hideUserMenu };
    hideFooter = environment.hideFooter;
    $loggedIn = toSignal(this.authenticationService.checklogin().pipe(
        map(user => !!user),
        shareReplay(1),
    ))
    $hasSidebar = computed(() => this.$loggedIn() && !this.config.hideSidebar && !isMobile());
    $sideBarShown = signal(!this.config.hideSidebar && !isMobile());
    override activeItem$ = new BehaviorSubject<string>('privacy-policy');

    mainLogo = environment.logo;
    name = environment.name;
    currentYear = new Date().getFullYear();
    footerText = `&copy; ${this.currentYear} ${this.config.footerText || environment.footerText || environment.name}`;

    gdprTitle = this.gdprService.getTitle();
    $gdprSections = computed(() => this.gdprService.getGDPRText())
}
