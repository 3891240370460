import { Component, EventEmitter, Output, computed, inject } from '@angular/core';
import { ViewerIndexService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
    selector: 'meta-viewer-calendar',
    templateUrl: './viewer-calendar.component.html',
    styleUrls: ['./viewer-calendar.component.css'],
})
export class ViewerCalendarComponent extends Accessible {
    public viewerIndexService = inject(ViewerIndexService);
    @Output() doClose = new EventEmitter();

    $events = computed(() => this.viewerIndexService.$currentEvents());

    onClose(): void {
        this.doClose.emit();
    }
}
