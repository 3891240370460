import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';

import { AccessibilitySetting, environment } from 'src/index';
import { ViewerContentService, ViewerPostMessageService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  fullscreenDynamicComponent$ = new BehaviorSubject<Type<any> | undefined>(undefined);
  //focusOnPage$ = new BehaviorSubject<boolean>(false);

  constructor(
    public httpClient: HttpClient,
    public viewerContentService: ViewerContentService,
    public viewerPostMessageService: ViewerPostMessageService,
  ) {
  }

  private accessibilitySetting: AccessibilitySetting | undefined = undefined;

  getPublication(pubId: string): Observable<any | undefined> {
    if (environment.getPublication) {
      return this.httpClient.get<any>(environment.getPublication.replace(':publicationId', pubId)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  getContent(pubId: string, contentId: string): Observable<any | undefined> {
    if (environment.getContent) {
      return this.httpClient.get<any>(
        environment.getContent.replace(':contentId', contentId).replace(':publicationId', pubId)
      ).pipe(
        catchError(() => of(undefined)),
        map(data => this.viewerContentService.handleData(data))
      );
    }
    return of(undefined);
  }

  getAccessibility() {
    return this.accessibilitySetting;
  }

  setAccessibilitySetting(setting: AccessibilitySetting) {
    this.accessibilitySetting = setting;
  }

  toggleCutMode() {
    this.viewerPostMessageService.postMessage('toggleCut', undefined);
  }
}

