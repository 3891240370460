import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';

import { environment, User } from 'src/index';
import { RoutingService, StorageService } from 'src/index/services.index';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService<T extends User> {
    public headersOptions = {
        'Content-Type': 'application/json',
    };
    public httpOptions = {
        headers: new HttpHeaders(this.headersOptions),
    };

    $currentUser = signal<T | undefined>(undefined);
    currentUser$ = toObservable(this.$currentUser);
    private currentToken: string = '';
    private sessionChecked = false;
    public redirectAfterLogin: {
        url: string,
        queryParams?: Params | null,
    } = { url: '' };

    storagetKeys = {
        tokenSession: 'tokenSession',
    };

    resetPasswordRoute = 'reset-password/:token';

    constructor(
        public httpClient: HttpClient,
        public storageService: StorageService,
        public routingService: RoutingService,
    ) {

    }

    login(username: string, password: string): Observable<T | undefined> {
        if (environment.login) {
            return this.httpClient.post<{ access_token: string, user: T }>(environment.login, { username, password }).pipe(
                catchError(error => throwError(() => error.error.message)),
                tap(response => {
                    this.setToken(response.access_token);
                    this.setUser(response.user);
                }),
                map(response => response.user),
            );
        }
        return of(undefined);
    }

    checklogin() {
        if (this.$currentUser()) {
            return of(this.$currentUser())
        }
        if (this.sessionChecked) {
            return of(undefined);
        }
        if (environment.checklogin) {
            return this.httpClient.get<{ access_token: string, user: T }>(environment.checklogin).pipe(
                catchError(error => of(undefined)),
                tap(response => {
                    this.sessionChecked = true;
                    if (response) {
                        this.setToken(response.access_token);
                        this.setUser(response.user);
                    }
                }),
                map(response => response?.user),
            );
        }
        return of(undefined);
    }

    logout() {
        if (environment.logout) {
            return this.httpClient.post(environment.logout, {})
                .pipe(
                    catchError(() => of()),
                    tap(() => {
                        this.clearSession();
                        this.routingService.goToLogin();
                    }),
                );
        }
        this.clearSession();
        return of();
    }

    getToken() {
        return this.currentToken || this.storageService.get(this.storagetKeys.tokenSession) || '';
    }

    setToken(token: string) {
        this.currentToken = token;
        this.storageService.set(this.storagetKeys.tokenSession, token);
    }

    setUser(user: T, local?: boolean) {
        this.$currentUser.set(user);
    }

    clearSession() {
        this.$currentUser.set(undefined);
        this.currentToken = '';
        this.sessionChecked = true;
        this.storageService.remove(this.storagetKeys.tokenSession);
    }

    resetPassword(username: string) {
        if (environment.resetPassword) {
            const resetUrl = `${window.location.origin}/${this.resetPasswordRoute}`;
            return this.httpClient.post<{ reset: boolean }>(environment.resetPassword, { username, resetUrl }).pipe(
                catchError(error => throwError(() => error.error.message)),
            );
        }
        return of(undefined);
    }
}

export interface AuthenticationService<T extends User> {
    login: (username: string, password: string) => Observable<T | undefined>;
    logout: () => Observable<Object>;
    checklogin: () => Observable<T | undefined>;
    setUser: (user: T, local?: boolean) => void;
    // refreshToken: () => void; // TODO
    // getStoredToken: () => string | undefined; // TODO
    // offlineLogin: (offlineLoginData: { username?: string, password?: string }) => Promise<T | undefined>;
    // autoLogin: () => Observable<T | undefined>;
}