import { Component, computed, inject, Input, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, firstValueFrom, of, shareReplay, switchMap } from 'rxjs';

import { CourseSubscriptionStatus, environment, SendlerMethod, User } from 'src/index';
import { AnalyticsService, AuthenticationService, CoursesService, LoadingService, RoutingService } from 'src/index/services.index';

@Component({
    selector: 'meta-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    authService = inject(AuthenticationService<User>);
    formBuilder = inject(FormBuilder);
    activatedRoute = inject(ActivatedRoute);
    router = inject(Router);
    loadingService = inject(LoadingService);
    routingService = inject(RoutingService);
    analyticsService = inject(AnalyticsService);
    courseService = inject(CoursesService);

    config = { ...environment.loginModule };
    @Input() showRegistration = !this.config?.hideRegistration;
    @Input() showPasswordForgotten = !this.config?.hidePasswordForgotten;

    appName = environment.name;
    logo = environment.logo;

    form: FormGroup = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
    });
    $submitted = signal(false);

    $errorMsg = signal('');

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    $queryParams = toSignal(this.activatedRoute.queryParams);
    $fromCourse = computed(() => this.$queryParams() ? this.$queryParams()!['fromCourse'] : undefined);
    $isFromCourse = computed(() => !!this.$fromCourse());
    $course = toSignal(toObservable(this.$fromCourse).pipe(
        filter(uuidCourse => !!uuidCourse),
        switchMap(uuidCourse => uuidCourse ? this.courseService.getCoursePublicDetails(uuidCourse) : of(undefined)),
        shareReplay(1),
    ));
    $courseNeedApproval = computed(() => this.$course()?.needApproval ?? false);
    $courseTitle = computed(() => [this.$course()?.title, this.$course()?.subtitle].filter(t => !!t).join(' '));

    $pageTitle = computed<string>(() => {
        if (this.$isFromCourse()) {
            return 'Sign in and register to course';
        }
        return 'Sign in to';
    });

    login() {
        this.$submitted.set(true);
        if (this.form.valid) {
            this.$errorMsg.set('');
            this.loadingService.show();
            firstValueFrom(this.authService.login(this.form.value.username, this.form.value.password))
                .then((user) => this.onAfterLogin(user))
                .catch(err => this.$errorMsg.set(err?.data))
                .finally(() => this.loadingService.hide());
        }
    }

    redirectAfterLogin(user: User) {
        const url = this.authService.redirectAfterLogin?.url || '';
        const queryParams = this.authService.redirectAfterLogin.queryParams || this.$queryParams() || {};
        this.router.navigate([url], { queryParams });
    }

    async onAfterLogin(user: User | undefined) {
        if (user) {
            this.analyticsService.trackEvent(SendlerMethod.Login, user);
            if (this.$isFromCourse()) {
                const subscriptionStatus = this.$courseNeedApproval() ? CourseSubscriptionStatus.AwaitingApproval : CourseSubscriptionStatus.Subscribed;
                await firstValueFrom(this.courseService.startCourseById(this.$fromCourse(), subscriptionStatus));
            }
            this.redirectAfterLogin(user);
        }
    }

    goToRegistration() {
        const queryParams = this.authService.redirectAfterLogin.queryParams || this.$queryParams() || {};
        this.routingService.goToRegistration(queryParams);
    }
}
