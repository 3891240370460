import { Injectable } from '@angular/core';
import { GDPRSection } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class GDPRService {
  getTitle() {
    return 'Privacy policy';
  }

  getGDPRText(): GDPRSection[] {
    // TODO: Costruire testo in base a funzionalità attive per il progetto
    return [{
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    }, {
      title: 'Curabitur pretium tincidunt lacus.',
      text: 'Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh euismod gravida. Duis ac turpis nulla. <strong>Sed vel erat ut leo aliquet tempor.</strong> Suspendisse commodo urna sit amet quam tristique, ac feugiat velit molestie. Vivamus quis arcu auctor, fermentum erat ac, laoreet ante. Donec mollis est ac orci suscipit, nec auctor ipsum vulputate. Mauris tincidunt, justo ac lacinia mollis, velit risus tempor est, quis suscipit lorem ante ac ante.',
    }, {
      title: 'Donec et gravida elit.',
      text: 'Aliquam erat volutpat. Mauris sit amet risus id odio dictum suscipit. Cras ut erat vitae tortor porttitor sollicitudin. Nam tempus, felis eu viverra condimentum, felis felis convallis augue, ac auctor nunc risus a leo. <strong>Phasellus convallis purus sed felis placerat, vel feugiat sapien malesuada.</strong>',
    }, {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    }, {
      title: 'Curabitur pretium tincidunt lacus.',
      text: 'Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh euismod gravida. Duis ac turpis nulla. <strong>Sed vel erat ut leo aliquet tempor.</strong> Suspendisse commodo urna sit amet quam tristique, ac feugiat velit molestie. Vivamus quis arcu auctor, fermentum erat ac, laoreet ante. Donec mollis est ac orci suscipit, nec auctor ipsum vulputate. Mauris tincidunt, justo ac lacinia mollis, velit risus tempor est, quis suscipit lorem ante ac ante.',
    }, {
      title: 'Donec et gravida elit.',
      text: 'Aliquam erat volutpat. Mauris sit amet risus id odio dictum suscipit. Cras ut erat vitae tortor porttitor sollicitudin. Nam tempus, felis eu viverra condimentum, felis felis convallis augue, ac auctor nunc risus a leo. <strong>Phasellus convallis purus sed felis placerat, vel feugiat sapien malesuada.</strong>',
    }];
  }

}

