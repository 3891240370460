import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
    selector: 'meta-viewer-credits',
    templateUrl: './viewer-credits.component.html',
    styleUrls: ['./viewer-credits.component.css'],
})
export class ViewerCreditsComponent extends Accessible {
    hideFooter = environment.viewerModule?.hideFooter ?? environment.hideFooter;

    @Output() doClose = new EventEmitter();

    onClose(): void {
        this.doClose.emit();
    }
}
