<main id="mainContent" class="flex-1">
    <section class="bg-primary min-h-screen px-base xl:px-base-desk">
        <div class="min-h-screen flex flex-col py-10">

            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="max-h-[3.75rem] mx-auto" [src]="logo || 'core/assets/images/logo.png'" [alt]="appName" />
            </div>
            <div class="w-full max-w-md mx-auto pb-12 mt-12 max-h-770:mt-0" [ngClass]="{
                'pt-10': !$isFromCourse()
            }">
                @if (!$isFromCourse()) {
                    <h1 class="text-[1.75rem] text-white text-center mb-6">{{$pageTitle() | translate:{ app: this.appName } }}</h1>
                }
                @if ($isFromCourse() && $course()) {
                    <div class="flex flex-col sm:flex-row gap-x-6 gap-y-4 text-start bg-white shadow rounded-md px-6 md:px-10 py-6 mt-6 mb-6">
                        <img class="max-h-[6.625rem] w-full sm:size-[6.5rem] object-cover"
                            [src]="$course()!.image" [alt]="$course()!.title" />
                        <div class="flex flex-col items-start gap-y-2 justify-start">
                            <h1 class="text-[1.4rem] leading-[2.025rem]  font-bold">{{$course()!.title || '' | translate}}</h1>
                            @if ($course()!.subtitle) {
                                <h2 class=" line-clamp-3">{{$course()!.subtitle || '' | translate}}
                                </h2>
                            }
                        </div>
                    </div>
                }
                <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                    @if ($isFromCourse()) {
                        <h1 class="mb-8 font-bold text-[1.2rem]">
                            {{$pageTitle() | translate:{ app: this.appName } }}
                        </h1>
                    }
                    <form class="space-y-7" [formGroup]="form" (ngSubmit)="login()" autocomplete="off" (change)="$errorMsg.set('')">
                        <div>
                            <label for="username" class="block text-[0.9375rem] font-semibold"
                                [ngClass]="{ '!text-red-600': $submitted() && f['username'].errors }">{{'Username' | translate}}</label>
                            <div class="mt-1">
                                <input id="username" name="username" type="text" class="h-10"
                                    required formControlName="username"
                                    [ngClass]="{ '!border-red-600': $submitted() && f['username'].errors }" />
                            </div>
                            @if ($submitted() && f['username'].errors) {
                                <div>
                                    <span class="text-red-600 text-sm">{{'Username is mandatory' | translate}}</span>
                                </div>
                            }
                        </div>

                        <div>
                            <label for="password" class="block text-[0.9375rem] font-semibold"
                                [ngClass]="{ '!text-red-600': $submitted() && f['password'].errors }">{{'Password' | translate}}</label>
                            <div class="mt-1">
                                <input id="password" name="password" type="password" class="h-10 !border-red-600" required formControlName="password"
                                    [ngClass]="{ '!border-red-600': $submitted() && f['password'].errors }" />
                            </div>
                            @if ($submitted() && f['password'].errors) {
                                <div>
                                    <span class="text-red-600 text-sm">{{'Password is mandatory' | translate}}</span>
                                </div>
                            }
                        </div>
                        <div>
                            <span class="text-red-600 text-sm">
                                {{$errorMsg() | translate}}
                                &nbsp;
                            </span>
                        </div>

                        <div class="!mt-9">
                            <button type="submit" [disabled]="!form.valid" [attr.aria-disabled]="!form.valid" 
                                class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2"
                                [ngClass]="{
                                    'btn-reset disabled': !form.valid,
                                    'btn-primary': form.valid
                                }">{{'Log in' | translate}}</button>
                        </div>

                        @if (showPasswordForgotten) {
                            <div class="text-[0.9375rem] text-right !mt-4">
                                <a href="#" class="hover:text-primary hover:underline">{{'Password forgotten?' | translate}}</a>
                            </div>
                        }
                    </form>
                </div>
            </div>
            @if (config.customMessage && config.customMessagePosition === 'below-form') {
                <div class="sm:mx-auto sm:w-full sm:max-w-2xl text-white text-center" [innerHTML]="config.customMessage | translate"></div>
            }
            @if (showRegistration) {
                <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto">
                    <p class="text-white">{{'Is this your first time using the app?' | translate:{value: appName} }}</p>
                    <a (click)="goToRegistration()"
                        class="text-secondary hover:text-secondary-hover underline hover:no-underline">
                        {{'Create your account' | translate}}
                    </a>
                </div>
            }
            @if (config.customMessage && config.customMessagePosition === 'below-registration') {
                <div class="sm:mx-auto sm:w-full sm:max-w-2xl text-white text-center" [innerHTML]="config.customMessage | translate"></div>
            }
        </div>
    </section>
</main>