import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, firstValueFrom, Observable, of, throwError } from "rxjs";

import { environment } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  saveExerciseExecution(exerciseExecutionData: any) {
    return firstValueFrom(
      environment.saveExerciseExecution ?
        this.httpClient.post(environment.saveExerciseExecution, exerciseExecutionData)
          .pipe(catchError(error => throwError(error))) :
        of(undefined));
  }

  getExerciseExecution(courseId: string, moduleId: string, contentId: string): Observable<any[]> {
    return environment.getExerciseExecution ?
      this.httpClient.get<any[]>(environment.getExerciseExecution.replace(':courseId', courseId).replace(':moduleId', moduleId).replace(':contentId', contentId))
        .pipe(catchError(error => of([]))) :
      of([])
  }

}

