import { Component, computed, inject, Signal, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, map, of, switchMap } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

import { CourseSubscriptionStatus, environment, HandleStringObjectPipe, OptionItem, RegistrationStepField, SendlerMethod, SkillLevelId, SkillsGroupDetails, User } from 'src/index';
import { AnalyticsService, AuthenticationService, ConfigurationService, CoursesService, LoadingService, PopupService, RoutingService, SkillsService, UserService } from 'src/index/services.index';

@Component({
    selector: 'meta-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
    popupService = inject(PopupService);
    configurationService = inject(ConfigurationService);
    formBuilder = inject(FormBuilder);
    userService = inject(UserService);
    router = inject(Router);
    loadingService = inject(LoadingService);
    authenticationService = inject(AuthenticationService<User>);
    skillsService = inject(SkillsService);
    routingService = inject(RoutingService);
    analyticsService = inject(AnalyticsService);
    activatedRoute = inject(ActivatedRoute);
    courseService = inject(CoursesService);
    translateService = inject(TranslateService)

    config = { ...environment.registrationModule };
    appName = environment.name;
    logo = environment.logo;
    $queryParams = toSignal(this.activatedRoute.queryParams);
    $fromCourse = computed(() => this.$queryParams() ? this.$queryParams()!['fromCourse'] : undefined);
    $isFromCourse = computed(() => !!this.$fromCourse());
    $course = toSignal(toObservable(this.$fromCourse).pipe(
        filter(uuidCourse => !!uuidCourse),
        switchMap(uuidCourse => uuidCourse ? this.courseService.getCoursePublicDetails(uuidCourse) : of(undefined)),
    ));
    $courseNeedApproval = computed(() => this.$course()?.needApproval ?? false);
    $courseTitle = computed(() => [this.$course()?.title, this.$course()?.subtitle].filter(t => !!t).join(' '));
    $privacyLink = toSignal(this.translateService.get('Privacy policy').pipe(
        map(label => `<a class="text-primary hover:underline" href="${this.config.privacyLink || location.origin}" target="_blank" title="${label}">${label}</a>`),
        shareReplay(1),
    ));

    submitted: boolean = false;

    $errorMsg = signal('');

    form = computed<FormGroup>(() => {
        const formSteps = this.$steps()?.reduce((controls, stepFields) => ({
            ...controls,
            ...stepFields.reduce((fields, field) => {
                if (field.inputType === 'checkbox' && field.$options && field.$options()!) {
                    return {
                        ...fields,
                        [field.id]: this.formBuilder.array(field.$options()!.map(() => new FormControl(false)), field.validators || []),
                    };
                } else {
                    return {
                        ...fields,
                        [field.id]: [field.default || '', field.validators || []],
                    };
                }
            }, {} as any),
        }), {} as any);
        return this.formBuilder.group(formSteps);
    });

    $controls = computed<{ [key: string]: AbstractControl }>(() => this.form().controls);

    private userSkillsGroups$ = this.skillsService.getUserSkillsGroups().pipe(
        shareReplay(1),
    );

    private stepsFields = this.configurationService.registrationStepFields;
    steps$ = of(this.config).pipe(
        switchMap(config => {
            let steps: RegistrationStepField[][] = [];
            if (config.registrationType === 'multipleSteps') {
                if (!config.hideAdditionalFields && this.stepsFields.length > 1) {
                    steps = [...this.stepsFields];
                } else {
                    steps = [this.stepsFields[0]];
                }
                if (!config.hideSkillsSetting) {
                    return this.userSkillsGroups$.pipe(
                        map(groups => steps.concat(groups.map(g => ([{ id: g.id, label: g.label, inputType: 'skillGroup' }])))),
                    );
                }
            } else {
                steps = [this.stepsFields[0]];
            }
            return of(steps);
        }),
        map(steps => {
            steps.forEach((stepFields, i) => {
                stepFields.forEach(
                    stepField => {
                        const stepFieldSignal = stepField as RegistrationStepFieldSignal;
                        if (stepFieldSignal.options) {
                            if (Array.isArray(stepFieldSignal.options)) {
                                stepFieldSignal.$options = Array.isArray(stepFieldSignal.options) ? signal(stepFieldSignal.options) : toSignal(stepFieldSignal.options);
                            }
                        }
                        return stepFieldSignal;
                    }
                )
                steps[i] = stepFields.filter(step => !step.excludeFromRegistration && (!step.showWhen ||
                    Object.keys(step.showWhen).some(k => {
                        const queryParams = this.$queryParams();
                        const paramValue = queryParams ? queryParams[k] : undefined;
                        if (typeof step.showWhen![k] === 'boolean') {
                            return (step.showWhen![k] && paramValue) || (!step.showWhen![k] && !paramValue);
                        } else {
                            return step.showWhen![k] === paramValue;
                        }
                    })));
            })
            return steps as RegistrationStepFieldSignal[][]
        }),
        map(steps => steps.filter(fields => fields.length > 0)),
        shareReplay(1),
    );
    $steps = toSignal(this.steps$);

    $totSteps = computed(() => this.$steps()?.length ?? 0);
    $hasSteps = computed(() => this.$totSteps() > 1);

    $currentStep = signal(1);
    $currentStepWidth = computed(() => (100 / this.$totSteps()) * this.$currentStep());

    $isFirstStep = computed(() => this.$currentStep() === 1);
    $isLastStep = computed(() => this.$totSteps() === this.$currentStep());

    $currentStepFields = computed(() => {
        const steps = this.$steps() || [];
        const currentStep = this.$currentStep();
        const currentStepFields = steps[currentStep - 1] || [];
        return currentStepFields.map(field => ({
            ...field,
            control: this.$controls()[field.id],
        }));
    });
    $showPrivacyDisclaimer = computed(() => this.$isFirstStep() && this.config.privacyDisclaimer);
    $currentStepSkippable = computed(() => {
        const steps = this.$steps() || [];
        return !(steps[this.$currentStep() - 1] || []).some(field => field.required);
    });

    $currentStepHasForm = computed(() => {
        const step = this.$currentStep();
        const steps = this.$steps();
        return step === 1 || (steps && !!steps[step - 1]);
    });
    $currentStepHasSurvey = computed(() => {
        const step = this.$currentStep();
        const steps = this.$steps();
        return step > 1 && steps && !steps[step - 1];
    })
    currentSurveyUserSkills: { [key: string]: SkillLevelId } = {}; // Used for data binding

    $currentSurveyData = computed(() => {
        const fields = this.$currentStepFields() || [];
        return fields[0];
    });
    private currenSurveyDetails$ = toObservable(this.$currentSurveyData).pipe(
        switchMap(fields => {
            if (fields?.inputType === 'skillGroup') {
                return this.skillsService.getUserSkillsGroupDetails(fields.id).pipe(
                    shareReplay(1),
                )
            }
            return of({ skills: [], levels: [] } as SkillsGroupDetails);
        }),
        shareReplay(1),
    );
    $currenSurveyDetails = toSignal(this.currenSurveyDetails$);
    $currentSurveySkills = computed(() => {
        const details = this.$currenSurveyDetails();
        return details?.skills ?? [];
    });

    $currentSurveySkillLevels = computed(() => {
        const details = this.$currenSurveyDetails();
        return details?.levels ?? [];
    });

    passwordsMatch(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value1 = control.parent?.value.password;
            return value1 !== control.value ? { password2: { value: control.value } } : null;
        };
    }

    getFieldAnswer(field: RegistrationStepFieldSignal) {
        const form = this.form();
        if (form) {
            if (field.inputType === 'checkbox' && field.$options) {
                return field.$options()?.map((opt, i) => ({
                    opt,
                    checked: form.value[field.id][i],
                })).filter(opt => opt.checked).map(({ opt }) => HandleStringObjectPipe.prototype.transform(opt, 'value'));
            }
            return form.value[field.id];
        }
        return undefined;
    }

    isFormValid() {
        return this.$currentStepFields().every(field => this.$controls()[field.id].valid)
    }

    async nextStep(skipCurrent?: boolean) {
        if (!skipCurrent) {
            this.submitted = true;
            this.$errorMsg.set('');
        }
        if (skipCurrent || this.isFormValid()) {
            const currentStep = this.$currentStep();
            const isLastStep = this.$isLastStep();
            const currentUser = this.authenticationService.$currentUser();
            const currentSurveyData = this.$currentSurveyData();
            if (currentStep > 1 && !skipCurrent) {
                let data = currentUser?.data ?? {};
                const steps = this.$steps();
                if (steps && steps[currentStep - 1]) { // Sono in uno step con il form
                    data = steps[currentStep - 1].reduce((fields, field) => ({
                        ...fields,
                        [field.id]: this.getFieldAnswer(field),
                    }), data);
                } else { // Sono in uno step "questionario" in cui i dati sono guidati dai risultati dell'api skillsGroups
                    data = {
                        ...data,
                        skills: {
                            ...data?.skills ?? {},
                            [currentSurveyData.id!]: this.currentSurveyUserSkills,
                        },
                    };
                }
                await this.updateProfile(data);
            }
            this.currentSurveyUserSkills = {}; // Resetto l'oggetto d'appoggio per il data-binding
            if (isLastStep) {
                this.enterPortal();
            } else {
                this.$currentStep.set(currentStep + 1);
            }
        }
    }

    updateProfile(data: { [key: string]: any }) {
        this.loadingService.show();
        return firstValueFrom(this.userService.saveData(data))
            .catch((reason) => this.$errorMsg.set(reason.message || 'An error occurred while saving the data'))
            .finally(() => this.loadingService.hide());
    }

    register() {
        this.submitted = true;
        this.$errorMsg.set('');

        if (this.isFormValid()) {
            this.loadingService.show();
            const newUser = this.prepareUserForRegistration();
            firstValueFrom(this.userService.registration(newUser))
                .catch((reason) => {
                    if (reason.code) {
                        switch (reason.code) {
                            case 1:
                                this.$errorMsg.set('Username already used');
                                break;
                            case 2:
                                this.$errorMsg.set('Email already used');
                                break;
                        }
                    } else {
                        this.$errorMsg.set(reason.message || 'An error occurred while registering');
                    }
                    return undefined;
                })
                .then((response) => this.onRegistrationDone(response))
                .finally(() => this.loadingService.hide());
        }
    }

    prepareUserForRegistration() {
        let newUser: { [key: string]: any } = {};
        Object.keys(this.form().value).forEach(
            fieldId => {
                if (this.$steps()) {
                    const field = this.$steps()![0].find(stepField => stepField.id === fieldId);
                    if (field && field.additionalData && this.form().value[field.id]) {
                        newUser['data'] = {
                            ...newUser['data'] || {},
                            [field.id]: this.getFieldAnswer(field),
                        }
                    } else if (field) {
                        newUser[field.id] = this.getFieldAnswer(field);
                    }
                }
            }
        )

        // Handle "fromCourse" query param
        if (this.$isFromCourse()) {
            newUser['data'] = {
                ...newUser['data'] || {},
                'fromCourse': this.$fromCourse(),
            }
        }

        return newUser;
    }

    async onRegistrationDone(response?: { access_token: string; user: User; }) {
        if (response) {
            this.analyticsService.trackEvent(SendlerMethod.Registration, response.user);
            this.popupService.success('Registration was successful. Complete your profile to get a personalized experience');
            this.authenticationService.setToken(response.access_token);
            this.authenticationService.setUser(response.user);
            if (this.$isFromCourse()) {
                const subscriptionStatus = this.$courseNeedApproval() ? CourseSubscriptionStatus.AwaitingApproval : CourseSubscriptionStatus.Subscribed;
                await firstValueFrom(this.courseService.startCourseById(this.$fromCourse(), subscriptionStatus));
            }
            if (this.$hasSteps()) {
                this.nextStep();
                this.submitted = false;
            } else {
                this.enterPortal();
            }
        }
    }

    goToLogin() {
        const queryParams = this.authenticationService.redirectAfterLogin.queryParams || this.$queryParams() || {};
        this.routingService.goToLogin(queryParams || {});
    }

    enterPortal() {
        const url = this.authenticationService.redirectAfterLogin?.url || '';
        const queryParams = this.authenticationService.redirectAfterLogin.queryParams || this.$queryParams() || {};
        this.router.navigate([url], { queryParams });
    }

    patchFormValue(fieldId: string, value: any) {
        this.form().patchValue({ [fieldId]: value });
    }
}

interface RegistrationStepFieldSignal extends RegistrationStepField {
    $options?: Signal<OptionItem[] | undefined>;
}