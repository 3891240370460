import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';

import { IndexItem, IndexParagraph, IndexTab } from 'src/index';
import { ViewerIndexService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
  selector: 'meta-viewer-index',
  templateUrl: './viewer-index.component.html',
  styleUrls: ['./viewer-index.component.css'],
})
export class ViewerIndexComponent extends Accessible {
  @Input() override verticalNavSelector = 'button:not([aria-selected="false"]), a';
  @Input() override horizontalNavSelector = 'button[role="tab"]';

  viewerIndexService = inject(ViewerIndexService);

  $index = computed(() => this.viewerIndexService.$currentIndex());
  $events = computed(() => this.viewerIndexService.$currentEvents());
  $currentIndexItemId = this.viewerIndexService.$currentContentId;

  @Output() doClose = new EventEmitter();

  selectedTab: string = "index";

  $tabList = computed(() => {
    const tabList: IndexTab[] = [
      {
        id: "index",
        label: "Index",
        visible: true,
      },
      {
        id: "attachments",
        label: "Attachments",
        visible: false,
      },
    ];
    if (this.$events().length > 0) {
      tabList.push({
        id: "events",
        label: "Events",
        visible: true,
      });
    }
    return tabList;
  });

  changeTab(tabId: string) {
    if (this.selectedTab === tabId) {
      return;
    }
    this.selectedTab = tabId;
  }

  toggleEntry(entry: IndexItem) {
    if (this.itemCanBeExploded(entry)) {
      entry.expanded = !entry.expanded;
    }
  }

  itemCanBeExploded(item: IndexItem) {
    return (item.children && item.children.length > 0)
      || (item.paragraphs && item.paragraphs.length > 0)
      || (item.subpages && item.subpages.length > 0);
  }

  // Event Listeners
  onItemClicked(evt: any, item: IndexItem | IndexParagraph) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    const pubType = this.viewerIndexService.$publicationType();
    const mainId = this.viewerIndexService.$mainId();
    if (pubType && mainId) {
      if (item.type === 'paragraph' && item.page) {
        this.toggleEntry(item.page);
        const itemClicked = {
          type: pubType,
          indexItem: item.page,
          mainId,
          paragraph: item
        };
        this.viewerIndexService.indexItemClicked.emit(itemClicked);
        this.onClose();
      }

      if (item.type === 'page') {
        this.toggleEntry(item);
        const itemClicked = {
          type: pubType,
          indexItem: item,
          mainId,
          secondaryMainId: this.viewerIndexService.getSecondaryMainId(item)
        }
        this.viewerIndexService.indexItemClicked.emit(itemClicked);
        this.onClose();
      }

      if (item.type === 'chapter') {
        this.toggleEntry(item);
      }
    }
  }

  onClose(): void {
    this.doClose.emit();
  }
}