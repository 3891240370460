<main id="mainContent" class="flex-1">
    <section class="min-h-screen flex flex-col px-base xl:px-base-desk py-6">
        <div class="pb-6 ml-auto">
            <a class="w-8 h-8 btn btn-primary items-center justify-center text-white text-xs rounded-full" (click)="close($event)">
                <span class="sr-only">{{'Close' | translate}}</span>
                <meta-icon icon="close" additionalClasses="text-xs"></meta-icon>
            </a>
        </div>
        @if (noFirstSearchDone$ | async) {
            <div class="flex-1 flex flex-col">
                <div class="flex-1 flex items-center justify-center">
                    <div class="relative w-full h-full flex flex-col items-center">
                        @if (baloonShown$ | async) {
                            <div id="askContent" class="absolute bottom-[8.5rem] w-full max-w-[21.875rem] flex flex-col bg-primary px-7 pt-5 pb-7 rounded-md">
                                <div class="mb-5">
                                    <p class="text-white text-[1.375rem] font-semibold leading-tight">
                                        {{'Hi!' | translate:{value:(currentUser$ | async)?.firstName} }}<br />
                                        {{'Do you need my help?' | translate}}
                                    </p>
                                </div>
                                <form (ngSubmit)="searchWithBot(botSearch.value)">
                                    <div class="relative flex items-center">
                                        <div class="flex-1">
                                            <label for="bot-search" class="sr-only">{{'Search' | translate}}</label>
                                            <div>
                                                <input id="bot-search" name="bot-search" type="text" class="h-10 !pr-12"
                                                    [ngModel]="botSearch$ | async" #botSearch />
                                            </div>
                                        </div>
                                        <div class="absolute right-0">
                                            <button type="submit"
                                                class="btn btn-primary w-10 h-10 items-center justify-center rounded !border !border-white">
                                                <span class="sr-only">{{'Search' | translate}}</span>
                                                <meta-icon icon="search"></meta-icon>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div class="w-4 h-4 bg-primary absolute -bottom-2 rotate-45 self-center" aria-hidden="true">
                                </div>
                            </div>
                        }
    
                        <button type="button" (click)="toggleBaloon$.emit()"
                            class="w-[6.75rem] rounded-full transition-all duration-100 hover:shadow hover:shadow-primary"
                            aria-controls="askContent" aria-expanded="true" [ngClass]="{
                                'shadow shadow-primary': baloonShown$ | async
                            }">
                            <img [src]="botImage" alt="" />
                        </button>
                    </div>
                </div>
            </div>
        }

        @if (firstSearchDone$ | async) {
            <div class="flex-1 flex flex-col">
                <div class="flex flex-col xl:flex-row gap-x-6">
                    <div>
                        <button type="button" (click)="toggleBaloon$.emit()"
                            class="w-[6.75rem] rounded-full transition-all duration-100 hover:shadow hover:shadow-primary"
                            aria-controls="askContent" aria-expanded="true" [ngClass]="{
                                'shadow shadow-primary': baloonShown$ | async
                            }">
                            <img [src]="botImage" alt="" />
                        </button>
                    </div>
    
                    <div id="askContent"
                        class="max-w-[90rem] flex-1 relative bg-primary rounded-md px-6 pt-5 pb-12 mt-4 xl:mt-0">
                        <div>
                            <div class="flex gap-x-3 pr-6">
                                <div class="flex-1">
                                    <p class="text-white text-xl leading-tight font-semibold mb-4">{{'Your request' | translate}}:
                                        {{botSearchRequest$ | async}}</p>
    
                                    @if ((botSearchTextResults$ | async)?.length) {
                                        <p class="mt-4 text-lg font-semibold text-white mb-2">
                                            {{'Here are the results I found' | translate}}:
                                        </p>
                                    }
                                    @for (chatMsg of botSearchTextResults$ | async; track $index) {
                                        <p class="text-lg text-white mb-2" [ngClass]="{
                                            'italic': $index % 2 !== 0
                                        }">
                                            @if (chatMsg.link) {
                                                <a [href]="chatMsg.link" class="cursor-pointer" target="_blank" [innerHTML]="chatMsg.text"></a>
                                            } @else {
                                                <span [innerHTML]="chatMsg.text"></span>
                                            }
                                        </p>
                                    }
                                    
                                    @if ((botSearchResults$ | async)?.length) {
                                        <p class="mt-4 text-lg font-semibold text-white mb-2">
                                            {{'Here is the content I found' | translate}}:</p>
                                        <div class="flex-1 flex flex-col">
                                            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-x-7 gap-y-7">
                                                @for (content of botSearchResults$ | async; track $index) {
                                                    <meta-content-preview [contentType]="content.paragraphId ? 'paragraph' : content.type || 'paragraph'"
                                                        [title]="content.title" [description]="content.description"
                                                        [cover]="content.image" [showImage]="true" [showDescription]="true"
                                                        [showContentTypeIcon]="true" (click)="openContent(content)"
                                                        class="cursor-pointer">
                                                    </meta-content-preview>
                                                }
                                            </div>
                                        </div>
                                    }
    
                                    @if (noBotSearchResults$ | async) {
                                        <p class="mt-4 text-2xl font-semibold text-white">
                                            {{'Sorry, I could not find any content related to your search' | translate}}
                                        </p>
                                    }
                                </div>
                            </div>
    
    
                            <div class="mt-10">
                                <form (ngSubmit)="searchWithBot(botSearch.value)">
                                    <div class="relative flex items-center">
                                        <div class="flex-1">
                                            <label for="bot-search" class="sr-only">{{'Search' | translate}}</label>
                                            <div>
                                                <input #botSearch [ngModel]="botSearch$ | async" id="bot-search"
                                                    name="bot-search" type="text" class="h-10 !pr-12"
                                                    [placeholder]="lastSearchConcluded ? 'Start a new request' : 'Provide more details to get results'">
                                            </div>
                                        </div>
                                        <div class="absolute right-0">
                                            <button type="submit"
                                                class="btn btn-primary w-10 h-10 items-center justify-center rounded !border !border-white">
                                                <span class="sr-only">{{'Search' | translate}}</span>
                                                <meta-icon icon="search"></meta-icon>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
    
                        <div class="w-4 h-4 bg-primary absolute -top-2 xl:top-11 left-12 xl:-left-2 rotate-45 self-center"
                            aria-hidden="true"></div>
                    </div>
                </div>
            </div>
        }
    </section>
</main>

@if (contentToPreview$ | async; as contentToPreview) {
    <meta-modal class="courseDetails" (doClose)="contentToPreview$.next(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
        <div modal-content>
            @if (contentToPreview.paragraphId === undefined) {
                @if (contentToPreview.type === 'course') {
                    <meta-course-detail [course]="contentToPreview" [needActivation]="!contentToPreview.associatedToUser"
                        (openCourse)="startCourse(contentToPreview, $event)" />
                } @else if (contentToPreview.type === 'guide') {
                    <meta-guide-detail [guide]="contentToPreview" (openGuide)="openGuide(contentToPreview)" />
                }
            } @else {
                <meta-content-detail type="content" [cover]="contentToPreview.image"
                    [title]="contentToPreview.title" [description]="contentToPreview.description"
                    [openBtnLabel]="contentToPreview.type === 'guide' ? 'Read the manual' : (contentToPreview.type === 'course' && contentToPreview.associatedToUser && contentToPreview.progress) ? (contentToPreview.progress * 1 >= 100 ? 'Review the course' : 'Continue the course') : 'Start the course'"
                    (openContent)="openParagraph(contentToPreview)"></meta-content-detail>
            }
        </div>
    </meta-modal>
}