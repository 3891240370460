import { Injectable } from '@angular/core';

import { GDPRService } from 'src/core/index/services.index';
import { GDPRSection } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class GDPRServiceCustom extends GDPRService {
    override getTitle() {
        return `Informativa resa ai sensi dell’art. 13 del Regolamento UE n. 2016/679`;
    }

    override getGDPRText(): GDPRSection[] {
        return [{
            text: `<p class="mb-4">
                Gentile Studente, <br /><br />desideriamo informarLa che il Regolamento UE n. 679/2016
                “Regolamento generale sulla protezione dei dati” prevede la tutela delle persone fisiche con
                riguardo al trattamento dei dati personali. Secondo la normativa indicata, tale trattamento sarà
                improntato ai principi di liceità, correttezza e trasparenza, adeguatezza, pertinenza e limitazione,
                esattezza e aggiornamento, non eccedenza e responsabilizzazione.
                I dati personali sono trattati ai sensi dell’art. 6, comma 1, lettera a).
            </p>
            <p class="mb-4">
                Pertanto, Le forniamo le seguenti informazioni:
            </p>
            <p class="mb-4">
                1. I dati da Lei forniti (<em>nome, cognome, indirizzo e-mail, titolo di studio, Ente o Università di appartenenza,
                    dipartimento o corso di studi</em>)
                verranno trattati dall’Istituto di Linguistica Computazionale “A. Zampolli” di Pisa e dagli altri partner del
                Progetto H2IOSC
                (elenco esaustivo disponibile al seguente link:
                <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="https://www.h2iosc.cnr.it/partner-institutions/" target="_blank"
                    title="Elenco partner del progetto H2IOSC">https://www.h2iosc.cnr.it/partner-institutions/</a>) per:
            <ul class="list-disc list-inside ml-4">
                <li>Gestire la sua iscrizione nella piattaforma in qualità di studente e consentirle l’accesso alle funzionalità
                    disponibili</li>
                <li>Permettere di gestire il suo percorso formativo</li>
                <li>Crearle una pagina personale e privata sulla piattaforma, che includerà i corsi seguiti, i badge conseguiti e i
                    suoi dati di carriera formativa</li>
            </ul>

            </p>
            <p class="mb-4">
                A questo scopo le sarà chiesto, in fase di iscrizione alla piattaforma, di fornirci i dati personali sopra elencati
                inserendoli direttamente nella maschera di registrazione, mentre i dati di carriera si alimenteranno direttamente
                alla conclusione di ciascun percorso formativo.
            </p>
            <p class="mb-4">
                I dati raccolti verranno conservati per il periodo di tempo in cui lei deciderà di mantenere l’account attivo.
                La sua decisione di eliminare il proprio profilo ed i suoi dati può essere inviata in qualunque momento inviando
                un’e-mail all’account <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="mailto:formazione@clarin-it.it"
                    title="Indirizzo email da contattare per richiedere eliminazione del profilo">formazione@clarin-it.it</a>
            </p>
            <p class="mb-4">
                I Partner del progetto e gli amministratori del sistema avranno accesso ai dati prima della loro pseudonimizzazione.
                Gli insegnanti avranno visibilità del suo nome e cognome, del suo indirizzo e-mail e dei suoi dati di carriera
                necessari per svolgere la funzione didattica.
            </p>
            <p class="mb-4">
                Inoltre, se decide di scrivere nella chat del/dei suo/suoi corso/corsi la avvertiamo che il suo nome,
                il suo cognome e i messaggi scambiati vengono salvati in un database Redis con accesso controllato tramite
                autenticazione.
                Tuttavia, è importante notare che i dati <strong>non sono crittografati</strong> nel database. La comunicazione tra
                il web server e la chat
                è protetta attraverso il protocollo <strong>WSS (Web Socket Secure)</strong>, che garantisce la sicurezza della
                trasmissione dei dati, equivalente al protocollo HTTPS.
            </p>
            <p class="mb-4">
                2. Il conferimento dei dati personali è obbligatorio e l’eventuale rifiuto di fornire tali dati ha come conseguenza
                l’impossibilità di iscriversi alla piattaforma. Potrà, comunque, decidere di revocare il suo consenso in qualsiasi
                momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca.
            </p>
            <p class="mb-4">
                3. Il trattamento dei dati personali avviene con modalità informatiche, in modo da garantire la riservatezza e la
                sicurezza degli stessi esclusivamente da parte di persone autorizzate al trattamento. Il titolare del trattamento
                metterà in atto misure tecniche e organizzative adeguate, volte ad attuare in modo efficace i principi di protezione
                dei dati e a tutelare i diritti degli interessati.
            </p>
            <p class="mb-4">
                4. I dati saranno visualizzabili anche da altri soggetti, in particolare le persone che sviluppano la parte
                informatica della piattaforma, dipendenti di ETT S.P.A., nominata Responsabile Esterno.
            </p>
            <p class="mb-4">
                5. I dati personali saranno conservati per il tempo necessario al perseguimento delle finalità per i quali sono
                stati
                richiesti.
            </p>
            <p class="mb-4">
                6. Il Titolare del trattamento è Consiglio Nazionale delle Ricerche nella sua articolazione interna, ai sensi
                dell’art. 2 quaterdecies,
                comma 1, del d.lgs. 196/2003 - giusta delibera del CdA del CNR n. 53/2019 e provvedimento del Presidente CNR n.
                27/2019,
                dell’Istituto di Linguistica Computazionale “Antonio Zampolli” (di seguito indicato come CNR-ILC), direttore f.f.
                dott.ssa Simonetta Montemagni,
                domiciliata per la carica presso la sede legale Via Giuseppe Moruzzi 1, 56124 Pisa, C.F. 80054330586, P. IVA
                02118311006
            </p>
            <p class="mb-4">
                7. Il Designato al trattamento è la dott.ssa Monica Monachini, Responsabile Scientifico del Progetto H2IOSC. Le
                persone autorizzate al trattamento sono i ricercatori ed i tecnologi del CNR, sia a TI che a TD, che lavorano nel
                WP8 del progetto H2IOSC, di cui è Responsabile la dott.ssa Francesca Frontini, Ricercatrice del CNR - Istituto di
                Linguistica Computazionale “A. Zampolli”.
            </p>
            <p class="mb-4">
                8. Presso il Titolare del trattamento è presente il Responsabile della protezione dei dati, nominato ai sensi
                dell’art. 37 del Regolamento UE 2016/679. Il responsabile della protezione dei dati può essere contattato ai
                seguenti indirizzi: e-mail <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="mailto:rpd@cnr.it" title="Indirizzo email del responsabile della protezione dei dati">rpd@cnr.it</a>, PEC
                (posta elettronica
                certificata): <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="mailto:rpd@pec.cnr.it"
                    title="Indirizzo PEC del responsabile della protezione dei dati">rpd@pec.cnr.it</a>.
            </p>
            <p class="mb-4">
                9. In ogni momento potrà esercitare i Suoi diritti di cui alla sezione 2, 3 e 4 del capo III del Regolamento UE n.
                679/2016 (es. diritti di informazione e accesso, di rettifica e cancellazione, di limitazione e di opposizione al
                trattamento, di portabilità dei dati personali).
            </p>
            <p class="mb-4">
                10. Lei ha il diritto di presentare un reclamo all’autorità di controllo (in Italia l’Autorità Garante per la
                protezione dei dati personali, <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="www.garanteprivacy.it" target="_blank"
                    title="Sito web dell’Autorità Garante per la privacy in Italia">www.garanteprivacy.it</a>, e-mail:
                <a class="text-secondary hover:text-secondary-hover underline hover:no-underline" href="mailto:protocollo@gpdp.it"
                    title="Indirizzo email dell’Autorità Garante per la privacy in Italia">protocollo@gpdp.it</a> PEC:
                <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="mailto:protocollo@pec.gpdp.it"
                    title="Indirizzo PEC dell’Autorità Garante per la privacy in Italia">protocollo@pec.gpdp.it</a>
                centralino tel. <a class="text-secondary hover:text-secondary-hover underline hover:no-underline"
                    href="tel:06696771" title="Contatto telefonico dell’Autorità Garante per la privacy in Italia">06696771</a>).
            </p>`
        }];
    }
}