@if ($hasSuggestedCourses()) {
    <section [ngClass]="{
        'mt-8': title
    }">
        @if (title) {
            <h2 class="text-3xl font-bold mb-4">{{title | translate}}</h2>
        }
        @if (layout === 'light') {
            <div class="grid grid-cols-4 gap-y-3 mt-3">
                @if ($coursesLoading()) {
                    <meta-loading [standAlone]="true" />
                } @else {
                    @for (course of $courses(); track $index) {
                        <div class="w-20">
                            <a (click)="previewCourse.emit(course)" class="cursor-pointer">
                                <img class="w-20 h-20 object-cover" [src]="course.image" [attr.alt]="course.title | translate">
                            </a>
                            <a class="inline-block text-sm leading-[1.1] hover:underline mt-1 cursor-pointer"
                                (click)="previewCourse.emit(course)">{{course.title | translate}}</a>
                        </div>
                    }
                }
            </div>
        } @else {
            <div class="relative swiper-c03 js-swiper-c03">
                @if ($coursesLoading()) {
                    <meta-loading [standAlone]="true" />
                } @else {
                    <swiper [config]="$swiperConfig()">
                        @for (course of $courses(); track $index) {
                            <ng-template swiperSlide>
                                <meta-course-preview [course]="course" [showImage]="layout !== 'study-path'" [showDescription]="layout !== 'study-path'"
                                    [showSkills]="layout !== 'study-path'" [showStudents]="layout === 'study-path'"
                                    (doClick)="previewCourse.emit(course)" class="cursor-pointer"/>
                            </ng-template>
                        }
                    </swiper>
                    <div class="swiper-pagination" id="swiperSuggestedCoursesPagination"></div>
                }
            </div>
        }
    </section>
}