import { Component, ElementRef, EventEmitter, ViewChild, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, filter, firstValueFrom, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { AdvancedSearchData, Content, Course, CourseSubscriptionStatus, FiltersSearch, Guide, SearchWithFiltersPayload, SettingsSearch, User, environment } from 'src/index';
import { AuthenticationService, CoursesService, GuidesService, LoadingService, PopupService, RoutingService, SearchService, isMobile, searchFiltersToFilterGroup } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class GlobalSearchComponent extends HasDashboardSidebar {
    @ViewChild('search') searchInput!: ElementRef;

    authService = inject(AuthenticationService<User>);
    coursesService = inject(CoursesService);
    guidesService = inject(GuidesService);
    loadingService = inject(LoadingService);
    popupService = inject(PopupService);
    route = inject(ActivatedRoute);
    router = inject(Router);
    routingService = inject(RoutingService);
    searchService = inject(SearchService);

    config = {
        ...environment.globalSearchModule,
        simpleSearchSettings: this.configurationService.simpleSearchSettings || [],
        hideSuggestedCourses: environment.hideSuggestedCourses || environment.globalSearchModule?.hideSuggestedCourses || false,
        suggestionsLayout: environment.suggestionsLayout || environment.globalSearchModule?.suggestionsLayout || 'default',
    };
    contentsPreviewConfig = {
        ...this.config.contentsPreviewConfig,
    };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    appName = environment.name;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    $sideBarShown = signal(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('search');

    updateData$ = new EventEmitter();
    $currentPage = signal<number>(1);
    $searchContents = signal<string | undefined>(undefined);
    uuidCourseToOpen = '';
    queryParams$ = this.route.queryParams.pipe(
        tap((queryParams) => {
            if (queryParams['fromCourse']) {
                this.uuidCourseToOpen = queryParams['fromCourse'];
            }
        }),
        map(params => params['q'] || ''),
        tap(() => this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { q: undefined, fromCourse: undefined },
            queryParamsHandling: 'merge',
        })),
        filter(q => !!q),
    );

    $queryParams = toSignal(this.queryParams$);

    $search = computed(() => {
        const q = this.$queryParams();
        const s = this.$searchContents();
        return s === undefined ? q : s;
    });

    fixedSearchFilters = this.configurationService.fixedSearchFilters;
    simpleSearchSettings = this.config.simpleSearchSettings.map(item => ({ type: item.value, checked: true }));

    $searchFilters = signal<FiltersSearch[]>([]);
    $searchSettings = signal<SettingsSearch | undefined>(this.getUpdatedSettings());
    $currentSearchFilters = computed(() => {
        const data = {
            filters: this.$searchFilters() || [],
            settings: this.$searchSettings() || {}
        };
        return {
            ...data,
            settings: data.settings ? Object.keys(data.settings).reduce((x, y) => ({
                ...x,
                [y]: Array.isArray(data.settings![y]) ? data.settings![y] : [data.settings![y]],
            }), {} as SettingsSearch) : undefined,
        }
    });

    results$ = this.updateData$.pipe(
        startWith(undefined),
        map(() => {
            const page = this.$currentPage();
            const search = this.$search() || this.configurationService.defaultSearchAllQuery
            const { filters, settings } = this.$currentSearchFilters();
            return {
                page,
                search: this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value.length > 0 ?
                    this.searchInput.nativeElement.value : search,
                filters,
                settings
            } as Partial<SearchWithFiltersPayload>
        }),
        tap(() => this.loadingService.show()),
        mergeMap(reqData => this.searchService.search(reqData).pipe(shareReplay(1))),
        map(res => ({
            ...res,
            results: res.results.map(content => {
                if (content.type === 'course') {
                    return this.coursesService.integrateCourseData(content) as Content;
                }
                return content;
            }),
        })),
        startWith(undefined),
        tap(data => {
            if (this.uuidCourseToOpen) {
                const courseToOpen = (data?.results || []).find(c => c.uuid === this.uuidCourseToOpen);
                if (courseToOpen) {
                    this.openContent(courseToOpen);
                }
            }
        }),
        tap(() => this.loadingService.hide()),
        shareReplay(1),
    );

    $results = toSignal(this.results$.pipe(map(results => results)));

    studyPathCourses$ = this.updateData$.pipe(
        startWith(undefined),
        switchMap(() => this.coursesService.getStudyPathCourses()),
        map(courses => courses || []),
        shareReplay(1),
    );
    $studyPathCourses = toSignal(this.studyPathCourses$);

    $content = computed(() => {
        const user = this.authService.$currentUser();
        const studyPath = this.$studyPathCourses();
        const results = this.$results()?.results || [];
        return results.map(r => {
            let associatedToUser = false;
            let progress: number | undefined;
            let status: CourseSubscriptionStatus | undefined;
            if (r.type === 'guide') {
                const index = user?.data?.guides?.indexOf(r.uuid) || -1;
                associatedToUser = index >= 0;
            }
            if (r.type === 'course') {
                const courseInStudyPath = studyPath?.find(c => c.uuid === r.uuid);
                associatedToUser = !!courseInStudyPath;
                progress = courseInStudyPath?.progress;
                status = courseInStudyPath?.status;
            }

            const item: SearchContent = {
                ...r,
                associatedToUser,
                progress,
                status,
                description: r.paragraphId && r.texts?.length ? r.texts.join('<br/>') : r.description,
            };
            if (r.type === 'course') {
                return this.coursesService.integrateCourseData(item as Course) as SearchContent;
            }
            return item;
        });
    });

    $filters = computed(() => searchFiltersToFilterGroup(this.$results()));

    $fixedFilters = computed(() => {
        const results = this.$results();
        return (this.configurationService.fixedSearchFilters || []).map(f => ({
            ...f,
            selectedValue: f.type === 'radio' ? results?.filtersApplied?.settings[f.id][0] : results?.filtersApplied?.settings[f.id],
        }));
    });

    $searchApplied = computed(() => {
        const search = this.$search();
        const filters = this.$currentSearchFilters().filters;
        const settings = this.$currentSearchFilters().settings;
        return (!!search && search !== '*') || (filters && filters.length > 0) || (settings && Object.keys(settings).length > 0);
    })
    $noResults = computed(() => {
        const searchResults = this.$results();
        if (searchResults) {
            const searchApplied = this.$searchApplied();
            return !searchApplied && searchResults && searchResults.results.length === 0;
        }
        return true;
    });

    $hasResults = computed(() => !this.$noResults());

    $noFilteredResults = computed(() => {
        const searchResults = this.$results();
        if (searchResults) {
            const searchApplied = this.$searchApplied();
            return searchApplied && searchResults && searchResults.results?.length === 0;
        }
        return true;
    });

    $pagination = computed(() => {
        const results = this.$results();
        return {
            page: results?.page || 1,
            pages: results?.pages || 1,
        }
    });

    $hasPagination = computed(() => {
        const { pages } = this.$pagination();
        return pages > 1;
    });

    $advancedSearchShown = signal(false);

    $contentToPreview = signal<Content | undefined>(undefined);
    $courseToPreview = computed(() => {
        if (this.$contentToPreview()?.type === 'course') {
            return this.$contentToPreview() as Course;
        }
        return undefined;
    });
    $guideToPreview = computed(() => {
        if (this.$contentToPreview()?.type === 'guide') {
            return this.$contentToPreview() as Guide;
        }
        return undefined;
    })

    loadPageResults(page: number) {
        this.$currentPage.set(page);
        this.updateData$.emit();
    }

    updateContentToPreview(content: Content) {
        if (content.type === 'course') {
            this.$contentToPreview.set({ ...content });
            this.updateData$.emit();
        }
    }

    openContent(content: Content) {
        if (!content.paragraphId && content.type === 'course') {
            this.loadingService.show();
            firstValueFrom(this.coursesService.getCourseDetails(content))
                .then(completeCourse => {
                    this.$contentToPreview.set(completeCourse);
                    if (!completeCourse) {
                        this.popupService.error(this.configurationService.getCourseDetailErrorMsg);
                    }
                })
                .catch(() => this.popupService.error(this.configurationService.getCourseDetailErrorMsg))
                .finally(() => this.loadingService.hide());
        } else {
            this.$contentToPreview.set({ ...content });
        }
    }

    searchAdvanced(data: AdvancedSearchData) {
        this.$searchFilters.update(filters => [
            ...filters || [],
            ...data.selectedFilters || [],
        ]);

        this.$searchSettings.update(settings => ({
            ...settings || {},
            ...data.settings || {},
        }));
        this.updateData$.emit();
        this.$advancedSearchShown.set(false);
    }

    isFilterCheckboxValueSelected(value: string) {
        return this.simpleSearchSettings.find(item => item.type === value && item.checked);
    }

    updateCheckboxValue($event: Event, value: string) {
        const selectedIndex = this.simpleSearchSettings.findIndex(item => item.type === value);
        if (($event.target as HTMLInputElement).checked) {
            if (selectedIndex < 0) {
                (this.simpleSearchSettings as any[]).push({ type: value, checked: true });
            }
        } else {
            if (selectedIndex >= 0) {
                if (this.simpleSearchSettings.filter(item => item.checked).length > 1) {
                    (this.simpleSearchSettings as any[]).splice(selectedIndex, 1);
                } else {
                    $event.preventDefault();
                    ($event.target as HTMLInputElement).checked = true;
                    return false;
                }
            }
        }

        this.$searchSettings.set(this.getUpdatedSettings());
        this.updateData$.emit();
        return true;
    }

    getUpdatedSettings() {
        return this.fixedSearchFilters.reduce((x, y) => ({
            ...x,
            [y.id]: y.showInSimpleSearch ? this.simpleSearchSettings.filter((i: any) => i[y.id] && i.checked).map((i: any) => i[y.id]) : y.selectedValue,
        }), {} as SettingsSearch);
    }

    startCourse(course: Course, urlToOpen?: string | void) {
        if (course.associatedToUser) {
            this.goToCourse(course, urlToOpen);
            this.$contentToPreview.set(undefined);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.$contentToPreview.set(undefined);
                    this.goToCourse(course, urlToOpen);
                })
                .catch((err) => this.popupService.error(err.message || this.configurationService.startCourseErrorMsg))
                .finally(() => this.loadingService.hide());
        }
    }

    goToCourse(course: Course, urlToOpen?: string | void) {
        if (urlToOpen) {
            return this.router.navigateByUrl(urlToOpen);
        }
        return this.coursesService.openCourse(course);
    }

    openGuide(guide: Guide) {
        this.$contentToPreview.set(undefined);
        firstValueFrom(this.guidesService.associateGuideToUser(guide.uuid))
            .finally(() => {
                if (guide.paragraphId) {
                    this.routingService.goToGuide(guide.uuid, guide.contentId, guide.paragraphId);
                } else {
                    if (guide.associatedToUser) {
                        // TODO: Aprire manuale all'ultima pagina visualizzata
                    }
                    this.routingService.goToGuide(guide.uuid, guide.startId, guide.paragraphId);
                }
            });
    }

    openParagraph(content: Course | Guide) {
        if (content.type === 'course') {
            this.startCourse(content);
        } else if (content.type === 'guide') {
            this.openGuide(content);
        }
    }

    isCourseCompleted(content: Content) {
        return content.type === 'course' && (content.progress || 0) * 1 >= 100;
    }

    trackContent(index: number, content: Content) {
        return `${content.uuid}_${content.associatedToUser}`;
    }
}

type SearchContent = Content & {
    associatedToUser: boolean;
    progress?: number;
    description: string;
    status: CourseSubscriptionStatus | undefined;
}