<div class="relative flex-1 flex flex-col px-base overflow-y-auto">
    <div class="sticky top-0 bg-white w-full h-12 px-1 pt-base flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="school-course"></meta-icon>
            <h1 class="inline-block">{{'Course details' | translate}}</h1>
        </div>
        <button class="inline-flex items-center justify-center gap-x-2" (click)="doClose.emit()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    <div class="px-1 flex flex-col overflow-y-auto mt-4 pt-2 border-t border-t-gray-300 scrollbar-custom">
        @if ($course()) {
            <div class="flex">
                <div class="flex flex-col">
                    <h1 class="text-3xl font-semibold">{{$course()!.title | translate}}</h1>
                    @if ($course()!.authors) {
                        <h2 class="text-xl font-semibold">
                            @for (author of $course()!.authors; track $index) {
                                {{ author }}{{ $last ? '' : ', '}}
                            }
                        </h2>
                    }
                    @if ($course()!.duration) {
                        <div class="inline-flex items-center mt-2">
                            <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
                            <span>{{$course()!.duration}}</span>
                        </div>
                    }
                </div>
            </div>
            <div class="flex flex-col gap-x-6 mt-6">
                <div>
                    @if ($course()!.abstract) {
                        <div class="text-sm px-1">
                            <p [innerHTML]="$course()!.abstract! | translate"></p>
                        </div>
                    }
                    @if ($course()!.mainLanguage) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Primary Language' | translate}}: </span>
                                <span class="italic">{{ $course()!.mainLanguage! | translate }}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.keywords?.length) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Keywords' | translate}}: </span>
                                <span class="italic">
                                    @for (keyword of $course()!.keywords; track $index) {
                                        {{ keyword | translate }}{{ $last ? '' : ', '}}
                                    }
                                </span>
                            </p>
                        </div>
                    }
                    @if ($course()!.skillLevel) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Skill level' | translate}}: </span>
                                <span class="italic">{{ $course()!.skillLevel! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.license) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'License' | translate}}: </span>
                                <span class="italic">{{ $course()!.license! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.versionDate) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Version date' | translate}}: </span>
                                <span class="italic">{{ $course()!.versionDate! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.resourceUrl) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'URL to Resource' | translate}}: </span>
                                <span class="italic"><a [href]="$course()!.resourceUrl" target="_blank">{{ $course()!.resourceUrl! | translate}}</a></span>
                            </p>
                        </div>
                    }
                    @if ($course()!.targetGroups?.length) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Target groups' | translate}}: </span>
                                <span class="italic">
                                    @for (group of $course()!.targetGroups; track $index) {
                                        {{ group | translate }}{{ $last ? '' : ', '}}
                                    }
                                </span>
                            </p>
                        </div>
                    }
                    @if ($course()!.learningResourceType) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Learning Resource Type' | translate}}: </span>
                                <span class="italic">{{ $course()!.learningResourceType! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.prerequisites) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Prerequisites' | translate}}: </span>
                                <span class="italic">{{ $course()!.prerequisites! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.learningOutcome) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Learning Outcome' | translate}}: </span>
                                <span class="italic">{{ $course()!.learningOutcome! | translate}}</span>
                            </p>
                        </div>
                    }
                    @if ($course()!.accessCost) {
                        <div class="text-sm mt-3 px-1">
                            <p class="text-[#767676] leading-tight">
                                <span class="text-primary-light font-bold">{{'Access cost' | translate}}: </span>
                                <span class="italic">{{ $course()!.accessCost! | translate}}</span>
                            </p>
                        </div>
                    } 
                </div>
                @if ($modules().length > 0) {
                    <div class="mt-3">
                        <p class="text-xl text-primary font-bold">{{'Modules' | translate}}</p>
                        <div class="flex flex-col gap-y-3 mt-3 mb-3">
                            @for (module of $modules(); track $index) {
                                <div class="text-primary-light font-bold">{{$index + 1}}. {{module.title | translate}} </div>
                                <div>
                                    @if (module.abstract && module.abstract !== $course()?.abstract) {
                                        <div class="text-sm px-1">
                                            <p [innerHTML]="module.abstract! | translate"></p>
                                        </div>
                                    }
                                    @if (module.mainLanguage && module.mainLanguage !== $course()?.mainLanguage) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Primary Language' | translate}}: </span>
                                                <span class="italic">{{ module.mainLanguage! | translate }}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.keywords?.length) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Keywords' | translate}}: </span>
                                                <span class="italic">
                                                    @for (keyword of module.keywords; track $index) {
                                                        {{ keyword | translate }}{{ $last ? '' : ', '}}
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.skillLevel && module.skillLevel !== $course()?.skillLevel) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Skill level' | translate}}: </span>
                                                <span class="italic">{{ module.skillLevel! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.license && module.license !== $course()?.license) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'License' | translate}}: </span>
                                                <span class="italic">{{ module.license! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.versionDate && module.versionDate !== $course()?.versionDate) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Version date' | translate}}: </span>
                                                <span class="italic">{{ module.versionDate! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.resourceUrl && module.resourceUrl !== $course()?.resourceUrl) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'URL to Resource' | translate}}: </span>
                                                <span class="italic"><a [href]="module.resourceUrl" target="_blank">{{ module.resourceUrl! | translate}}</a></span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.targetGroups?.length) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Target groups' | translate}}: </span>
                                                <span class="italic">
                                                    @for (group of module.targetGroups; track $index) {
                                                        {{ group | translate }}{{ $last ? '' : ', '}}
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.learningResourceType && module.learningResourceType !== $course()?.learningResourceType) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Learning Resource Type' | translate}}: </span>
                                                <span class="italic">{{ module.learningResourceType! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.prerequisites && module.prerequisites !== $course()?.prerequisites) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Prerequisites' | translate}}: </span>
                                                <span class="italic">{{ module.prerequisites! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                    @if (module.learningOutcome && module.learningOutcome !== $course()?.learningOutcome) {
                                        <div class="text-sm mt-3 px-1">
                                            <p class="text-[#767676] leading-tight">
                                                <span class="text-primary-light font-bold">{{'Learning Outcome' | translate}}: </span>
                                                <span class="italic">{{ module.learningOutcome! | translate}}</span>
                                            </p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>
