import { CommonConfig, common as commonCore, } from "src/index";

export const common: CustomCommonConfig = {
    ...commonCore,
    // Impostare qui gli override per i valori della configurazione comune tra sviluppo e produzione
    id: 'h2iosc',
    name: 'H2IOSC',
    instanceVersion: '1.2.0',
    defaultLanguage: 'it',
    multiLanguage: true,
    availableLanguages: ['en', 'it'],
    customLanguageResourcesPrefixes: ['/assets/i18n/'],

    logo: 'assets/images/Logo_H2IOSC_bianco_extended.png',
    enablePrivacyDisclaimer: true,
    hideFooter: false,
    footerText: `<span class="px-base xl:px-base-desk flex flex-col items-center justify-between gap-2"><span>H2IOSC Project - Humanities and cultural Heritage Italian Open Science Cloud <br/> funded by the European Union - NextGenerationEU - NRRP M4C2 - Project code IR0000029 - CUP B63C22000730005</span>
        <span class="flex flex-row gap-2 items-center justify-center"><img class="h-4" src="assets/images/GarrCloudLogo.png" title="Cloud Hosting Garr" alt="Garr Cloud Logo"/><span class="text-xs">Cloud Hosting Garr</span></span></span>`,

    hideChat: false,
    hideChatToggler: false,

    myCoursesModule: {
        hideAdvancedSearch: true,
        provideCertificate: true,
        certificateLogos: ['assets/images/Logo_H2IOSC_colori_extended.jpg', 'assets/images/logo-ilc.png', 'assets/images/logo_CLARIN-IT.png'],
        coursesPreviewConfig: {
            showImage: true,
            showDescription: true,
            showProgress: true,
        }
    },
    myGuidesModule: {
        hideAdvancedSearch: true,
    },
    globalSearchModule: {
        ...commonCore.globalSearchModule,
        searchInputPlaceholder: 'Search in H2IOSC',
        contentsPreviewConfig: {
            showImage: true,
            showDescription: true,
            showSkills: true,
            showContentTypeIcon: true,
        },
    },
    viewerModule: {
        ...commonCore.viewerModule,
        trackProgressType: 'manual',
        toolbar: {
            config: {
                ...commonCore.viewerModule?.toolbar?.config ?? {},
                activeToolId: 'index',
            },
        },
        liquidViewer: {
            ...commonCore.viewerModule?.liquidViewer,
            customCssPath: 'assets/liquidViewerCustomizations.css',
        },
    },

    registrationModule: {
        hideSkillsSetting: true,
        registrationType: 'simple',
        privacyDisclaimer: 'flag',
    },

    loginModule: {
        logoExternalLink: 'https://www.h2iosc.cnr.it/',
        logoExternalLinkTitle: 'Go to H2IOSC Project',
        pageTitle: 'Welcome to the H2IOSC training environment',
        pageSubtitle: 'Log in with your credentials or create an account',
        customMessage: 'Discover more about the platform',
        customMessagePosition: 'below-registration'
    },

    coursePreview: `${commonCore.baseContentUrlApi}/course/:uuidCourse`,
};

interface CustomCommonConfig extends CommonConfig {
    coursePreview: string;
}