import { ApplicationRef, ComponentRef, inject, Injectable, signal, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupComponent, PopupConfig, PopupModalConfig, PopupSubtype, ToastConfig } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class PopupService {
    private applicationRef = inject(ApplicationRef);
    private $activePopups = signal<ComponentRef<PopupComponent>[]>([]);

    // MODAL
    public openModal(bodyTemplateRef: TemplateRef<any>, options?: Partial<ModalOptions>) {
        const modalRef = this._open({ ...options || {}, type: 'modal' });
        modalRef.setInput('bodyTemplateRef', bodyTemplateRef);
        return modalRef;
    }

    public openComponentInModal(bodyComponent: Type<any>, inputs: Array<{ key: string, value: any }> = [], options?: Partial<ModalOptions>) {
        const modalRef = this._open({ ...options || {}, type: 'modal' });
        modalRef.setInput('bodyComponent', { component: bodyComponent, inputs });
        return modalRef;
    }

    // TOAST
    public info(message: string, config?: ToastConfig) {
        this._toast(message, 'info', config);
    }

    public success(message: string, config?: ToastConfig) {
        this._toast(message, 'success', config);
    }

    public warning(message: string, config?: ToastConfig) {
        this._toast(message, 'warning', config);
    }

    public error(message: string, config?: ToastConfig) {
        this._toast(message, 'error', config);
    }

    private _toast(message: string, subtype: PopupSubtype, config?: ToastConfig) {
        const toastRef = this._open({ type: 'toast', subtype, message, closable: config?.skipAutoclose ?? false });
        if (!config?.skipAutoclose) {
            setTimeout(() => {
                try {
                    this._removeActivePopup(toastRef);
                    toastRef.destroy();
                } catch (e) { }
            }, config?.timeBeforeAutoClose ?? 3000);
        }
        toastRef.instance.close.subscribe(() => this.fixToastsMarginTop())
        this.fixToastsMarginTop();
    }

    private fixToastsMarginTop() {
        const openedToasts = this.$activePopups().filter(pc => pc.instance.$type() === 'toast');
        openedToasts.map(t => t.instance.elRef.nativeElement as HTMLElement).forEach((t, i) => {
            const marginTop = openedToasts.slice(i, openedToasts.length - 1).reduce((top, t) => top += t.instance.elRef.nativeElement.clientHeight + 10, 0);
            t.style.marginTop = `${marginTop}px`;
        });
    }

    // ALERTS
    public alert(message: string, options?: Partial<AlertOptions>) {
        this._open({ ...options || {}, type: 'alert', message })
    }

    public alertError(message: string, options?: Partial<AlertOptions>) {
        this._open({
            ...options || {}, type: 'alert', subtype: 'error', message, labels: {
                confirm: 'Close',
                ...options?.labels || {},
            },
        });
    }

    public alertWarning(message: string, options?: Partial<AlertOptions>) {
        this._open({
            ...options || {}, type: 'alert', subtype: 'warning', message, labels: {
                confirm: 'Close',
                ...options?.labels || {},
            },
        });
    }

    public alertSuccess(message: string, options?: Partial<AlertOptions>) {
        this._open({
            ...options || {}, type: 'alert', subtype: 'success', message, labels: {
                confirm: 'Close',
                ...options?.labels || {},
            },
        });
    }

    public confirm(message: string, options?: Partial<AlertOptions>) {
        const confirmed$ = new Subject<boolean>();
        this._open({
            ...options || {},
            callbacks: {
                confirm: () => {
                    if (options?.callbacks?.confirm) {
                        options?.callbacks?.confirm();
                    }
                    confirmed$.next(true);
                    confirmed$.complete();
                },
                cancel: () => {
                    if (options?.callbacks?.cancel) {
                        options?.callbacks?.cancel();
                    }
                    confirmed$.next(false);
                    confirmed$.complete();
                },
            },
            type: 'alert',
            subtype: 'confirm',
            message,
        });
        return confirmed$;
    }

    // INTERNAL 
    private _open(options?: PopupConfig) {
        const rootViewContainerRef = this.applicationRef.components[0].injector.get(ViewContainerRef);
        const componentRef = rootViewContainerRef.createComponent(PopupComponent);
        componentRef.setInput('config', options);

        this.$activePopups.update(popups => [...popups, componentRef]);

        componentRef.instance.close.subscribe(() => {
            this._removeActivePopup(componentRef);
            componentRef.destroy();
        });

        return componentRef;
    }
    private _removeActivePopup(popupRef: ComponentRef<PopupComponent>) {
        this.$activePopups.update(popups => {
            const popupIndex = popups.indexOf(popupRef);
            if (popupIndex >= 0) {
                popups.splice(popupIndex, 1);
            }
            return popups;
        });
        this.fixToastsMarginTop();
    }


}

export interface AlertOptions extends Omit<PopupConfig, 'type' | 'modalConfig' | 'message' | 'toastConfig'> { }
export interface ConfirmOptions extends Omit<AlertOptions, 'callbacks'> { }
interface InternalModalConfig extends Omit<PopupModalConfig, 'body'> { }
export interface ModalOptions extends Omit<PopupConfig, 'type' | 'subtype' | 'message' | 'toastConfig' | 'alertConfig'> {
    modalConfig: Partial<InternalModalConfig>;
}