@if ($course()) {
    <meta-content-preview [contentType]="'course'" [cover]="$cover()" [title]="$title()"
        [duration]="$duration()" [description]="$description()" [skills]="$skills()"
        [banned]="$banned()" [progress]="$progress()" [students]="$students()"
        [showProgress]="$showProgress()" [lowerHeight]="$lowerHeight()" [showStudents]="$showStudents()"
        [showSkills]="$showSkills()" [showImage]="$showImage()" [showCompletedIcon]="$completed()"
        [showCertificateIcon]="$course()!.finalCertificate === true && $completedMandatory()"
        [showContentTypeIcon]="$showContentTypeIcon()" [showStarIcon]="$showStarIcon()" 
        [showDescription]="$showDescription()"
        (click)="doClick.emit($event)"
        (clickCertificate)="$event.stopPropagation(); showCertificate()" />
}