import { Component, ElementRef, EventEmitter, Output, ViewChild, computed, inject } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Annotation, AnnotationType } from 'src/index';
import { LoadingService, PopupService, ViewerAnnotationListService, ViewerIndexService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
    selector: 'meta-viewer-annotations-list',
    templateUrl: './viewer-annotations-list.component.html',
    styleUrls: ['./viewer-annotations-list.component.css']
})
export class ViewerAnnotationsListComponent extends Accessible {
    public loadingService = inject(LoadingService);
    public viewerIndexService = inject(ViewerIndexService);
    public viewerAnnotationListService = inject(ViewerAnnotationListService);
    private popupService = inject(PopupService);
    private translateService = inject(TranslateService);

    override handleKeyPress($event: KeyboardEvent) {
        if (!this.lockedByModal) {
            super.handleKeyPress($event);
        } else {
            $event.stopPropagation();
        }
    }

    @Output() doClose = new EventEmitter();
    @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

    mode: 'bookmarks' | 'workbook' = 'workbook';
    $mainId = computed(() => this.viewerIndexService.$mainId());
    secondaryMainId: string | undefined = this.viewerIndexService.getSecondaryMainId();
    contentId: string | undefined = this.viewerIndexService.$currentIndexItem()?.id;
    annotations$ = this.viewerAnnotationListService.annotations$.pipe(
        map(annotations => annotations.filter(annotation => annotation.type !== 'bookmark'))
    )

    lockedByModal = false;

    public async deleteAnnotation(annotation: Annotation, target?: HTMLElement) {
        this.lockedByModal = true;
        const deleteMsg = this.getDeleteMessageByAnnotationType(annotation);
        const deleteMsgTranslated = await firstValueFrom(this.translateService.get(deleteMsg, { value: annotation.title }));
        this.popupService.confirm(`<span>${deleteMsgTranslated}</span>`, {
            size: 'medium',
            callbacks: {
                cancel: () => {
                    this.lockedByModal = false;
                    if (target) {
                        target.focus();
                    }
                },
                confirm: () => {
                    this.loadingService.show();
                    firstValueFrom(
                        this.viewerAnnotationListService.deleteAnnotation(annotation.id)
                    ).finally(() => {
                        this.lockedByModal = false;
                        this.closeBtn?.nativeElement.focus();
                        this.currentVerticalIndex = this.verticalNavEls.indexOf(this.closeBtn?.nativeElement);
                        this.loadingService.hide();
                    });
                }
            }
        });
    }

    getDeleteMessageByAnnotationType(annotation: Annotation): string {
        const startText = "Are you sure you want to remove"
        switch (annotation.type) {
            case 'bookmark':
                return `${startText} the bookmark?`;
            case 'highlight':
                return `${startText} the highlight?`;
            case 'link':
                return `${startText} the link?`;
            case 'clipping':
                return `${startText} the clipping?`;
            case 'note':
                return `${startText} the note?`;
        }
    }

    getAnnotationIconByType(type: AnnotationType): string {
        switch (type) {
            case 'bookmark':
                return 'star-fill';
            case 'highlight':
                return 'highlight';
            case 'link':
                return 'link';
            case 'clipping':
                return 'crop';
            case 'note':
                return 'paragraph';
        }
    }

    public openAnnotation(annotation: Annotation) {
        this.viewerAnnotationListService.openedAnnotation$.next(annotation);
        this.onClose();
    }

    onClose(): void {
        this.doClose.emit();
    }
}