import { Component, computed, inject, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { environment, HandleStringObjectPipe, OptionItem, RegistrationStepField, User, UserData } from 'src/index';
import { AuthenticationService, ConfigurationService, LoadingService, PopupService, UserService, Validation } from 'src/index/services.index';

@Component({
    selector: 'meta-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent {
    popupService = inject(PopupService);
    configurationService = inject(ConfigurationService);
    currentRoute = inject(ActivatedRoute);
    authenticationService = inject(AuthenticationService<User>);
    formBuilder = inject(FormBuilder);
    loadingService = inject(LoadingService);
    userService = inject(UserService);

    config = {
        ...environment.userProfileModule,
        registrationType: environment.registrationModule?.registrationType || 'simple',
        hideAdditionalFields: environment.registrationModule?.hideAdditionalFields,
    };
    profilePath = this.configurationService.appSections.profile.routerLink;

    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    submitted = false;
    submittedPwd = false;

    minPwdLength = environment.minPwdLength;
    maxPwdLength = environment.maxPwdLength;

    formPwd: FormGroup = this.formBuilder.group(
        {
            currentPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(this.minPwdLength), Validators.maxLength(this.maxPwdLength)]],
            confirmPassword: ['', [Validators.required]],
        },
        {
            validators: [Validation.match('newPassword', 'confirmPassword')],
        }
    );

    registrationFields: RegistrationStepFieldSignal[][] = this.configurationService.registrationStepFields
        .map(stepFields => stepFields.filter(field => !field.excludeFromProfile).map(
            stepField => {
                const stepFieldSignal = stepField as RegistrationStepFieldSignal;
                if (stepFieldSignal.options) {
                    if (Array.isArray(stepFieldSignal.options)) {
                        stepFieldSignal.$options = Array.isArray(stepFieldSignal.options) ? signal(stepFieldSignal.options) : toSignal(stepFieldSignal.options);
                    }
                }
                return stepFieldSignal;
            }
        )).filter(fields => fields.length > 0);

    fields = this.registrationFields.reduce((x, y, i) => {
        if (i === 0) {
            return [...x, ...y];
        }
        if (this.config.registrationType === 'multipleSteps' && !this.config.hideAdditionalFields) {
            return [...x, ...y];
        }
        return x;
    }, []).filter(el => el.inputType !== 'password');

    form = computed<FormGroup>(() => {
        const formSteps = this.fields.reduce((controls, field) => {
            if (field.inputType === 'checkbox' && field.$options && field.$options()!) {
                return {
                    ...controls,
                    [field.id]: this.formBuilder.array(field.$options()!.map(() => new FormControl(false)), field.validators || []),
                };
            }
            return {
                ...controls,
                [field.id]: [field.default || '', field.validators || []],
            }
        }, {} as any);
        const form = this.formBuilder.group(formSteps);
        const user = this.$currentUser();
        if (user) {
            const formPatches: any = {};
            this.fields.forEach(field => {
                if (user) {
                    if (field.inputType === 'checkbox' && field.$options && field.$options()!) {
                        formPatches[field.id] = field.$options()?.map(opt => {
                            const optValue = HandleStringObjectPipe.prototype.transform(opt, 'value');
                            let checked = false;
                            if (field.additionalData && user.data && user.data[field.id]) {
                                checked = user.data[field.id].indexOf(optValue) >= 0;
                            } else if ((user as any)[field.id]) {
                                checked = (user as any)[field.id].indexOf(optValue) >= 0;
                            }
                            return checked;
                        });
                    } else {
                        const value = field.additionalData && user.data && user.data[field.id] ? user.data[field.id] : (user as any)[field.id];
                        formPatches[field.id] = value;
                    }
                }
            });
            form.patchValue(formPatches);
        }
        return form;
    });
    $currentUser = this.authenticationService.$currentUser;
    $currentUserData = computed<UserData>(() => {
        const user = this.$currentUser();
        return { ...user?.data };
    });

    $errorMsg = signal('');
    $errorPwdMsg = signal('');

    $fControls = computed<{ [key: string]: AbstractControl }>(() => this.form().controls);
    
    get fPwd(): { [key: string]: AbstractControl } {
        return this.formPwd.controls;
    }

    editPassword() {
        this.submittedPwd = true;

        if (this.formPwd.invalid) {
            return;
        }
        this.loadingService.show();
        firstValueFrom(this.userService.changePassword(this.formPwd.value.currentPassword, this.formPwd.value.newPassword))
            .then(res => {
                if (res) {
                    this.popupService.success('Password modificata');
                } else {
                    this.$errorPwdMsg.set('An error occurred while changing your password');
                }
            })
            .catch((err) => this.$errorPwdMsg.set(err.message || 'An error occurred while changing your password'))
            .finally(() => this.loadingService.hide());
    }

    getFieldAnswer(field: RegistrationStepFieldSignal) {
        const form = this.form();
        if (form) {
            if (field.inputType === 'checkbox' && field.$options) {
                return field.$options()?.map((opt, i) => ({
                    opt,
                    checked: form.value[field.id][i],
                })).filter(opt => opt.checked).map(({ opt }) => HandleStringObjectPipe.prototype.transform(opt, 'value'));
            }
            return form.value[field.id];
        }
        return undefined;
    }

    saveChanges() {
        this.submitted = true;

        if (this.form().invalid) {
            return;
        }
        this.loadingService.show();
        const dataToSave: Partial<User> = {
            data: { ...this.$currentUserData() || {} },
        };
        this.fields.forEach(field => {
            if (field.editable) {
                const fieldValue = this.getFieldAnswer(field);
                if (!field.additionalData && fieldValue) {
                    (dataToSave as any)[field.id] = fieldValue;
                }
                if (field.additionalData) {
                    if (fieldValue) {
                        dataToSave.data = {
                            ...dataToSave.data,
                            [field.id]: fieldValue
                        }
                    } else if (dataToSave.data && !fieldValue) {
                        delete dataToSave.data[field.id];
                    }
                }
            }
        });
        firstValueFrom(this.userService.updatePersonalData(dataToSave))
            .catch((err) => this.$errorMsg.set(err.message || 'An error occurred while saving the data'))
            .then((res) => {
                if (res) {
                    this.popupService.success('Data successfully updated!');
                } else {
                    this.$errorMsg.set('An error occurred while saving the data');
                }
            })
            .finally(() => this.loadingService.hide());;
    }

    patchFormValue(fieldId: string, value: any) {
        this.form().patchValue({ [fieldId]: value });
    }
}


interface RegistrationStepFieldSignal extends RegistrationStepField {
    $options?: Signal<OptionItem[] | undefined>;
}