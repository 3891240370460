import { Injectable } from '@angular/core';

import { faker } from '@faker-js/faker';

import {
  ApiUrlKeys, Guide, Course, PaginatedResults, SearchWithFiltersPayload, User, SkillsGroupId, SkillsGroup,
  SkillLevel, Skill, BotSearchResult, FiltersSearch, Content, StudyPathItem, BotSearchPayload,
  SelfAssessmentSet,
  CourseSubscriptionStatus,
} from 'src/index';
import { capitalize, enumerate } from './utils';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {

  REQ: { [T in ApiUrlKeys]: (...args: any) => any } = {
    // User
    checklogin: () => {
      const user = this.getUser();
      window.localStorage['user'] = user;
      return { user, access_token: faker.datatype.uuid() };
    },
    login: () => {
      const user = this.getUser();
      window.localStorage['user'] = user;
      return { user, access_token: faker.datatype.uuid() };
    },
    logout: () => {
      window.localStorage['user'] = undefined;
      return { success: true };
    },
    registration: (user: User) => {
      return user;
    },
    // Dashboard
    coursesInEvidence: this.getCourses.bind(this),
    coursesInProgess: this.getStudyPathCourses.bind(this),
    coursesStudyPath: this.getStudyPathCourses.bind(this),
    // Courses
    activateCourse: (course: Course, activationCode: string) => ({ activated: true }),
    courses: this.getCourses.bind(this),
    courseModuleContent: (id: string) => this.getCourseDetail(id),
    courseDetail: (id: string) => this.getCourseDetail(id),
    startCourse: (course: Course) => ({ started: true }),
    // Guides
    guides: this.getGuides.bind(this),
    courseRelatedGuides: (courseId: string) => this.getGuides(),
    search: this.search.bind(this),
    botSearch: this.botSearch.bind(this),
    changePassword: () => ({ changed: true }),
    changePasswordWT: () => ({ changed: true }),
    resetPassword: () => ({ reset: true }),
    saveUserData: (reqData) => this.getUser(),
    userSkillsGroups: this.getUserSkillsGroups.bind(this),
    userSkillsList: this.getUserSkillsList.bind(this),
    suggestedCourses: this.getCourses.bind(this),
    userSuggested: this.getCourses.bind(this),
    trackProgress: (reqData) => this.rand(100, 1),
    // Viewer
    getPublication: (pubId) => this.getPublication(pubId),
    getContent: (contentId) => this.getContent(contentId),
    viewerSearch: () => undefined,
    //loadAnnotationsCounts: (pubId, type) => this.getAnnotation(pubId, type),
    //loadAnnotationsByVolume: (pubId, type) => this.getAnnotation(pubId, type),
    getAnnotationsByPublicationIdContentId: (pubId, type) => this.getAnnotation(pubId, type),
    getAnnotationsByPublicationId: (pubId, type) => this.getAnnotation(pubId, type),
    //loadAnnotationsByType: (pubId, type) => this.getAnnotation(pubId, type),
    deleteAnnotationById: (pubId, type) => this.getAnnotation(pubId, type),
    saveAnnotation: (pubId, type) => this.getAnnotation(pubId, type),
    updateAnnotationById: (pubId, type) => this.getAnnotation(pubId, type),
    //syncAnnotations: (pubId, type) => this.getAnnotation(pubId, type),
    annotation: (pubId) => this.bookmark(pubId),

    content: (pubId, searchValue) => this.publicationSearch(pubId, searchValue),
    saveExerciseExecution: function (...args: any) {
      throw new Error('Function not implemented.');
    },
    getSelfAssessmentSets: () => this.getSelfAssessmentSets(),
    getSelfAssessmentSet: () => this.getSelfAssessmentSet(),
    saveExecution: function (...args: any) {
      throw new Error('Function not implemented.');
    },
    getExerciseExecution: function (...args: any) {
      throw new Error('Function not implemented.');
    },
    deleteExecution: function (...args: any) {
      throw new Error('Function not implemented.');
    },
    getPublicCourse: (id: string) => this.getCourseDetail(id),
  }

  private rand = faker.mersenne.rand;

  getFilters() {
    const keys = ['cat', 'dog', 'fish', 'snake', 'bear'];
    return keys.reduce((x, k) => ({
      ...x,
      [k]: enumerate(this.rand(10, 1)).map((_, i) => {
        switch (k) {
          case 'cat':
            return { label: faker.animal.cat(), num: this.rand(10, 1) };
          case 'dog':
            return { label: faker.animal.dog(), num: this.rand(10, 1) };
          case 'fish':
            return { label: faker.animal.fish(), num: this.rand(10, 1) };
          case 'snake':
            return { label: faker.animal.snake(), num: this.rand(10, 1) };
          case 'bear':
            return { label: faker.animal.bear(), num: this.rand(10, 1) };
          default:
            return { label: faker.word.noun(), num: this.rand(10, 1) };
        }
      }),
    }), {} as FiltersSearch);
  }

  getFilterLabel(k: string) {
    switch (k) {
      case 'cat':
        return 'Gatto';
      case 'dog':
        return 'Cane';
      case 'fish':
        return 'Pesce';
      case 'snake':
        return 'Serpente';
      case 'bear':
        return 'Orso';
      default:
        return capitalize(k);
    }
  }
  getPaginatedResults<T>(results: T[], reqData?: Partial<SearchWithFiltersPayload>): PaginatedResults<T> {
    const filters: FiltersSearch = this.getFilters();
    reqData?.filters?.forEach(f => {
      const filterKey = Object.keys(f)[0];
      filters[filterKey] = [...[{ label: f[filterKey] as any, num: this.rand(10, 1) }], ...filters[filterKey] || []];
    });

    return {
      page: 1,
      pages: results.length > 0 ? this.rand(10, 1) : 1,
      numFound: results.length,
      results,
      filtersApplied: {
        filters: reqData?.filters || [],
        search: reqData?.search || '',
        settings: reqData?.settings || {},
      },
      filters,
      filtersLabels: reqData?.withLabels ? Object.keys(filters).reduce((x, k) => ({
        ...x,
        [k]: this.getFilterLabel(k),
      }), {}) : undefined,
    };
  }

  getUser(): User {
    return {
      id: faker.datatype.uuid(),
      username: faker.internet.userName(),
      email: faker.internet.email(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      tokenId: faker.datatype.string(),
      data: {
        guides: enumerate(this.rand(5, 0)).map(() => faker.datatype.uuid()),
      },
    }
  }

  private getImage(width?: number, height?: number) {
    const categories = ['animals', 'business', 'technics', 'transport', 'fashion', 'city', 'food', 'sports'];
    return faker.image.imageUrl(width || 640, height || 640, categories[this.rand(categories.length - 1, 0)], true);
  }

  getCourse(): Course {
    const progress = this.rand(99, 0);
    return {
      uuid: faker.datatype.uuid(),
      image: this.getImage(),
      title: capitalize(faker.random.words()),
      duration: `${this.rand(4, 1)}h`,
      description: faker.lorem.paragraph(),
      skills: enumerate(this.rand(5, 1)).map(() => faker.word.noun()),
      progress,
      mandatoryProgress: this.rand(progress, 0),
      students: this.rand(100, 1),
      type: 'course',
      toBuy: faker.datatype.boolean(),
    };
  }
  getCourses(): Course[] {
    return enumerate(this.rand(10, 1)).map((_, i) => this.getCourse());
  }

  getStudyPathCourses(): StudyPathItem[] {
    const courseInfo = this.getCourse();
    return enumerate(this.rand(10, 1)).map((_, i) => ({
      id: faker.datatype.number(),
      activatedDate: courseInfo.toBuy ? faker.date.past().toDateString() : '',
      courseInfo,
      createdAt: faker.date.past().toDateString(),
      progress: courseInfo.progress || 0,
      mandatoryProgress: courseInfo.mandatoryProgress || 0,
      startedDate: faker.date.past().toDateString(),
      status: courseInfo.progress ? CourseSubscriptionStatus.Progress : CourseSubscriptionStatus.Started,
      uuidCourse: courseInfo.uuid,
      uuidUser: faker.datatype.uuid(),
    }));
  }

  getCourseDetail(id: string): Course {
    return {
      uuid: id,
      image: this.getImage(),
      title: capitalize(faker.random.words()),
      duration: `${this.rand(4, 1)}h`,
      description: faker.lorem.paragraph(),
      skills: enumerate(this.rand(5, 1)).map(() => faker.word.noun()),
      skillsNeeded: enumerate(this.rand(5, 1)).map(() => faker.word.noun()),
      modules: enumerate(this.rand(10, 1)).map((_, i) => ({
        id: faker.datatype.uuid(),
        title: capitalize(faker.random.words()),
        description: faker.lorem.paragraph(),
        cover: this.getImage(),
        index: []
      })),
      progress: this.rand(99, 0),
      students: this.rand(100, 1),
    }
  }

  getGuides(): Guide[] {
    return enumerate(this.rand(10, 1)).map((_, i) => ({
      uuid: faker.datatype.uuid(),
      image: this.getImage(),
      title: capitalize(faker.random.words()),
      description: faker.lorem.paragraph(),
      type: 'guide',
      startId: faker.datatype.uuid(),
    }));
  }

  search(reqData: Partial<SearchWithFiltersPayload>) {
    let results: Content[] = [];
    if (reqData.settings) {
      if (reqData.settings['type']?.indexOf('course') >= 0) {
        results = [...results, ...this.getCourses()];
      }
      if (reqData.settings['type']?.indexOf('guide') >= 0) {
        results = [...results, ...this.getGuides()];
      }
    }
    return this.getPaginatedResults(results, reqData);
  }

  botSearch(payload: BotSearchPayload): BotSearchResult {
    let { search, chatHistory } = payload;
    const res = this.rand(1, 0) > 0;
    return {
      search,
      chatHistory,
      response: [
        ...enumerate(this.rand(3, 0)).map((_, i) => ({
          text: capitalize(faker.random.words()),
          link: faker.datatype.boolean() ? faker.internet.url() : undefined,
        })),
        ...this.getCourses(),
        ...enumerate(this.rand(3, 0)).map((_, i) => ({
          text: capitalize(faker.random.words()),
          link: faker.datatype.boolean() ? faker.internet.url() : undefined,
        })),
        ...this.getGuides(),
        ...enumerate(this.rand(3, 0)).map((_, i) => ({
          text: capitalize(faker.random.words()),
          link: faker.datatype.boolean() ? faker.internet.url() : undefined,
        })),
      ],
    };
  }

  getUserSkillsLevels(): SkillLevel[] {
    return [
      { id: 'none', label: 'Nessuna' },
      { id: 'base', label: 'Base' },
      { id: 'medium', label: 'Medio' },
      { id: 'experienced', label: 'Esperto' },
      { id: 'very-experienced', label: 'Molto esperto' },
    ];
  }

  getUserSkillsGroups(): SkillsGroup[] {
    return [
      { id: 'technical', label: 'Competenze tecniche' },
      { id: 'it', label: 'Competenze informatiche' },
      { id: 'linguistic', label: 'Competenze linguistiche' },
      { id: 'software', label: 'Competenze software' },
    ];
  }

  getUserSkillsList(skillsGroup: SkillsGroupId): { label?: string; levels: SkillLevel[], skills: Skill[] } {
    switch (skillsGroup) {
      case 'technical':
        return { label: 'Competenze tecniche', levels: this.getUserSkillsLevels(), skills: [{ id: 'technical1', label: 'Competenza tecnica 1' }, { id: 'technical2', label: 'Competenza tecnica 2' }] };
      case 'it':
        return { label: 'Competenze informatiche', levels: this.getUserSkillsLevels(), skills: [{ id: 'it1', label: 'Competenza IT 1' }, { id: 'it2', label: 'Competenza IT 2' }] };
      case 'linguistic':
        return { label: 'Competenze linguistiche', levels: this.getUserSkillsLevels(), skills: [{ id: 'linguistic1', label: 'Competenza linguistica 1' }, { id: 'linguistic2', label: 'Competenza Linguistica 2' }] };
      case 'software':
        return { label: 'Competenze software', levels: this.getUserSkillsLevels(), skills: [{ id: 'software1', label: 'Competenza software 1' }, { id: 'software2', label: 'Competenza software 2' }] };
      default:
        return { levels: this.getUserSkillsLevels(), skills: [] };
    }
  }

  getAnnotation(pubId: string, type: string) {
    return [{
      "data": {
        "quote": "Pagina",
        "ranges": [
          {
            "start": "/meta-runtime-component[1]/section[1]/section[1]/div[1]/h3[1]/span[1]",
            "startOffset": 1,
            "end": "/meta-runtime-component[1]/section[1]/section[1]/div[1]/h3[1]/span[1]",
            "endOffset": 7
          }
        ],
        "color": "hl-light-blue"
      },
      "uuid": 108,
      "userId": "6d622650-c0ab-47fa-ab18-ac48d1002393",
      "volumeId": "ya9Wy0Eb8k",
      "moduleId": null,
      "contentId": "rKLWQAMWyN",
      "type": "highlight",
      "title": "Pagina",
      "canDelete": true,
      "canEdit": true,
      "sign": "2023-06-13T12:23:54.000Z",
      "publisher": null,
      "createdAt": "2023-06-13T12:23:54.000Z",
      "updatedAt": "2023-06-13T12:23:54.000Z"
    }]
  }

  getPublication(id: string) {
    return MOCKED_PUBLICATIONS;
    // const cover = this.getImage();
    // return {
    //   id,
    //   startId: faker.datatype.uuid(),
    //   // TODO: Rivedere generazione indice fake
    //   index: enumerate(this.rand(10, 1)).map(() => {
    //     const types = ['chapter', 'page', 'paragraph'];
    //     const type = types[this.rand(types.length - 1, 0)];
    //     return {
    //       id: faker.datatype.uuid(),
    //       title: faker.random.words(),
    //       type,
    //       depth: types.indexOf(type) + 1,
    //       pages: type === 'chapter' ? enumerate(this.rand(10, 1)).map(() => faker.datatype.uuid()) : undefined,
    //       children: type === 'chapter' ? enumerate(this.rand(10, 1)).map(() => faker.datatype.uuid()) : undefined,
    //       page: type === 'paragraph' ? faker.datatype.uuid() : undefined,
    //       icon: "default",
    //       hideNode: type !== 'chapter' ? faker.datatype.boolean() : false,
    //       contents: type === 'paragraph' ? enumerate(this.rand(10, 0)).map(() => this.getMedia()) : undefined,
    //     }
    //   }),
    //   title: faker.random.words(),
    //   styles: "",
    //   author: faker.name.fullName(),
    //   coverSmall: cover,
    //   coverBig: cover,
    //   language: 'it',
    //   isbn: ['9788823881709'],
    //   type: 'publication',
    // };
  }

  getMedia() {
    // TODO: Rivedere generazione media
    return {
      id: faker.datatype.uuid(),
      view: 'attachment',
      title: faker.lorem.sentence(),
      type: 'image',
      icon: 'icon-paperclip',
      value: this.getImage(),
      originalId: faker.datatype.uuid(),
      originalFileName: `${faker.word.noun().toLowerCase()}.jpg`
    };
  }

  getContent(id: string) {
    return {
      id,
      contents: {
        [faker.datatype.uuid()]: this.getMedia()
      },
      template: `<section class="main-container">\n\n\n<section class="section-container">${enumerate(this.rand(10, 1)).map(() => `<p>${faker.lorem.paragraphs()}</p>`).join('')}</section></section>`,
      styles: '',
      title: faker.random.words(),
      /*relations: {
        [parId: string]: RelationData[];
      };*/
      customCss: 'ul li:before {\n background-color: #1761a8;\n}\n\n.section-main-title .main-title-number {\n color: #1761a8;\n}\n.section-container .container-title-num {\n color: #1761a8;\n}\n.section-container .container-title:before {\n background-color: #1761a8;\n}\n.section-container figure .image-number {\n color: #1761a8;\n}\n\n.box-default .box-number {\n color: #1761a8;\n}\n.box1 .box-number,\n.box2 .box-number {\n color: #1761a8;\n}\n.box1 .box-content,\n.box2 .box-content {\n border-color: #1761a8;\n}\n\n.text-emph,\n.main-container .viewer-icon-notepad1,\n.table-num {\n  color: #1761a8;\n}\n\n.box h3 {\nfont-size: 1rem;\ncolor: #1761a8;\n}\n\n.type-internalLink,\n.type-internalLink *,\na[data-type=\"internalLink\"] {\n color: #1761a8!important;\nfont-weight: 600;\n}',
    };
  }

  bookmark(pubId: string) {
    return [{
      canDelete: true,
      canEdit: true,
      contentId: "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
      createdAt: "2023-06-23T10:01:33.000Z",
      data: null,
      sign: "2023-06-23T10:01:33.000Z",
      title: "La Tempesta in pittura ('700 e '800)",
      type: "bookmark",
      updatedAt: "2023-06-23T10:01:33.000Z",
      userId: "118",
      uuid: 81,
      volumeId: "133f9aab-a7e4-4325-bb21-f2ca6d24c03c"
    }]
  }

  publicationSearch(pubId: string, searchValue: string) {
    return [
      {
        "id": "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
        "texts": [
          "-cittadino: in Hogarth\u00a0 l'azione pedagogica \u00e8 intreccio\/intrico fra <span class=\"hl\">natura<\/span> e cultura, individuo- collettivit\u00e0",
          "-cittadino: in Hogarth\u00a0 l'azione <span class=\"hl\">pedagogica<\/span> \u00e8 intreccio\/intrico fra natura e cultura, individuo- collettivit\u00e0",
          "-cittadino: in Hogarth\u00a0 l'azione pedagogica \u00e8 intreccio\/intrico fra <span class=\"hl\">natura<\/span> e cultura, individuo- collettivit\u00e0"
        ]
      }
    ];
  }

  getSelfAssessmentSets(): Partial<SelfAssessmentSet>[] {
    return [
      {
        uuid: "dd679292-7656-11ef-812f-0242ac120005",
        title: "DATA MANAGEMENT",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem sequi temporibus quo maiores voluptatem fuga quaerat eius, minima ducimus rerum odit ratione explicabo quibusdam similique nemo debitis, nostrum dolorem ea laudantium corrupti! Nemo laboriosam expedita maiores ex soluta? Porro culpa nulla at fugiat magnam nihil optio odio obcaecati, quidem iste.",
        optional: true,
        completed: false,
        questions: [],
        profiles: [],
        execution: undefined
      }
    ];
  }

  getSelfAssessmentSet(): SelfAssessmentSet {
    return {
      "uuid": "dd679292-7656-11ef-812f-0242ac120005",
      "title": "DATA MANAGEMENT",
      "description": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem sequi temporibus quo maiores voluptatem fuga quaerat eius, minima ducimus rerum odit ratione explicabo quibusdam similique nemo debitis, nostrum dolorem ea laudantium corrupti! Nemo laboriosam expedita maiores ex soluta? Porro culpa nulla at fugiat magnam nihil optio odio obcaecati, quidem iste.",
      "optional": true,
      "questions": [
        {
          "uuid": "060e7441-7657-11ef-812f-0242ac120005",
          "text": "Usate i dati per finalità strategiche o operative (es. scelta di canali e contenuti di marketing e comunicazione, modifica o validazione dell'offerta culturale, revisione dei servizi offerti)?",
          "answerType": "single",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005",
          "answers": [
            {
              "uuid": "12d62fb7-7657-11ef-812f-0242ac120005",
              "text": "Si",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005"
            },
            {
              "uuid": "12d6390e-7657-11ef-812f-0242ac120005",
              "text": "No",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005"
            }
          ],
          "flow": [
            {
              "uuid": "d593ff7a-7684-11ef-812f-0242ac120005",
              "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005",
              "uuidNextQuestion": "e5c15c6d-7656-11ef-812f-0242ac120005"
            },
            {
              "uuid": "d594079c-7684-11ef-812f-0242ac120005",
              "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005",
              "uuidNextQuestion": "f2b279ad-7656-11ef-812f-0242ac120005"
            }
          ]
        },
        {
          "uuid": "e5c15c6d-7656-11ef-812f-0242ac120005",
          "text": "Raccogliete e analizzate dati sugli utenti raccolti direttamente o forniti da terzi (es. di contatto, sul grado di soddisfazione, sugli acquisti fatti)?",
          "answerType": "single",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005",
          "previousQuestions": [
            {
              "uuid": "d593ff7a-7684-11ef-812f-0242ac120005",
              "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005",
              "uuidNextQuestion": "e5c15c6d-7656-11ef-812f-0242ac120005"
            }
          ],
          "answers": [
            {
              "uuid": "1a5cdafd-7657-11ef-812f-0242ac120005",
              "text": "Si",
              "uuidQuestion": "e5c15c6d-7656-11ef-812f-0242ac120005"
            },
            {
              "uuid": "1a5ce767-7657-11ef-812f-0242ac120005",
              "text": "No",
              "uuidQuestion": "e5c15c6d-7656-11ef-812f-0242ac120005"
            }
          ]
        },
        {
          "uuid": "f2b279ad-7656-11ef-812f-0242ac120005",
          "text": "Avete sistemi di Business Intelligence o Customer Relationship Management per gestione e analisi dei dati sui pubblici?",
          "answerType": "single",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005",
          "previousQuestions": [
            {
              "uuid": "d594079c-7684-11ef-812f-0242ac120005",
              "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005",
              "uuidQuestion": "060e7441-7657-11ef-812f-0242ac120005",
              "uuidNextQuestion": "f2b279ad-7656-11ef-812f-0242ac120005"
            }
          ],
          "answers": [
            {
              "uuid": "344ce3db-7657-11ef-812f-0242ac120005",
              "text": "Si",
              "uuidQuestion": "f2b279ad-7656-11ef-812f-0242ac120005"
            },
            {
              "uuid": "344cf28b-7657-11ef-812f-0242ac120005",
              "text": "No",
              "uuidQuestion": "f2b279ad-7656-11ef-812f-0242ac120005"
            }
          ]
        }
      ],
      "profiles": [
        {
          "uuid": "1dd593df-771a-11ef-b4ed-0242ac120005",
          "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005#1a5cdafd-7657-11ef-812f-0242ac120005#344ce3db-7657-11ef-812f-0242ac120005",
          "level": "Alta",
          "text": "Con riferimento alla maturità nella raccolta, gestione e analisi dei dati il livello di maturità è alto. Si è compreso che la raccolta di dati deve essere opportunamente accompagnata dall'uso di strumenti che ne supportino la gestione e l'analisi e che tali strumenti devono essere utilizzati per perseguire degli obiettivi specifici.\r\nPer approfondire ulteriormente il tema della data strategy è possibile seguire i seguenti moduli:",
          "uuidsCourses": "",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005"
        },
        {
          "uuid": "1dd59e00-771a-11ef-b4ed-0242ac120005",
          "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005#1a5ce767-7657-11ef-812f-0242ac120005#344ce3db-7657-11ef-812f-0242ac120005",
          "level": "Media",
          "text": "Con riferimento alla maturità nella raccolta, gestione e analisi dei dati il livello di maturità è medio.\r\nL'organizzazione ha compreso l'importanza della raccolta dei dati, ma è utile considerare che l'uso di strumenti come i software di Business Intelligence e di Customer Relationship Management può notevolmente agevolare il perseguimento delle finalità che sono state prefissate.\r\nQuesti strumenti, oltre a facilitare la gestione dei dati, consentono di visualizzare le informazioni desiderate all'interno di dashboard, facilitando così un processo decisionale informato e non basato su supposizioni.\r\nLa sostenibilità dell'adozione di tali strumenti va chiaramente valutata anche in base alle specifiche esigenze e dimensioni dell'istituzione culturale e accompagnata da un investimento sulle risorse umane in grado di valorizzare gli strumenti.\r\nPer approfondire il tema della data strategy suggeriamo di seguire i seguenti moduli:",
          "uuidsCourses": "",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005"
        },
        {
          "uuid": "49af7ae9-771a-11ef-b4ed-0242ac120005",
          "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005#1a5cdafd-7657-11ef-812f-0242ac120005#344cf28b-7657-11ef-812f-0242ac120005",
          "level": "Media",
          "text": "Con riferimento alla maturità nella raccolta, gestione e analisi dei dati il livello di maturità è medio.\r\nÈ importante considerare che l'uso di strumenti come i software di Business Intelligence e di Customer Relationship Management, combinato alla definizione di specifiche finalità strategiche o operative può rivelarsi di grande utilità per un'ottimizzazione del processo di gestione e analisi dei dati.\r\nPer approfondire il tema della data strategy suggeriamo di seguire i seguenti moduli:",
          "uuidsCourses": "",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005"
        },
        {
          "uuid": "49af8383-771a-11ef-b4ed-0242ac120005",
          "uuidsAnswers": "12d62fb7-7657-11ef-812f-0242ac120005#1a5ce767-7657-11ef-812f-0242ac120005#344cf28b-7657-11ef-812f-0242ac120005",
          "level": "Bassa",
          "text": "Con riferimento alla maturità nella raccolta, gestione e analisi dei dati il livello di maturità è basso.\r\nÈ importante ricordare che per prendere delle decisioni strategiche efficaci i dati possono rivelarsi estremamente utili.\r\nIl primo passo è quello di dotarsi di strumenti che consentano di raccoglierli, ma la raccolta dati, senza che questi vengano utilizzati in alcun modo, è certamente poco utile.\r\nEsistono dei software, come quelli di Business Intelligence, che possono supportare la gestione, elaborazione e analisi dei dati e che possono supportarne l'utilizzo per alcune finalità specifiche, anche strategiche per l'organizzazione.\r\nTali strumenti consentono spesso di estrapolare dai dati il vero valore (le informazioni) grazie a delle dashboard che sintetizzano le analisi e le restituiscono in modo snello.\r\nTuttavia, a prescindere dall'uso di questi software più avanzati o di strumenti più semplici (come, ad esempio, un file Excel) è importante sviluppare o acquisire le competenze necessarie a porsi le giuste domande e utilizzare i dati per ottenere delle risposte effettivamente utili.\r\nPer approfondire il tema della data strategy suggeriamo di seguire i seguenti moduli:",
          "uuidsCourses": "",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005"
        },
        {
          "uuid": "d16e03fe-7685-11ef-812f-0242ac120005",
          "uuidsAnswers": "12d6390e-7657-11ef-812f-0242ac120005",
          "level": "Bassa",
          "text": "Con riferimento alla maturità nella raccolta, gestione e analisi dei dati il livello di maturità è basso.\r\nÈ importante ricordare che per prendere delle decisioni strategiche efficaci i dati possono rivelarsi estremamente utili e il primo passo è quello di dotarsi di strumenti che consentano di raccoglierli.\r\nEsistono dei software che possono supportare la gestione, l'elaborazione e l'utilizzo dei dati per finalità specifiche, anche strategiche per l'organizzazione. Diversi fornitori offrono inoltre delle dashboard che sintetizzano le analisi e restituiscono suggerimenti di possibili azioni da svolgere. Tuttavia, a prescindere dall'uso di questi software più avanzati o di strumenti più semplici (come, ad esempio, un file Excel) è importante sviluppare o acquisire le competenze necessarie a porsi le giuste domande e utilizzare i dati per ottenere risposte effettivamente utili.\r\nPer approfondire il tema della data strategy suggeriamo di seguire i seguenti moduli:",
          "uuidsCourses": "",
          "uuidSet": "dd679292-7656-11ef-812f-0242ac120005"
        }
      ]
    };
  }
}

const MOCKED_PUBLICATIONS = {
  "id": "133f9aab-a7e4-4325-bb21-f2ca6d24c03c",
  "title": "La Tempesta e l\u2019arte figurativa ",
  "styles": "http:\/\/dev-sc-studio.gruppometa.it\/api\/content\/133f9aab-a7e4-4325-bb21-f2ca6d24c03c\/styles\/styles.css",
  "index": [
    {
      "id": "133f9aab-a7e4-4325-bb21-f2ca6d24c03c",
      "title": "La Tempesta e l\u2019arte figurativa ",
      "depth": 1,
      "type": "chapter",
      "pages": [
        "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
        "6788be27-8011-4283-8960-8dafaf1e249e",
        "1a42955f-6f7c-4e05-87ea-e62a388f133e",
        "cb1994e4-3fb7-4c7d-a860-e531efc1f457",
        "13c6c817-066d-4717-9df1-b44611091814",
        "4a104c10-d80f-4612-9024-02225b80102f",
        "300e1644-4aa1-4bbc-85a9-8a82dfaf5fdf",
        "bd2e9938-a6ed-4394-90c3-76614705a0b1"
      ],
      "children": [
        "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
        "6788be27-8011-4283-8960-8dafaf1e249e",
        "1a42955f-6f7c-4e05-87ea-e62a388f133e",
        "cb1994e4-3fb7-4c7d-a860-e531efc1f457",
        "13c6c817-066d-4717-9df1-b44611091814",
        "4a104c10-d80f-4612-9024-02225b80102f",
        "300e1644-4aa1-4bbc-85a9-8a82dfaf5fdf",
        "bd2e9938-a6ed-4394-90c3-76614705a0b1"
      ]
    },
    {
      "id": "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
      "title": "La Tempesta in pittura ('700 e '800)",
      "number": null,
      "depth": 2,
      "type": "page",
      "contents": [
        {
          "src": "",
          "value": "http:\/\/dev-sc-studio.gruppometa.it\/api\/content\/133f9aab-a7e4-4325-bb21-f2ca6d24c03c\/media\/William_Hogarth_The%20Tempest.jpeg",
          "icon": "icon-picture",
          "view": "inline",
          "id": "63efd1a3-92fd-48de-bb25-e486c4d7ecd9",
          "type": "image",
          "title": "William_Hogarth_The Tempest"
        },
        {
          "src": "",
          "value": "http:\/\/dev-sc-studio.gruppometa.it\/api\/content\/133f9aab-a7e4-4325-bb21-f2ca6d24c03c\/media\/Millais.%20Feridinand-Ariel.jpeg",
          "icon": "icon-picture",
          "view": "inline",
          "id": "12dcf767-4ea0-4649-9606-effab61dd15d",
          "type": "image",
          "title": "Millais. Feridinand-Ariel"
        }
      ]
    },
    {
      "id": "860f36dd-e19f-4138-ad9f-4a3cdccffd86",
      "title": "La Tempesta in pittura ('700 e '800)",
      "page": "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
      "number": null,
      "depth": 3,
      "type": "paragraph"
    },
    {
      "id": "6f9c881c-a62b-45f3-a0e8-73a42ad442f0",
      "title": "La Tempesta in pittura ('700 e '800)",
      "page": "f02149b5-1897-4f8f-ab5c-0b0f1a0d1507",
      "number": null,
      "depth": 3,
      "type": "paragraph"
    },
    {
      "id": "6788be27-8011-4283-8960-8dafaf1e249e",
      "title": "Sottolivelli",
      "number": null,
      "depth": 2,
      "type": "page",
      "contents": [
        {
          "src": "",
          "value": "http:\/\/dev-sc-studio.gruppometa.it\/api\/content\/133f9aab-a7e4-4325-bb21-f2ca6d24c03c\/media\/Millais.%20Feridinand-Ariel.jpeg",
          "icon": "icon-picture",
          "view": "inline",
          "id": "12dcf767-4ea0-4649-9606-effab61dd15d",
          "type": "image",
          "title": "Millais. Feridinand-Ariel"
        }
      ]
    },
    {
      "id": "f4ff0f39-9d73-4ae8-b218-6019dd56bd53",
      "title": "Sottolivelli",
      "page": "6788be27-8011-4283-8960-8dafaf1e249e",
      "number": null,
      "depth": 3,
      "type": "paragraph"
    },
    {
      "id": "1a42955f-6f7c-4e05-87ea-e62a388f133e",
      "title": "Sottolivello 2",
      "number": null,
      "depth": 2,
      "type": "page"
    },
    {
      "id": "ffb54e05-b9a2-410b-a7cf-069497402161",
      "title": "Sottolivello 2",
      "page": "1a42955f-6f7c-4e05-87ea-e62a388f133e",
      "number": null,
      "depth": 3,
      "type": "paragraph"
    },
    {
      "id": "cb1994e4-3fb7-4c7d-a860-e531efc1f457",
      "title": "Mappa Concettuale - Drammaturgia",
      "number": null,
      "depth": 2,
      "type": "page"
    },
    {
      "id": "13c6c817-066d-4717-9df1-b44611091814",
      "title": "Contenuto VR",
      "number": null,
      "depth": 2,
      "type": "page",
      "contents": [
        {
          "src": "",
          "value": "http:\/\/dev-sc-studio.gruppometa.it\/api\/content\/133f9aab-a7e4-4325-bb21-f2ca6d24c03c\/media\/industria-QR.png",
          "icon": "icon-picture",
          "view": "inline",
          "id": "62a9546c-8485-43c7-b507-78a4b1d9485e",
          "type": "image",
          "title": "industria-QR"
        }
      ]
    },
    {
      "id": "c1f6d2a4-16ca-49ff-9ba5-17ab42b84075",
      "title": "Contenuto VR",
      "page": "13c6c817-066d-4717-9df1-b44611091814",
      "number": null,
      "depth": 3,
      "type": "paragraph"
    },
    {
      "id": "4a104c10-d80f-4612-9024-02225b80102f",
      "title": "Storia senza nome",
      "number": null,
      "depth": 2,
      "type": "page"
    },
    {
      "id": "300e1644-4aa1-4bbc-85a9-8a82dfaf5fdf",
      "title": "Il ruolo di Calibano",
      "number": null,
      "depth": 2,
      "type": "page"
    },
    {
      "id": "bd2e9938-a6ed-4394-90c3-76614705a0b1",
      "title": "Intreccio relazionale dei personaggi",
      "number": null,
      "depth": 2,
      "type": "page"
    }
  ]
};