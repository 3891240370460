import { Component, ElementRef, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { environment } from 'src/index';
import { ChatService, LoadingService } from 'src/index/services.index';
import { inOutPaneAnimation } from './components/shared/animations';

@Component({
    selector: 'meta-contents-viewer-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '150ms', direction: 'enterRight' }),
    ],
})
export class AppComponent {
    @HostBinding('attr.app-version') libVersionAttr = `${environment.version}.${environment.build}.${environment.patch}` +
        (environment.instanceVersion ? `_${environment.instanceVersion}` : '');
    title = environment.name;

    constructor(
        public chatService: ChatService,
        private loadingService: LoadingService,
        private titleService: Title,
        private el: ElementRef,
        translate: TranslateService,
        router: Router,
    ) {
        translate.setDefaultLang(environment.defaultLanguage);

        if (this.title && this.title.length > 0) {
            this.titleService.setTitle(this.title);
        }
        router.events.pipe(
            filter(event => (event instanceof NavigationStart)),
            map(e => e as NavigationStart),
        ).subscribe(event => {
            if (event.navigationTrigger === 'popstate') {
                this.loadingService.hide();
            }
        });
        const rootEl: HTMLElement = this.el.nativeElement;
        rootEl.removeAttribute('data-root-api');
    }
}
