<router-outlet />
<meta-loading />
@if (chatService.$chatShown()) {
    <meta-chat @inOutPaneAnimation [course]="chatService.$currentCourse()" [isGlobal]="chatService.$isGlobal()"
        (doClose)="chatService.toggleChat$.emit(false)" />
}
@if ($showPrivacyDisclaimer()) {
    <meta-popup [config]="privacyDisclaimerConfig" [bodyTemplateRef]="privacyDisclaimer">
        <ng-template #privacyDisclaimer>
            @for (section of $gdprSections(); track $index) {
                @if (section.title) {
                    <h2 class="font-semibold text-xl mt-2">{{section.title}}</h2>
                }
                <div class="mb-2" [innerHTML]="section.text"></div>
            }
        </ng-template>
    </meta-popup>
}