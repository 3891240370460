import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from "@angular/router";

import { LocalizationModule, SharedModule } from "src/core/index/shared.module.index";
import { LoginComponent, ResetPasswordComponent } from 'src/index/components.index';
import { LoginGuard, ResetPasswordGuard } from "src/index/services.index";

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginComponent,
    },
    {
        path: 'reset-password/:token', // If changed, change also in authenticationService
        canActivate: [ResetPasswordGuard],
        component: ResetPasswordComponent,
    }
];

@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
    ],
    providers: [
        LoginGuard,
        ResetPasswordGuard,
    ],
    exports: [
        LoginComponent,
        ResetPasswordComponent,
    ],
})
export class LoginModule {
    getRoutes() {
        return routes;
    }
}
