import { CommonConfig, common as commonCore, } from "src/index";

export const common: CustomCommonConfig = {
    ...commonCore,
    // Impostare qui gli override per i valori della configurazione comune tra sviluppo e produzione
    id: 'h2iosc',
    name: 'H2IOSC',
    instanceVersion: '1.0.2',
    defaultLanguage: 'it',
    multiLanguage: true,
    availableLanguages: ['en', 'it'],
    customLanguageResourcesPrefixes: ['/assets/i18n/'],

    logo: 'assets/images/Logo_H2IOSC_bianco_extended.png',
    hideFooter: false,
    footerText: `<span class="-mx-base flex flex-row items-center justify-between gap-4"><span>H2IOSC Project - Humanities and cultural Heritage Italian Open Science Cloud funded by the European Union - NextGenerationEU - NRRP M4C2 - Project code IR0000029 - CUP B63C22000730005</span>
        <span><img class="h-4" src="assets/images/GarrCloudLogo.png" title="Cloud Hosting Garr" alt="Garr Cloud Logo"/></span></span>`,

    hideChat: true,
    hideChatToggler: true,

    myCoursesModule: {
        hideAdvancedSearch: true,
        provideCertificate: true,
        certificateLogos: ['assets/images/Logo_H2IOSC_colori_extended.jpg', 'assets/images/logo-ilc.png', 'assets/images/logo_CLARIN-IT.png'],
        coursesPreviewConfig: {
            showImage: true,
            showDescription: true,
            showProgress: true,
        }
    },
    myGuidesModule: {
        hideAdvancedSearch: true,
    },
    globalSearchModule: {
        ...commonCore.globalSearchModule,
        searchInputPlaceholder: 'Search in H2IOSC',
        contentsPreviewConfig: {
            showImage: true,
            showDescription: true,
            showSkills: true,
            showContentTypeIcon: true,
        },
    },
    viewerModule: {
        ...commonCore.viewerModule,
        trackProgressType: 'manual',
        toolbar: {
            config: {
                ...commonCore.viewerModule?.toolbar?.config ?? {},
                activeToolId: 'index',
            },
        },
        liquidViewer: {
            ...commonCore.viewerModule?.liquidViewer,
            customCssPath: 'assets/liquidViewerCustomizations.css',
        },
    },

    registrationModule: {
        hideSkillsSetting: true,
        registrationType: 'simple',
    },

    loginModule: {
        customMessage: '<p class="flex flex-col justify-center items-center gap-1"><img class="h-7" src="assets/images/GarrCloudLogo.png" title="Cloud Hosting Garr" alt="Garr Cloud Logo"/><span class="text-xs">Cloud Hosting Garr</span></p>'
    },

    coursePreview: `${commonCore.baseContentUrlApi}/course/:uuidCourse`,
};

interface CustomCommonConfig extends CommonConfig {
    coursePreview: string;
}