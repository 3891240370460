@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="[]" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6" [ngClass]="{ 'xl:pl-sidebar': sideBarShown$ | async }">
	<section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
        <div class="flex flex-col-reverse lg:flex-row">
            <div class="w-full flex justify-between">
                <div>
                    <h1 class="text-4xl font-bold">{{'Self Assessment' | translate}}</h1>
                </div>
                <div>
                    <a class="btn btn-primary w-full h-9 items-center justify-center rounded-md px-5 py-2">{{'New' | translate}}</a>
                </div>
            </div>
        </div>
    </section>

    <section class="px-base xl:px-base-desk">
        @for (set of sets$ | async; track $index) {
            <div class="flex items-center border-b border-[#E2E2E2] mb-4 pb-4">
                <h2 class="text-[1.625rem] font-bold">{{ set.title }}</h2>
                <div class="pl-4 ml-auto flex gap-6">
                    <!-- <a [routerLink]="section.routerLink" [relativeTo]="currentRoute" -->
                    <a class="btn btn-primary w-full h-9 items-center justify-center rounded-md px-5 py-2">{{'Edit' | translate}}</a>
                    <button type="button" class="btn btn-secondary w-full h-9 items-center justify-center rounded-md px-5 py-2"
                        (click)="deleteSet(set)">
                        {{'Remove' | translate}}
                    </button>
                </div>
            </div>
        }
    </section>
	
	@if (!hideFooter) {
		<meta-footer />
	}
</main>
