import { Component, inject, input, signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';


import { environment, User } from 'src/index';
import { AuthenticationService, LoadingService, PopupService, RoutingService, UserService, Validation } from 'src/index/services.index';

@Component({
    selector: 'meta-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
    authService = inject(AuthenticationService<User>);
    formBuilder = inject(FormBuilder);
    router = inject(Router);
    loadingService = inject(LoadingService);
    routingService = inject(RoutingService);
    userService = inject(UserService);
    popupService = inject(PopupService);
    route = inject(ActivatedRoute);
    config = { ...environment.loginModule };

    appName = environment.name;
    logo = environment.logo;

    minPwdLength = environment.minPwdLength;
    maxPwdLength = environment.maxPwdLength;
    resetToken = this.route.snapshot.params['token'];
    form: FormGroup = this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.minLength(this.minPwdLength), Validators.maxLength(this.maxPwdLength)]],
        confirmPassword: ['', [Validators.required]],
    }, {
        validators: [Validation.match('newPassword', 'confirmPassword')],
    });
    $submitted = signal(false);

    $errorMsg = signal('');

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    goToLogin() {
        const queryParams = this.authService.redirectAfterLogin.queryParams || {};
        this.routingService.goToLogin(queryParams || {});
    }

    resetPassword() {
        this.$submitted.set(true);
        if (this.form.valid) {
            this.$errorMsg.set('');
            this.loadingService.show();
            firstValueFrom(this.userService.resetPassword(this.form.value.newPassword, this.resetToken))
                .then(res => {
                    if (res) {
                        this.popupService.success('Password modificata');
                        this.routingService.goToLogin();
                    } else {
                        this.$errorMsg.set('An error occurred while changing your password');
                    }
                })
                .catch((err) => this.$errorMsg.set(err || 'An error occurred while changing your password'))
                .finally(() => this.loadingService.hide());
        }
    }
}
