import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Module } from 'src/index';

@Component({
    selector: 'meta-content-detail',
    templateUrl: './content-detail.component.html',
    styleUrls: ['./content-detail.component.css']
})
export class ContentDetailComponent {
    @Input() type: 'course' | 'guide' | 'content' = 'content';
    @Input() cover: string = '';
    @Input() title: string = '';
    @Input() subtitle: string | undefined;
    @Input() duration: string = '';
    @Input() description: string = '';
    @Input() skills: string[] = [];
    @Input() skillsNeeded: string[] = [];
    @Input() modules: Module[] = [];
    @Input() preview: string = '';
    @Input() showBottomButton = true;

    get defaultOpenBtnLabel() {
        switch (this.type) {
            case 'course':
                return 'Start the course';
            case 'guide':
                return 'Consult the guide';
            default:
                return 'View content';
        }
    }
    private _openBtnLabel = '';
    @Input() set openBtnLabel(label: string) {
        this._openBtnLabel = label;
    }
    get openBtnLabel() {
        return this._openBtnLabel || this.defaultOpenBtnLabel;
    }

    @Output() openContent = new EventEmitter<string | void>();
}
