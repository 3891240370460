@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="$menuItems()" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300" [ngClass]="{ 
    'xl:pl-sidebar': sideBarShown$ | async,
    'pb-6': !hideFooter
}">
	<!-- Exercise page -->
    @if ($set()) {
        @if ($terminated()) {
            <section class="py-6 px-base xl:px-base-desk">
                <div class="pl-8 xl:pl-0 flex justify-between items-center gap-6 flex-wrap pb-6">
                    <div class="flex items-center">
                        <h1 class="text-3xl font-bold">{{ $set()!.title | translate}}</h1>
                    </div>
                </div>
                <div class="mt-6 px-4 py-8 border border-input-color rounded-md flex items-start gap-4 text-xl">    
                    <div class="flex flex-col gap-y-[1.125rem]">
                        @if ($userProfile()) {
                            <p class="font-bold">{{'Maturity level' | translate}}: {{ $userProfile()!.level | translate}}</p>
                            <p [innerHTML]="$userProfile()!.text"></p>
                        } @else {
                            <p class="font-bold">{{'No profiles found' | translate}}</p>
                        }
                    </div>
                </div>
            </section>
        } @else {
            <section class="py-6 px-base xl:px-base-desk">
                <div class="pl-8 xl:pl-0 flex justify-between items-center gap-6 flex-wrap pb-6">
                    <div class="flex items-center">
                        <a [routerLink]="$backUrl()" class="mr-2">
                            <meta-icon icon="angle-left" class="text-xl text-primary" />
                            <span class="sr-only">{{'Go back' | translate}}</span>
                        </a>
                        <h1 class="text-3xl font-bold">{{ $set()!.title }}</h1>
                    </div>
                </div>
                <p class="text-xl" [innerHTML]="$set()!.description"></p>
            </section>
            <section class="px-base xl:px-base-desk flex flex-col gap-6" aria-live="polite">
                @for (question of $visibleQuestions(); let questionIndex = $index; track questionIndex) {
                    <div class="px-4 py-8 border border-input-color rounded-md flex items-start gap-4 text-xl">
                        <span class="font-bold text-primary">{{ questionIndex + 1 }}</span>
                        <div class="flex flex-col gap-y-[1.125rem]">
                            <p [innerHTML]="question.text | translate"></p>
                            @for (answer of question.answers; track $index) {
                                <label class="inline-flex gap-x-5 items-center">
                                    @if (question.answerType === 'single') {
                                        <input type="radio" class="exercise shrink-0" #radio (change)="handleUserResponse(answer.uuid, radio.checked, question.originalQuestionsIndex)" 
                                            [name]="question.uuid" [value]="answer.uuid" [ngModel]="question.userAnswer" />
                                    } 
                                    @else if (question.answerType === 'multiple') {
                                        <input type="checkbox" class="exercise shrink-0" #checkbox (change)="handleUserResponse(answer.uuid, checkbox.checked, question.originalQuestionsIndex)" 
                                            [name]="question.uuid" [value]="answer.uuid" [checked]="answer.checked" />
                                    }
                                    <p [innerHTML]="answer.text | translate"></p>
                                </label>
                            }
                        </div>
                    </div>
                }
            </section>
        }

        <section class="px-base xl:px-base-desk flex flex-col gap-y-4">
            @if ($tip()) {
                <div class="text-xl" [innerHTML]="$tip() | translate"></div>
            }
            @if ($suggestedCourses().length > 0 && $studyPathCourses()) {
                <meta-suggested-courses [title]="''" [courses]="$suggestedCourses()" [currentStudyPath]="$studyPathCourses()!" (previewCourse)="showCourseDetails($event)"/>
            } 
        </section>

        <div class="py-6 px-base xl:px-base-desk flex justify-end items-center gap-6 flex-wrap mt-auto mb-6">
            @if ($terminated()) {
                <div class="flex gap-2">
                    <a [routerLink]="$backUrl()" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
                        {{'Go back to home page' | translate}}
                    </a>
                    @if (config.enableTryAgain && $currentExecution()) {
                        <a href="javascript:void(0);" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
                            (click)="reset()">{{'Try again' | translate}}</a>
                    }
                </div>
            } @else {
                <a href="javascript:void(0);" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
                    [ngClass]="{ 'disabled': !$canTerminate() }"
                    (click)="terminate()">{{'Go to set results' | translate}}</a>
            }
        </div>
    }

    <meta-footer [hideCustomFooter]="hideFooter"/>
</main>

@defer (when !!$courseToPreview()) {
	@if (!!$courseToPreview()) {
		<meta-modal class="courseDetails" (doClose)="$courseToPreview.set(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
			<meta-course-detail modal-content [course]="$courseToPreview()!" [needActivation]="!$courseToPreview()?.associatedToUser" 
				(updateData)="updateCourseToPreview($event)" (openCourse)="startCourse($courseToPreview()!, $event)"/>
		</meta-modal>
	}
}