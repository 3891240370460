import { Component, inject } from '@angular/core';

import { BehaviorSubject, combineLatest, firstValueFrom, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { AdvancedSearchData, Course, PaginatedResults, SearchWithFiltersPayload, environment } from 'src/index';
import { ConfigurationService, CoursesService, LoadingService, PopupService, RoutingService, isMobile, searchFiltersToFilterGroup } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';
import { Router } from '@angular/router';

@Component({
    selector: 'meta-my-courses',
    templateUrl: './my-courses.component.html',
    styleUrls: ['./my-courses.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class MyCoursesComponent extends HasDashboardSidebar {
    popupService = inject(PopupService);
    router = inject(Router);

    config = { ...environment.myCoursesModule };
    coursesPreviewConfig = {
        ...this.config.coursesPreviewConfig,
    };
    appName = environment.name;
    hideFooter = this.config.hideFooter || environment.hideFooter;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('my-courses');

    updatePage$ = new BehaviorSubject<number>(1);
    searchCourses$ = new BehaviorSubject<string>('');
    updateFilters$ = new BehaviorSubject<{
        [key: string]: any;
    } | undefined>(undefined);

    updateCousesResults$ = combineLatest([
        this.updatePage$,
        this.searchCourses$,
        this.updateFilters$,
    ]).pipe(
        map(([page, search, filters]) => ({
            page,
            search,
            filters,
        } as Partial<SearchWithFiltersPayload>)),
    );

    coursesResults$ = this.updateCousesResults$.pipe(
        // filter((reqData) => !!reqData), // TODO capire se far partire la chiamata anche se non sono stati impostati filtri
        tap(() => this.loadingService.show()),
        mergeMap(reqData => {
            if (this.config.hideAdvancedSearch) {
                return this.coursesService.getStudyPathCourses(reqData.search).pipe(
                    map(results => ({ results } as PaginatedResults<Course>)),
                );
            }
            return this.coursesService.searchCourses(reqData).pipe(shareReplay(1));
        }),
        startWith(undefined),
        tap(() => this.loadingService.hide()),
        shareReplay(1),
    );

    courses$ = this.coursesResults$.pipe(
        map(coursesResults => coursesResults?.results || []),
    );

    filters$ = this.coursesResults$.pipe(
        map(coursesResults => searchFiltersToFilterGroup(coursesResults)),
    );

    noResults$ = this.updateCousesResults$.pipe(
        switchMap((search) => this.coursesResults$.pipe(
            map(coursesResults => ({ ...search, coursesResults })),
            map(({ search, filters, coursesResults }) => !search && (filters || [])?.length <= 0 && coursesResults && coursesResults.results.length === 0),
        ))
    );

    hasResults$ = this.noResults$.pipe(
        map(noResults => !noResults),
    );

    noFilteredResults$ = this.updateCousesResults$.pipe(
        switchMap((search) => this.coursesResults$.pipe(
            map(coursesResults => ({ ...search, coursesResults })),
            map(({ search, filters, coursesResults }) => (search || (filters || [])?.length > 0) && coursesResults && coursesResults.results.length === 0),
        ))
    );

    pagination$ = this.coursesResults$.pipe(
        map(coursesResults => ({
            page: coursesResults?.page || 1,
            pages: coursesResults?.pages || 1,
        })),
    );

    hasPagination$ = this.pagination$.pipe(
        map(({ pages }) => pages > 1),
    );

    advancedSearchShown$ = new BehaviorSubject(false);

    courseToPreview$ = new BehaviorSubject<Course | undefined>(undefined);

    constructor(
        configurationService: ConfigurationService,
        private loadingService: LoadingService,
        private coursesService: CoursesService,
        private routingService: RoutingService,
    ) {
        super(configurationService);
    }

    loadPageResults(page: number) {
        this.updatePage$.next(page);
    }

    showCourseDetails(course: Course) {
        this.loadingService.show();
        firstValueFrom(this.coursesService.getCourseDetails(course))
            .then(completeCourse => {
                this.courseToPreview$.next(completeCourse);
                if (!completeCourse) {
                    this.popupService.error(this.configurationService.getCourseDetailErrorMsg);
                }
            })
            .catch(() => this.popupService.error(this.configurationService.getCourseDetailErrorMsg))
            .finally(() => this.loadingService.hide());
    }

    openCourse(course: Course, urlToOpen?: string | void) {
        if (course.progress) {
            this.navigateToCourse(course, urlToOpen);
        } else {
            this.startCourse(course, urlToOpen);
        }
    }

    startCourse(course: Course, urlToOpen?: string | void) {
        this.loadingService.show();
        firstValueFrom(this.coursesService.startCourse(course))
            .then(() => {
                this.courseToPreview$.next(undefined);
                this.navigateToCourse(course, urlToOpen);
            })
            .catch((err) => this.popupService.error(err.message || this.configurationService.startCourseErrorMsg))
            .finally(() => this.loadingService.hide());
    }

    navigateToCourse(course: Course, urlToOpen?: string | void) {
        if (urlToOpen) {
            return this.router.navigateByUrl(urlToOpen);
        }
        return this.coursesService.openCourse(course);
    }

    searchAdvanced(data: AdvancedSearchData) {
        this.updateFilters$.next(data.selectedFilters);
        this.advancedSearchShown$.next(false);
    }
}
