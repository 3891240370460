import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { CoursesService } from 'src/core/index/services.index';
import { Course, CoursePackage, Module, ModulePackage, environment } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class CoursesServiceCustom extends CoursesService {
    override integrateCourseData(course: Partial<Course> | Course): Partial<Course> | Course {
        return {
            ...super.integrateCourseData(course),
            description: course.description ?? course.authors?.map(a => a.trim()).join(', ') ?? '',
            teachers: (course.authors ?? []),
        };
    }

    override getContentModuleCourse(mainId: string, secondaryMainId: string, contentId: string) {
        return super.getContentModuleCourse(mainId, secondaryMainId, contentId).pipe(
            tap(response => {
                try {
                    if (response.contents) {
                        const contentsKeys = Object.keys(response.contents);
                        contentsKeys.forEach((key: any) => {
                            if (response.contents[key].type === 'image') {
                                response.contents[key].downloadable = true;
                            }
                        });
                    } else {
                        const responseKeys = Object.keys(response);
                        if (responseKeys.indexOf('file') >= 0 && responseKeys.indexOf('pages') >= 0) {
                            response.downloadable = true;
                        }
                    }
                } catch (e) { console.log(e) }
            }),
        );
    }

    override convertPackageCourse(course: CoursePackage): Partial<Course> {
        return {
            ...super.convertPackageCourse(course),
            abstract: course.abstract,
            authors: (course.authors || []).map(a => a.trim()),
            keywords: course.keywords || [],
            mainLanguage: course.mainLanguage,
            license: course.license,
            versionDate: course.versionDate,
            resourceUrl: course.resourceUrl,
            urlType: course.urlType,
            targetGroups: course.targetGroups || [],
            skillLevel: course.skillLevel,
            learningResourceType: course.learningResourceType,
            accessCost: course.accessCost,
            learningOutcome: course.learningOutcome,
        };
    }

    override convertPackageModule(module: ModulePackage, courseId?: string): Module {
        const { level, ...moduleData } = module;
        return {
            ...moduleData,
            ...super.convertPackageModule(module, courseId),
            description: ((module as any).authors || []).map((a: string) => a.trim()).join(', '),
            authors: ((module as any).authors || []).map((a: string) => a.trim()),
        };
    }

    override getCourse(courseId: string): Observable<Course | undefined> {
        if (environment.courseDetail) {
            const baseUrl = environment.courseDetail.replace(':uuidCourse', courseId);
            const url = baseUrl + ((this.authService.$currentUser() as any).previewToken ? `?token=${(this.authService.$currentUser() as any).previewToken}` : "");
            return this.httpClient.get<Course | { result: CoursePackage }>(url).pipe(
                catchError(() => of(undefined)),
                map(completeCourse => completeCourse ? ({
                    ...(completeCourse as any)?.result ? this.convertPackageCourse((completeCourse as any)?.result) : completeCourse,
                }) : completeCourse),
                map(course => course as Course),
            );
        }
        return of(undefined);
    }

    getCoursePreview(courseId: string, token: string): Observable<Course | undefined> {
        if (environment.courseDetail) {
            return this.httpClient.get<Course | { result: CoursePackage }>(environment.courseDetail.replace(':uuidCourse', courseId)).pipe(
                catchError(() => of(undefined)),
                map(completeCourse => completeCourse ? ({
                    ...(completeCourse as any)?.result ? this.convertPackageCourse((completeCourse as any)?.result) : completeCourse,
                }) : completeCourse),
                map(course => course as Course),
            );
        }
        return of(undefined);
    }
}