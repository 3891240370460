<div class="flex gap-2">
    <div class="flex flex-col">
        <h1 class="text-3xl font-semibold">{{$course().title | translate}}</h1>
        @if ($course().subtitle) {
            <h2 class="text-xl leading-6 font-semibold">{{$course().subtitle! | translate}}</h2>
        }
        @if ($course().duration) {
            <div class="inline-flex items-center mt-2">
                <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
                <span>{{$course().duration}}</span>
            </div>
        }
    </div>
    <div class="ml-auto mt-auto min-w-fit" [ngClass]="{
        'pr-8': !$course().duration
    }">
        <a (click)="startCourse()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{$openBtnLabel() | translate}}
        </a>
    </div>
</div>
<div class="flex flex-col md:flex-row gap-x-6 mt-6">
    <img class="w-full aspect-square" 
        [ngClass]="{
            'max-w-[15.625rem] md:w-3/12 h-full object-cover': $courseDescription() || $courseMetadataInDescription().length > 0,
            'max-h-[10.5rem] object-contain object-left-top': !$courseDescription() && $courseMetadataInDescription().length === 0
        }"
        [src]="$course().image" [attr.alt]="$course().title | translate">
    @if ($courseDescription() || $courseMetadataInDescription().length > 0) {
        <div class="md:w-9/12 mt-4 md:mt-0">
            @if ($courseDescription()) {
                @if ($courseMetadataInDescription().length > 0) {
                    <div class="text-sm px-1">
                        <p class="text-[#767676] whitespace-pre-line leading-tight">{{$courseDescription() | translate}}</p>
                    </div>
                } @else {
                    <p class="whitespace-pre-line" [innerHTML]="$courseDescription() | translate"></p>
                }
            }
            
            @for (metadata of $courseMetadataInDescription(); track $index) {
                <div class="text-sm mt-3 px-1">
                    @if (metadata.labelInline) {
                        <p class="text-[#767676] leading-tight">
                            @if (metadata.label) {
                                <span class="text-primary-light font-bold">{{metadata.label | translate}}: </span>
                            }
                            <span class="italic">{{metadata.value | translate}}</span>
                        </p>
                    } @else {
                        @if (metadata.label) {
                            <span class="text-primary-light font-bold">{{metadata.label | translate}}: </span>
                        }
                        <p class="text-[#767676] whitespace-pre-line leading-tight">{{metadata.value | translate}}</p>
                    }
                </div>
            }
    
        </div>
    }
</div>
@if ($showAwaitingApprovalMessage()) {
    <div class="mt-4 px-base xl:px-base-desk bg-primary py-5 -ml-6 -mr-6">
        <div class="max-w-[54.375rem] mx-auto">
            <div class="flex justify-between items-center gap-8 flex-wrap">
                <div class="text-xl leading-7 font-bold text-white whitespace-pre-line">
                    {{'Thank you for signing up, you will be contacted as soon as possible by the organizing secretary for confirmation' | translate}}
                </div>
            </div>
        </div>
    </div>
}
@if ($course().skillsNeeded?.length || $course().skills?.length) {
    <div class="mt-8">
        @if ($course().skillsNeeded?.length) {
            <div>
                <p class="text-primary font-bold">{{'Skills required' | translate}}</p>
                <p>
                    @for (skill of $course().skillsNeeded; track $index) {
                        {{ skill | translate }}{{$last ? '': ', '}}
                    }
                </p>
            </div>
        }
        @if ($course().skills?.length) {
            <div class="mt-3">
                <p class="text-primary font-bold">{{'Skills you will get' | translate}}</p>
                <p>
                    @for (skill of $course().skills; track $index) {
                        {{ skill | translate }}{{$last ? '': ', '}}
                    }
            </div>
        }
    </div>
}
@if ($course().modules?.length) {
    <div class="mt-8">
        <p class="text-xl text-primary font-bold">{{$modulesSectionTitle() | translate}}</p>
        <div class="flex flex-col gap-y-3 mt-3">
            @for (module of $course().modules; track $index) {
                <ng-template *ngTemplateOutlet="moduleData; context: {module}" />
            }
            <ng-template #moduleData let-module="module">
                <article class="h-[5.625rem] flex bg-white border border-[#E2E2E2] rounded-md overflow-hidden" (click)="startCourse(module.routerLink)" [ngClass]="{
                    'cursor-pointer': module.routerLink
                }">
                    @if (module.cover) {
                        <div class="w-[5.7rem] h-full">
                            <img class="w-full h-full object-cover" [src]="module.cover" [attr.alt]="module.title | translate">
                        </div>
                    }
                    <div class="w-[calc(100%-5.7rem)] md:w-[calc(100%-7.25rem)] px-2 pt-1.5 pb-2.5">
                        <h2 class="text-lg text-main-text truncate px-1">{{module.title | translate}}</h2>
                        <div class="text-sm mt-1 px-1">
                            @if (module.description) {
                                <p class="leading-tight line-clamp-2">{{module.description | translate}}</p>
                            }
                        </div>
                    </div>
                </article>
            </ng-template>
        </div>
    </div>
}
@if (($course().modules?.length || 0) > 2) {
    <div class="text-center mt-12">
        <a (click)="startCourse()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{$openBtnLabel() | translate}}
        </a>
    </div>
}

@if ($showActivationModal()) {
    <meta-modal [title]="'Enter your activation code' | translate" [settings]="{closable: true, closeOnEscape: true}" (doClose)="$showActivationModal.set(false)">
        <ng-container modal-content>
            <div class="mt-12">
                <label for="code" class="sr-only">{{'Activation code' | translate}}</label>
                <div>
                    <input id="code" name="code" type="text" class="h-10" [ngClass]="{
                            '!border-red-600': $activationErrorMsg()
                        }" [(ngModel)]="courseActivationCode" />
                </div>
                @if ($activationErrorMsg()) {
                    <div>
                        <span class="text-red-600 text-sm">{{$activationErrorMsg() | translate}}</span>
                    </div>
                }
            </div>
            <div class="text-center mt-12">
                <a (click)="activateCourse()"
                    class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">{{'Continue' | translate}}</a>
            </div>
        </ng-container>
    </meta-modal>
}