import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent, FaqsComponent, ViewerComponent } from 'src/index';
import { AuthGuard, PreviewGuard } from 'src/index/services.index';

export const routes: Routes = [
    {
        path: 'faqs',
        canActivate: [AuthGuard],
        component: FaqsComponent,
    },
    {
        path: 'preview',
        canActivate: [PreviewGuard],
        component: ViewerComponent,
        data: { contentType: 'course' },
    },
    {
        path: 'about',
        component: AboutComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    getRoutes() {
        return routes;
    }
}
