import { Component, EventEmitter, inject } from '@angular/core';
import { BehaviorSubject, firstValueFrom, shareReplay } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { environment, SelfAssessmentSet } from 'src/index';
import { isMobile, LoadingService, PopupService } from 'src/index/services.index';
import { SelfAssessmentService } from '../../../services/self-assessment.service';
import { inOutPaneAnimation } from '../../shared/animations';
import { HasDashboardSidebar } from '../../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-self-assessment-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class SelfAssessmentManagerComponent extends HasDashboardSidebar {
    selfAssessmentService = inject(SelfAssessmentService);
    activatedRoute = inject(ActivatedRoute);
    popupService = inject(PopupService);
    loadingService = inject(LoadingService);

    updateSets$ = new EventEmitter<void>();

    config = { ...environment.selfAssessmentModuleConfig };
    hideFooter = this.config.hideFooter || environment.hideFooter;
    appName = environment.name;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('self-assessment');

    sets$ = this.selfAssessmentService.getSets().pipe(
        shareReplay(1),
    );

    deleteSet(set: SelfAssessmentSet) {
        this.popupService.confirm(`<span>Eliminare il set: ${set.title}</span>`, {
            size: 'medium',
            callbacks: {
                confirm: () => {
                    this.loadingService.show();
                    firstValueFrom(
                        this.selfAssessmentService.deleteSet(set.uuid)
                    ).finally(() => {
                        this.loadingService.hide();
                        location.reload();
                    });
                }
            }
        });
    }
}