import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Buffer } from 'buffer';
import html2canvas from 'html2canvas-pro';

import { Content, PaginatedResults, SearchFilterGroup } from 'src/index';

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function enumerate(n: number) {
    return [...Array(n)].map((_, i) => i);
}

export function capitalize(str: string) {
    try {
        return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
        return str;
    }
}

export function isMobile() {
    return window.innerWidth < 1280;
}

export class Validation {
    static match(controlName: string, checkControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(checkControlName);

            if (checkControl?.errors && !checkControl.errors['matching']) {
                return null;
            }

            if (control?.value !== checkControl?.value) {
                controls.get(checkControlName)?.setErrors({ matching: true });
                return { matching: true };
            } else {
                return null;
            }
        };
    }
}

export function searchFiltersToFilterGroup(searchResults?: PaginatedResults<Content>) {
    const filters = searchResults?.filters ?? {};
    return Object.keys(filters).map((id: string) => ({
        id,
        label: searchResults?.filtersLabels ? searchResults?.filtersLabels[id] ?? '' : '',
        type: 'checkbox',
        values: filters[id],
        selectedValue: (searchResults?.filtersApplied?.filters ?? []).filter(f => !!f[id]).map(f => f[id]),
    } as SearchFilterGroup));
}

export type addPrefix<TKey, TPrefix extends string> = TKey extends string ? `${TPrefix}${TKey}` : never;
export type removePrefix<TPrefixedKey, TPrefix extends string> = TPrefixedKey extends addPrefix<infer TKey, TPrefix> ? TKey : '';
export type prefixedValue<TObject extends object, TPrefixedKey extends string, TPrefix extends string> = TObject extends { [K in removePrefix<TPrefixedKey, TPrefix>]: infer TValue } ? TValue : never;
export type addPrefixToObject<TObject extends object, TPrefix extends string> = {
    [K in addPrefix<keyof TObject, TPrefix>]: prefixedValue<TObject, K, TPrefix>
}

export function removeEmptyQueryParams(url: string) {
    const urlParts = url.split('?');
    let queryParams = '';
    if (urlParts && urlParts[1]) {
        queryParams = urlParts[1].split('&').map(q => {
            const paramParts = q.split('=');
            return paramParts && paramParts[1] ? q : '';
        }).join('');
    }
    return queryParams ? `${urlParts[0]}?${queryParams}` : urlParts[0];
}

export function dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    if (typeof dataUrl !== 'string') {
        return undefined;
    }
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
        return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
        return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
}

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];
export function convertBytesSize(sizeInBytes: number, longForm?: boolean) {
    const units = longForm ? FILE_SIZE_UNITS_LONG : FILE_SIZE_UNITS;

    let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);

    const size = sizeInBytes / Math.pow(1024, power); // size in new units
    const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
    const unit = units[power];

    return `${formattedSize}${unit}`;
}

export function toCanvas(element: HTMLElement): Promise<HTMLCanvasElement> {
    return html2canvas(element, {
        useCORS: true,
        scale: 2,
    });
}

export function isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
}