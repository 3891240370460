import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { catchError, map, of, tap } from 'rxjs';
import { User } from 'src/index';

import { AuthenticationService, LoadingService, RoutingService } from 'src/index/services.index';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService<User>,
        private loadingService: LoadingService,
        private router: RoutingService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.show();
        return this.authenticationService.checklogin().pipe(
            tap(() => this.loadingService.hide()),
            catchError(e => of(undefined)),
            map(currentUser => {
                if (!currentUser) {
                    if (!route.params['token']) {
                        this.router.goToLogin();
                        return false;
                    }
                    return true;
                }
                this.router.goToHome();
                return false;
            }),
        );
    }
}
