<div id="viewer-index" class="flex flex-col px-base overflow-y-auto">
	<div class="sticky top-0 bg-white w-full pt-base flex flex-col z-1000">
		<div class="flex justify-between items-center text-viewer-secondary text-lg mb-2">
			<div class="flex items-center justify-center gap-x-2">
				<meta-icon icon="list"></meta-icon>
				<p class="inline-block hover:cursor-default">{{'Index' | translate}}</p>
			</div>
			<button id="index-close-button" class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
				<p>{{'Close' | translate}}</p>
				<meta-icon icon="close" additionalClasses="text-base"></meta-icon>
			</button>
		</div>

		<div class="flex gap-x-4 mb-2">
			@if ($index()?.cover) {
				<img class="my-auto h-20 w-20 object-cover shrink-0" [src]="$index()?.cover" [alt]="'Cover' | translate" />
			}
			<p class="my-auto text-lg font-semibold">{{ $index()?.title ?? '' | translate}}</p>
		</div>

		<div role="tablist" class="w-full px-2 mb-2 border-b border-b-gray-300">
			@for (tab of $tabList(); track $index) {
				@if (tab.visible) {
					<button role="tab" [ngClass]="selectedTab === tab.id ? 'active' : ''" [attr.aria-selected]="selectedTab === tab.id"
						[attr.aria-controls]="tab.id" [id]="tab.id"
						class="inline-flex justify-center items-center px-2 py-1 mx-1 rounded-sm border border-b-0 border-gray-300 transition-all hover:bg-gray-200"
						(click)="changeTab(tab.id)">
						<p>{{ tab.label | translate }}</p>
					</button>
				}
			}
		</div>
	</div>

	@if (selectedTab === 'index') {
		@if ($index()) {
			<div [id]="selectedTab" role="tabpanel" class="relative px-2 pb-2 flex flex-col overflow-y-auto">
				<ng-template *ngTemplateOutlet="indexBase; context: { level: 0, index: $index() }"></ng-template>
			</div>
		} @else {
			<div class="h-full relative">
				<meta-loading [standAlone]="true" />
			</div>
		}
	} @else if (selectedTab === 'events') {
		<div [id]="selectedTab" role="tabpanel" class="relative px-2 pb-2 flex flex-col overflow-y-auto">
			@for (event of $events(); track $index) {
				<div class="first:pt-1 pb-3 mb-3 flex flex-col gap-1 border-b border-b-gray">
					<strong>{{event.title || '' | translate}}</strong> 
					<div class="flex flex-row gap-1 items-end justify-between ">
						<div class="flex flex-col">
							<strong>{{'Event date' | translate}}:</strong>
							{{ event.date }}
						</div>
						@if (event.inProgress) {
							@if (event.url) {
								<a [href]="event.url" target="_blank" class="text-green-600 hover:underline"><em>{{'Participate' | translate}}</em></a>
							}
						} @else if (event.passed) {
							@if (event.rec) {
								<a [href]="event.rec" target="_blank"><em>{{'View recording' | translate}}</em></a>
							} @else {
								<em class="text-red-600">{{'Event concluded' | translate}}</em>
							}
						} @else if (event.future) {
							<em class="text-secondary">{{'Event scheduled' | translate}}</em>
						}
					</div>
				</div>
			}
		</div>
	}

	<!-- TODO: per ogni tab replicare il div esterno del seguente HTML, gestendo correttamente i valori dei meta attributi. 
		Gestire visibilità tab attiva con proprietà del componente. -->
	<!-- <div *ngIf="selectedTab === 'index'" id="tabIndex" role="tabpanel" aria-describedby="index" class="relative px-2 pb-2 flex flex-col overflow-y-auto"> -->
	<!-- TODO: Gestire repeater su dati ottenuti da var di input. Gestire visibilità icone, apertura/chiusura e presenza di varie class opzionali in base a quanto indicato nella issue #4 -->
	<!-- <ng-template *ngTemplateOutlet="mimmo; context: { index: index }"></ng-template> -->
	<!-- </div> -->

	<!-- <div *ngIf="selectedTab === 'attachments'" id="tabAttachments" role="tabpanel" aria-describedby="attachments"
		class="relative px-2 pb-2 flex flex-col overflow-y-auto">
		<div *ngFor="let att of attachments" (click)="emitClick(att, 'attachment')">
			{{ att.title }}
		</div>
		<div *ngIf="attachments.length === 0">
			Nessun allegato
		</div>
	</div> -->
</div>

<ng-template let-index="index" let-item="item" let-level="level" #indexBase>
	<ul [attr.id]="item && item.id ? item.id : 'main-list'">
		@if (level === 0) {
			@for (item of $index()!.items; track $index) {
				<ng-template *ngTemplateOutlet="indexItem; context: { item: item, level: level }"></ng-template>
			}
		}
	</ul>
</ng-template>

<ng-template let-item="item" let-level="level" #indexItem>
	<li [ngClass]="{ 'first:pt-1 pb-1': level > 0 }">
		<ng-template *ngTemplateOutlet="indexItemButton; context: { item: item, level: level }"></ng-template>

		@if (item.type === 'chapter' && item.children && item.children.length > 0 && item.expanded) {
			<ul [id]="item.id + '-list'">
				@for (child of item.children; track $index) {
					<ng-template *ngTemplateOutlet="indexItem; context: { item: child, level: level + 1 }"></ng-template>
				}
			</ul>
		} @else if (item.type === 'page' && ((item.paragraphs && item.paragraphs.length > 0) || (item.subpages && item.subpages.length > 0)) && item.expanded) {
			<ul [id]="item.id + '-list'">
				@for (paragraph of item.paragraphs; track $index) {
					<ng-template *ngTemplateOutlet="indexItem; context: { item: paragraph, level: level + 1 }"></ng-template>
				}
				@for (subpage of item.subpages; track $index) {
					<ng-template *ngTemplateOutlet="indexItem; context: { item: subpage, level: level + 1 }"></ng-template>
				}
			</ul>
		}
	</li>
</ng-template>

<ng-template let-item="item" let-level="level" #indexItemButton>
	<div class="flex" [ngClass]="{ 'w-full p-1 hover:text-white hover:bg-viewer-secondary duration-150': level === 0 }">
		@if (itemCanBeExploded(item)) {
			<button [attr.aria-expanded]="item.expanded"
				[attr.aria-controls]="item.expanded ? item.id + '-list' : null" [attr.aria-label]="'Expand/Collapse' | translate"
				(click)="toggleEntry(item)" class="inline-flex mr-2" [ngClass]="{
					'pt-1.5': level === 0,
					'pt-1 text-[.75rem] text-viewer-secondary': level > 0
				}">
				<span class="contents-viewer-icon-angle-right" [ngClass]="{ hidden: item.expanded }"></span>
				<span class="contents-viewer-icon-angle-bottom" [ngClass]="{ hidden: !item.expanded }"></span>
			</button>
		}
		@if (viewerIndexService.getRouteByIndexItem(item); as itemRoute) {
			<a [href]="getA11yRoute(itemRoute)" (click)="onItemClicked($event, item)"
				class="text-left duration-150" [ngClass]="{
					'text-viewer-secondary hover:text-viewer-secondary-hover': $currentIndexItemId() === item.id,
					'inline-flex font-semibold text-lg only:pl-6': level === 0,
					'w-full hover:text-viewer-secondary': level > 0,
					'before:inline-block before:h-2 before:w-2 before:bg-viewer-secondary before:mr-2 before:mb-0.5 hover:text-viewer-secondary': !itemCanBeExploded(item) && level > 0
				}">
				@if (item.num) {
					<span class="mr-1">{{ item.num }}</span>
				}
				{{ item.title | translate}}
			</a>
		}
	</div>
</ng-template>