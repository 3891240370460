@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="$menuItems()" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6" [ngClass]="{ 'xl:pl-sidebar': sideBarShown$ | async }">
	<!-- Self Assessment page -->
	@if ($welcome()) {
		<section class="pt-6 pb-3 px-base xl:px-base-desk mb-8">
			<p class="text-xl text-primary font-bold">{{'Hi!' | translate:{ value:$currentUser() } }}</p>
			<h1 class="text-4xl font-bold">{{'Before we start' | translate}}</h1>
			@for (text of introTexts; track $index) {
				<p class="text-pretty mt-6" [ngClass]="{
					'mt-6': $first
				}" [innerHTML]="text | translate"></p>
			}
		</section>
	}
	
	<section class="px-base xl:px-base-desk flex flex-col gap-y-14 pt-6 mb-6">
		<div class="flex justify-between">
			<h2 class="text-3xl font-bold">{{ ($welcome() ? 'Fill out the questionnaire' : 'Maturity level') | translate}}</h2>
		</div>
		@if ($sets()?.length) {
			<div class="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
				@for (set of $sets(); track $index) {
					<div class="px-4 py-5 border border-input-color bg-white rounded-md flex flex-col items-start gap-y-[1.125rem]">
						<div class="w-full flex justify-between items-baseline">
							<h3 class="text-xl text-main-text w-full line-clamp-2">{{ set.title | translate }}</h3>
							@if (set.completed) {
								<meta-icon icon="check-round" class="text-green-600 text-xl"/>
							}
						</div>
	
						@if (set.description) {
							<p class="line-clamp-3" [innerHTML]="set.description | translate"></p>
						}
	
						@if (!set.completed) {
							<a [routerLink]="set.routerLink" (click)="trackSelfAssessmentStart()"
								class="btn btn-primary mt-auto h-10 items-center justify-center rounded-md px-6 py-2 font-bold">
								{{'Start' | translate}}
							</a>
						} @else {
							@if ($welcome()) {
								<a [routerLink]="set.routerLink"
									class="btn btn-primary mt-auto h-10 items-center justify-center rounded-md px-6 py-2 font-bold">
									{{'See the result' | translate}}
								</a>
							} @else {
								<a href="javascript:void(0);" (click)="showResults(set)"
									class="btn btn-primary mt-auto h-10 items-center justify-center rounded-md px-6 py-2 font-bold">
									{{set.executionProfile?.level ?? 'See the result' | translate}}
								</a>
							}
						}
					</div>	
				}
			</div>
			@if ($welcome()) {
				<div class="flex justify-end gap-4">
					<a href="javascript:void(0)" 
						class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
						(click)="goHome()">{{'Explore the courses' | translate}}</a>
					<a href="javascript:void(0)" 
						class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
						[ngClass]="{ 'disabled': !$canTerminate() }"
						(click)="goToResults()">{{'Go to the results' | translate}}</a>
				</div>
			} @else if ($suggestedCourses().length > 0 && $studyPathCourses()) {
				<meta-suggested-courses [courses]="$suggestedCourses()" [currentStudyPath]="$studyPathCourses()!" (previewCourse)="showCourseDetails($event)"/>
			} 
		}
	</section>

	<meta-footer [hideCustomFooter]="hideFooter"/>
</main>

@defer (when !!$courseToPreview()) {
	@if (!!$courseToPreview()) {
		<meta-modal class="courseDetails" (doClose)="$courseToPreview.set(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
			<meta-course-detail modal-content [course]="$courseToPreview()!" [needActivation]="!$courseToPreview()?.associatedToUser" 
				(updateData)="updateCourseToPreview($event)" (openCourse)="startCourse($courseToPreview()!, $event)"/>
		</meta-modal>
	}
}