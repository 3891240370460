import { inject, Injectable } from '@angular/core';
import { AnalyticsEvent, environment, ISendler, SendlerMethod, SendlerMethodParams } from 'src/index';
import { Ga4Service, MatomoService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  config = { ...environment.analytics };
  sendlerService = this.initSendler();

  initSendler(): ISendler | undefined {
    this.checkAnalyticsEnums();
    if (this.config.enable) {
      return this.config.type === 'Matomo' ? inject(MatomoService) : inject(Ga4Service)
    }
    return undefined
  }

  convertDate(date: Date) {
    return this.sendlerService?.convertDate ? this.sendlerService?.convertDate(date) : date.toISOString();
  }

  trackEvent<K extends SendlerMethod>(trackMethod: K, ...args: SendlerMethodParams[K]) {
    if (this.sendlerService && !this.isEventDisabled(trackMethod)) {
      this.sendlerService[trackMethod](...args as any);
    }
  }

  isEventDisabled(trackMethod: SendlerMethod) {
    const trackMethodKey = Object.keys(SendlerMethod)[Object.values(SendlerMethod).indexOf(trackMethod)];
    const analyticsEvent = Object.values(AnalyticsEvent)[Object.keys(AnalyticsEvent).indexOf(trackMethodKey)];
    const disableEvents = this.config.disableEvents || {};
    return disableEvents[analyticsEvent] === true;
  }

  private checkAnalyticsEnums() {
    const keys1 = Object.keys(AnalyticsEvent);
    const keys2 = Object.keys(SendlerMethod);
    const check = keys1.length === keys2.length && keys1.every(key => keys2.includes(key));
    if (!check) {
      console.error(`Analytics enums keys don't match! Events tracking may not work properly!`);
    }
  }
}
