import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs';

import { SwiperOptions } from 'swiper';

import { DatePipe } from '@angular/common';
import { environment, Module } from 'src/index';
import { AuthenticationService, CoursesService, LoadingService, RoutingService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-public-details',
    templateUrl: './course-public-details.component.html',
    styleUrls: ['./course-public-details.component.css']
})
export class CoursePublicDetailsComponent {
    loadingService = inject(LoadingService);
    coursesService = inject(CoursesService);
    route = inject(ActivatedRoute);
    authenticationService = inject(AuthenticationService);
    routingService = inject(RoutingService);

    config = { ...environment.publicModule };
    mainLogo = environment.logo;
    name = environment.name;
    currentYear = new Date().getFullYear();
    footerText = `&copy; ${this.currentYear} ${this.config.footerText || environment.footerText || environment.name}`;

    courseId$ = this.route.params.pipe(
        map(params => params as CoursePublicDetailsRouteParams),
        map(params => params?.courseId),
    );
    course$ = this.courseId$.pipe(
        filter(courseId => !!courseId),
        tap(() => this.loadingService.show()),
        switchMap(courseId => this.coursesService.getCoursePublicDetails(courseId)),
        tap(() => this.loadingService.hide()),
    );
    $course = toSignal(this.course$);

    $courseSyntheticData = computed(() => {
        const course = this.$course();
        const data: Metadata[] = [];
        if (course) {
            if (course.mainLanguage) {
                data.push({ id: 'mainLanguage', label: 'Language', value: course.mainLanguage });
            }
            if ((course.keywords || []).length > 0) {
                data.push({ id: 'keywords', label: 'Keywords', value: (course.keywords || []).join(', ') });
            }
            if (course.level) {
                data.push({ id: 'level', label: 'Level', value: course.level });
            }
            if (course.updatedDate) {
                data.push({ id: 'updatedDate', label: 'Version date', value: DatePipe.prototype.transform(course.updatedDate, 'dd/MM/yyyy') ?? '' });
            }
            if (course.duration) {
                data.push({ id: 'duration', label: 'Duration', value: course.duration });
            }
        }
        return data;
    });

    $courseLongData = computed(() => {
        const course = this.$course();
        const data: Metadata[] = [];
        if (course) {
            if (course.teachers) {
                data.push({
                    id: 'teachers',
                    label: 'Teachers',
                    value: course.teachers.join('<br/>'),
                });
            }
        }
        return data;
    })

    $modules = computed(() => (this.$course()?.modules ?? []).map(m => ({
        ...m,
        metadata: this.getModuleMetadata(m),
    })));

    get swiperConfig(): SwiperOptions {
        return {
            slidesPerView: 1,
            spaceBetween: 48,
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next',
            },
            pagination: {
                el: '#swiperSuggestedCoursesPagination',
                clickable: true
            },
        };
    }

    getModuleMetadata(module: Module) {
        const metadata: Metadata[] = [];
        if (module.level) {
            metadata.push({ id: 'level', label: 'Level', value: module.level });
        }
        return metadata;
    }

    registerAndGoCourse() {
        const course = this.$course();
        if (course?.uuid) {
            const courseRoute = this.routingService.getCourseRoute(this.$course()?.uuid!);
            if (course.needApproval) {
                this.authenticationService.redirectAfterLogin = {
                    url: '/search',
                    queryParams: {fromCourse: course.uuid},
                };
            } else {
                this.authenticationService.redirectAfterLogin = {
                    url: courseRoute.route,
                    queryParams: courseRoute.queryParams,
                };
            }
            this.routingService.goToRegistration({ 'fromCourse': this.$course()?.uuid! });
        }
    }
}

interface CoursePublicDetailsRouteParams {
    courseId: string;
}
interface Metadata {
    id: string;
    label: string;
    value: string;
}