@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="flex flex-col transition-all duration-300 pb-6 min-h-screen"
    [ngClass]="{'xl:pl-sidebar': sideBarShown$ | async}">
    @if (isMainPage) {
        <section class="pt-6 pb-3 bg-body px-base xl:px-base-desk mb-6 flex-1">
            <div class="flex flex-col-reverse lg:flex-row mb-3">
                <div class="w-full flex">
                    <div>
                        <h1 class="text-4xl font-bold">{{'Profile' | translate}}</h1>
                    </div>
                </div>
                @if (!hideChatToggler) {
                    <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                        <div class="ml-auto">
                            <meta-chat-toggler />
                        </div>
                    </div>
                }
            </div>
            @for (section of sections$ | async; track $index) {
                <div class="flex items-center border-b border-[#E2E2E2] mb-4 pb-4">
                    <h2 class="text-[1.625rem] font-bold">{{section.label | translate}}</h2>
                    <div class="pl-4 ml-auto">
                        @if (section.routerLink) {
                            <a [routerLink]="section.routerLink" [relativeTo]="currentRoute"
                                class="btn btn-primary w-full h-9 items-center justify-center rounded-md px-5 py-2">{{'Edit' | translate}}</a>
                        }
                    </div>
                </div>
            }
        </section>

        <meta-footer [hideCustomFooter]="hideFooter"/>
    }
    <router-outlet></router-outlet>
</main>