<div id="viewer-accessibility" class="relative flex-1 flex flex-col px-base overflow-y-auto">
    <div class="sticky top-0 bg-white w-full h-12 px-1 pt-base flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="calendar"></meta-icon>
            <p class="inline-block">{{'Events' | translate}}</p>
        </div>
        <button class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    <div class="px-1 flex flex-col overflow-y-auto mt-4 pt-2 border-t border-t-gray-300 scrollbar-custom">
        @for (event of $events(); track $index) {
            <div class="first:pt-1 pb-3 mb-3 flex flex-col gap-1 border-b border-b-gray">
                <strong>{{event.title || '' | translate}}</strong> 
                <div class="flex flex-row gap-1 items-end justify-between ">
                    <div class="flex flex-col">
                        <strong>{{'Event date' | translate}}:</strong>
                        {{ event.date }}
                    </div>
                    @if (event.inProgress) {
                        @if (event.url) {
                            <a [href]="event.url" target="_blank" class="text-green-600 hover:underline"><em>{{'Participate' | translate}}</em></a>
                        }
                    } @else if (event.passed) {
                        @if (event.rec) {
                            <a [href]="event.rec" target="_blank"><em>{{'View recording' | translate}}</em></a>
                        } @else {
                            <em class="text-red-600">{{'Event concluded' | translate}}</em>
                        }
                    } @else if (event.future) {
                        <em class="text-secondary">{{'Event scheduled' | translate}}</em>
                    }
                </div>
            </div>
        } @empty {
            <div class="first:pt-1 pb-3 mb-3">
                {{'No events for the current course' | translate}}
            </div>
        }
    </div>
</div>