import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';

import { AuthenticationService } from 'src/core/index/services.index';
import { User, environment } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationServiceCustom<T extends User> extends AuthenticationService<User> {

    autoLogin(): Observable<T | undefined> {
        if (environment.login) {
            return this.httpClient.post<{ access_token: string, user: T }>(environment.login, { username: "preview", password: "password" }).pipe(
                catchError(error => throwError(() => error.error.message)),
                tap(response => {
                    this.setToken(response.access_token);
                    this.setUser(response.user);
                }),
                map(response => response.user),
            );
        }
        return of(undefined);
    }

}