import { Component, ElementRef, EventEmitter, ViewChild, inject, signal } from '@angular/core';

import { BehaviorSubject, delay, firstValueFrom, map, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { SwiperOptions } from 'swiper';

import { Course, User, environment } from 'src/index';
import { AuthenticationService, ConfigurationService, CoursesService, LoadingService, PopupService, RoutingService, isMobile } from 'src/index/services.index';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'meta-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends HasDashboardSidebar {
    @ViewChild('swiperInEvidencePagination') swiperInEvidencePagination!: ElementRef;

    popupService = inject(PopupService);
    route = inject(ActivatedRoute);
    router = inject(Router);

    config = { ...environment.dashboardModule };
    studyPathConfig = {
        ...this.config.studyPathCoursesConfig,
    };
    inProgressConfig = {
        ...this.config.inProgressCoursesConfig,
    };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    appName = environment.name;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config?.hideChatToggler;

    currentUser$ = this.authenticationService.currentUser$;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    uuidCourseToOpen = '';
    $queryParams = toSignal(this.route.queryParams.pipe(
        tap((queryParams) => {
            if (queryParams['fromCourse']) {
                this.uuidCourseToOpen = queryParams['fromCourse'];
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        fromCourse: null,
                    },
                    queryParamsHandling: 'merge',
                });
            }
        }),
        shareReplay(1),
    ));
    reloadDashboard$ = new EventEmitter();
    $inEvidenceLoading = signal(true);
    inEvidenceCourses$ = this.reloadDashboard$.pipe(
        startWith(undefined),
        switchMap(() => this.studyPathCourses$),
        switchMap((studyPathCourses) => this.coursesService.getInEvidenceCourses().pipe(
            map(courses => courses || []),
            map(courses => courses.filter(c => !(studyPathCourses || []).find(spc => spc.uuid === c.uuid))),
            delay(1),
            tap(() => this.$inEvidenceLoading.set(false)),
            shareReplay(1),
        )),
        shareReplay(1),
    );
    noInEvidenceCourses$ = this.inEvidenceCourses$.pipe(
        map(courses => courses && courses.length === 0),
    );

    $inProgressLoading = signal(true);
    inProgressCourses$ = this.reloadDashboard$.pipe(
        startWith(undefined),
        switchMap(() => this.coursesService.getInProgressCourses().pipe(
            map(courses => courses || []),
            shareReplay(1),
        )),
        map(courses => {
            const slides: { courses: Course[] }[] = [];
            courses.forEach((course, i) => {
                const slideIndex = Math.floor(i / 3);
                if (!slides[slideIndex]) {
                    slides.push({ courses: [] });
                }
                slides[slideIndex].courses = [...slides[slideIndex].courses || [], course];
            });
            return slides;
        }),
        delay(1),
        shareReplay(1),
        tap(() => this.$inProgressLoading.set(false)),
    );
    noInProgressCourses$ = this.inProgressCourses$.pipe(
        map(courses => !courses || courses.length === 0),
    );

    studyPathLoading$ = new BehaviorSubject(true);
    studyPathCourses$ = this.reloadDashboard$.pipe(
        startWith(undefined),
        switchMap(() => this.coursesService.getStudyPathCourses().pipe(
            map(courses => courses || []),
            tap(courses => {
                if (this.uuidCourseToOpen) {
                    const courseToOpen = courses.find(c => c.uuid === this.uuidCourseToOpen);
                    if (courseToOpen) {
                        this.showCourseDetails(courseToOpen);
                    }
                }
            }),
            delay(1),
            tap(() => this.studyPathLoading$.next(false)),
        )),
        shareReplay(1),
    );
    noStudyPathCourses$ = this.studyPathCourses$.pipe(
        map(courses => !courses || courses.length === 0),
    );

    swiperInEvidenceConfig: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {
            el: '#swiperInEvidencePagination',
            clickable: true
        },
        keyboard: {
            enabled: true,
        },
    };

    swiperInProgressConfig: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {
            el: '#swiperInProgressPagination',
            clickable: true
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: this.config.hideInEvidenceCourses && !this.config.hideStudyPath && this.config.hideInProgressCourses ? {
            640: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            }
        } : undefined,
    };

    swiperStudyPathConfig: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {
            el: '#swiperStudyPathPagination',
            clickable: true
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            }
        }
    };

    search$ = new BehaviorSubject<string>('');
    $courseToPreview = signal<Course | undefined>(undefined);
    getCourseDetailErrorMsg = this.configurationService.getCourseDetailErrorMsg;
    startCourseErrorMsg = this.configurationService.startCourseErrorMsg;

    constructor(
        configurationService: ConfigurationService,
        private authenticationService: AuthenticationService<User>,
        private coursesService: CoursesService,
        private loadingService: LoadingService,
        private routingService: RoutingService,
    ) {
        super(configurationService);
    }

    search(str: string) {
        this.routingService.goToSearch(str);
    }

    showCourseDetails(course: Course) {
        this.loadingService.show();
        firstValueFrom(this.coursesService.getCourseDetails(course))
            .then(completeCourse => {
                this.$courseToPreview.set({ ...course, ...completeCourse });
                if (!completeCourse) {
                    this.popupService.error(this.getCourseDetailErrorMsg);
                }
            })
            .catch((err) => this.popupService.error(this.getCourseDetailErrorMsg))
            .finally(() => this.loadingService.hide());
    }

    startCourse(course: Course, urlToOpen?: string | void) {
        if (!!course.status) {
            this.goToCourse(course, urlToOpen);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.$courseToPreview.set(undefined);
                    this.goToCourse(course, urlToOpen);
                })
                .catch((err) => this.popupService.error(err.message || this.startCourseErrorMsg))
                .finally(() => this.loadingService.hide());
        }
    }

    updateCourseToPreview(course: Course) {
        this.$courseToPreview.set({ ...course });
        this.reloadDashboard$.emit()
    }

    goToCourse(course: Course, urlToOpen?: string | void) {
        if (urlToOpen) {
            return this.router.navigateByUrl(urlToOpen);
        }
        return this.coursesService.openCourse(course);
    }

    isCourseInProgress(course: Course) {
        return (course.progress || 0) * 1 > 0;
    }
}
