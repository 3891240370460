import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, shareReplay } from 'rxjs';
import { HasDashboardSidebar } from 'src/core/app/components/shared/mixins/has-dashboard-sidebar.mixin';
import { environment } from 'src/index';
import { AuthenticationService, isMobile } from 'src/index/services.index';


@Component({
    selector: 'meta-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent extends HasDashboardSidebar {
    authenticationService = inject(AuthenticationService)
    config = { ...environment.helpModule };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    name = environment.name;
    mainLogo = environment.logo;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    $loggedIn = toSignal(this.authenticationService.checklogin().pipe(
        map(user => !!user),
        shareReplay(1),
    ))
    $hasSidebar = computed(() => this.$loggedIn() && !this.config.hideSidebar && !isMobile());

    $sideBarShown = signal(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('about');
}
