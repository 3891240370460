import { Injectable } from '@angular/core';
import { AnalyticsEvent, Course, ISendler, LiquidViewerMedia, Module, User } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class Ga4Service implements ISendler {
  trackRegistration(user: Partial<User>) {
    this.sendEvent(AnalyticsEvent.Registration, { userId: user.uuid });
  }

  trackLogin(user: Partial<User>) {
    this.sendEvent(AnalyticsEvent.Login, { userId: user.uuid });
  }

  trackSelfAssessmentStart(user: Partial<User>) {
    this.sendEvent(AnalyticsEvent.SelfAssessmentStart, { userId: user.uuid });
  }

  trackSelfAssessmentComplete(user: Partial<User>, startDate: string) {
    this.sendEvent(AnalyticsEvent.SelfAssessmentComplete, { userId: user.uuid, startDate });
  }

  trackCourseAccess(user: Partial<User>, course: Partial<Course>) {
    this.sendEvent(AnalyticsEvent.CourseAccess, { userId: user.uuid, courseId: course.uuid, courseName: course.title });
  }

  trackModuleAccess(user: Partial<User>, course: Partial<Course>, module: Partial<Module>) {
    this.sendEvent(AnalyticsEvent.ModuleAccess, { userId: user.uuid, courseId: course.uuid, courseName: course.title, module: module.id, moduleName: module.title });
  }

  trackVideoPlay(user: Partial<User>, course: Partial<Course>, video: LiquidViewerMedia, titleFromCurrentIndex: string) {
    this.sendEvent(AnalyticsEvent.VideoPlay, { userId: user.uuid, courseId: course.uuid, courseName: course.title, videoFilename: video.title || titleFromCurrentIndex || '' });
  }

  sendEvent(name: string, value: any) {
    console.warn('Analytics send event missing', name, value);
  }
}
